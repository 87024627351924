import React, { useState    } from 'react';
import { useSandpack        } from '@codesandbox/sandpack-react';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/solid';
import { PlayIcon           } from '@heroicons/react/24/solid';

import { editorEventPublisher } from '../../EditorEventPublisher';
import { EditorEventType      } from '../../types';

type Props = {
  isMobile       : boolean;
  videoEnded     : boolean;
  showPreview    : boolean;
  setShowPreview : (showPreview : boolean) => void;
};

const RunCodeButton = ({isMobile, videoEnded, showPreview, setShowPreview}: Props) => {
  const { sandpack                          } = useSandpack();
  const [ runDisabled   , setRunDisabled    ] = useState(true);
  const [isToggleVisible, setIsToggleVisible] = useState(false);
  const activeCode= sandpack.files[sandpack.activeFile].code;

  const togglePreview = () => {
    if (!showPreview) {
      // If the video has not ended, the send the show preview event
      // which sets the video player to pause
      !videoEnded && editorEventPublisher.publish([{ type: EditorEventType.SHOW_PREVIEW }]);
      sandpack.runSandpack(); 
    }

    setShowPreview(!showPreview);
  };

  React.useEffect(() => {
    if (activeCode.length === 0 && ! runDisabled) {
      setRunDisabled(true);
    } else if (activeCode.length > 0 && runDisabled) {
      setRunDisabled(false);
    }
  }, [activeCode]);

  const Tooltip = () => {
    return (
      <div className="
        absolute bottom-full right-[5%] transform -translate-x-1 -translate-y-1
        w-max mb-1 p-1 
        text-sm text-primary whitespace-nowrap
        bg-lightgrey rounded-md shadow-lg">
        <div className="relative">
          <span>{showPreview ? "코드창 다시보기" : "코드 실행하기"}</span>
          <div className="
            absolute 
            right-[1%] transform -translate-x-1 translate-y-full 
            mt-[-2px] w-0 h-0 
            border-l-[6px] border-l-transparent 
            border-r-[6px] border-r-transparent 
            border-t-[6px] border-t-lightgrey
          "></div>
        </div>
      </div>
    );
  }

  const showTooltip = (isMobile || isToggleVisible) && !runDisabled;

  return (
    <div className={`
      code-run-button
      relative inline-block
      bg-white 
      border border-primary shadow-md rounded-full
      text-primary 
      ${runDisabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
    `}>
      { 
        showPreview ? ( 
          <ArrowUturnLeftIcon
            className    = "h-7 w-7 p-1 md:h-9 md:w-9 md:p-2"
            onClick      = {() => togglePreview()     } 
            onMouseEnter = {() => setIsToggleVisible(true)  } 
            onMouseLeave = {() => setIsToggleVisible(false) }
          />
        ) : (
          <PlayIcon 
            className    = "h-7 w-7 p-1 md:h-9 md:w-9 md:p-2"
            onClick      = {() => !runDisabled && togglePreview()     } 
            onMouseEnter = {() => !runDisabled && setIsToggleVisible(true)  } 
            onMouseLeave = {() => !runDisabled && setIsToggleVisible(false) } 
          />
        )
      }
      {showTooltip && <Tooltip />}
    </div>
  );
};

export default RunCodeButton;
