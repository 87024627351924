import React from 'react';
import MarkdownContent from 'components/core/MarkdownContent';

const Site = () => <MarkdownContent content={MD.trim()} />;

export default Site;

const MD = `
# 서비스 이용 약관
<br>

## 제1장 총칙
<br><br>

### 제1조 목적
<br>

본 약관은 주식회사 그레이스풀레인이 제공하는 서비스의 이용과 관련하여, 회사와 이를 이용하는 이용자 간 발생하는 권리, 의무, 책임 기타 사항 등을 규정하는 것을 목적으로 합니다.<br></br>

### 제2조 정의
<br>

1 본 약관에서 사용되는 용어는 다음 각호와 같이 정의됩니다.
<br><br>

- (1) "회사"란, 서비스를 제공하는 사업자 "주식회사 그레이스풀레인"을 말합니다.
- (2) "이용자"란, 본 약관에 따라 회사와 서비스 이용계약을 체결하고, 회사가 제공하는 서비스를 이용하는 회원 및 비회원을 말합니다.
- (3) "회원"이라 함은 이용에 관한 약관에 동의하고 회원 등록을 통하여 회사가 제공하는 서비스를 이용할 수 있는 자를 말합니다.
- (4) "비회원"이라 함은 회원으로 등록하지 않고 회사가 제공하는 서비스를 이용하는 자를 말합니다.
- (5) "서비스"란, 회사의 wecode로서, 본 약관 제9조 제1항에 따른 서비스를 의미합니다. "서비스 이용료"란, 이용자가 유료 서비스를 이용한 대가로 회사에 지급하여야 하는 대금을 의미합니다.
- (6) "웹사이트"란, 서비스를 제공하는 회사가 운영하는 [웹사이트(http://wecode.co.kr)](http://wecode.co.kr)를 말합니다.
- (7) "계정"이라 함은 서비스에서 본인을 식별하기 위해 생성된 계정으로 본 약관에 동의하고 회사와 이용계약을 체결한 회원에게 부여되는 서비스 계정을 말합니다.
- (8) "비밀번호"라 함은 회원이 계정 및 계정 주소와 일치되는 회원임을 확인하고 비밀보호를 위해 회원 자신이 정한 문자 또는 숫자의 조합을 의미합니다.
- (9) "디지털 콘텐츠"라 함은 회사가 이용자에게 제공하는 온라인 동영상 강좌 및 기타 관련정보와 그 밖에 서비스를 제공하기 위해 정보통신망에서 사용되는 부호 · 문자 · 음성 · 음향· 이미지또는 영상 등으로 표현된 자료 또는 정보를 말합니다.
- (10) "사이버머니"라 함은 회사 웹 사이트 내에서 유료 서비스 등을 구매할 수 있는회사가 정한 가상 결제수단을 말하고, 그 명칭에 따라 결제 가능한 범위의 차이가 있을 수 있습니다.
<br>
2 본 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을 제외하고는 관계법령에서 정하는 바에 의합니다. 관계법령에서 정하지 않는 것은 일반적인 상관례에 의합니다.<br></br>

### 제3조 약관의 적용
<br>

본 약관은 원칙적으로 회사가 이용자에게 제공하는 모든 서비스에 적용됩니다. 단, 회사와 이용자 간 별도의 계약 또는 별도의 약관을 체결하는 경우에는 그 정함에 따릅니다.<br></br>

### 제4조 약관의 명시와 개정
<br>

1 회사는 본 약관의 내용을 이용자가 알 수 있도록 웹사이트에 게시하거나 연결 화면을 제공하는 방법으로 이용자에게 공지합니다.<br></br>

2 본 약관에 동의한 이용자는 약관에 동의한 시점부터 동의한 약관의 적용을 받고, 약관의 변경이 있을 경우에는 변경의 효력이 발생한 시점부터 변경된 약관의 적용을 받습니다.<br></br>

3 회사는 「약관의 규제에 관한 법률」, 「정보통신망 이용 촉진 및 정보보호 등에 관한 법률」 등 관련 대한민국 법령을 위배하지 않는 범위에서 본 약관의 전부 또는 일부를 개정, 수정(이하 "개정") 할 수 있습니다.<br></br>

4 회사가 본 약관을 개정하고자 할 경우에는 적용일자 및 개정 내용, 개정 사유 등을 현행 약관과 함께 명시하여 그 적용일자로부터 최소 7일 이전부터 그 적용일자 경과 후 상당한 기간이 경과할 때까지 웹사이트를 통해 공지합니다. 다만, 이용자에게 불리하게 개정되는 경우 개정 적용일자 30일 이전부터 웹사이트 내 팝업을 띄우는 방식 등을 이용하여 개별적으로 통지합니다.<br></br>

5 회사가 본 약관을 개정할 경우, 개정 약관 공지 또는 통지를 하면서 이용자에게 공지 기간 내에 거부의 의사표시를 하지 않으면 약관 개정에 동의한 것으로 본다는 의미를 명확하게 전달했음에도 불구하고, 이용자가 명시적으로 거부의 의사표시를 아니하였을 경우에는 이용자가 개정 약관에 동의한 것으로 간주합니다.<br></br>

6 이용자가 개정약관의 적용에 동의하지 않을 경우 회사는 개정약관의 내용을 적용할 수 없으며, 이 경우 회사는 해당 이용자와의 이용계약을 해지하고 서비스의 제공을 중단할 수 있습니다. 또한 이용자는 이용자가 개정약관의 적용에 동의하지 않을 경우, 회사와의 이용계약을 해지할 수 있습니다.<br></br>

7 회사는 서비스 내에서 제공되는 세부적인 서비스를 위하여 별도의 정책을 둘 수 있으며, 이를 이용자가 확인할 수 있도록 웹사이트에 게시하거나 통지합니다. 만일, 회사가 게시한 정책 내용이 본 약관과 상충되는 경우, 별도의 정책이 우선하여 적용됩니다.<br></br>

### 제5조 통지
<br>

1 회사가 이용자에게 통지를 하는 경우 웹사이트 내 팝업, 공지사항 게시판 등을 이용합니다.<br></br>

2 이용자는 언제든지 웹사이트 내 고객센터를 방문하여 의견을 개진할 수 있습니다.<br></br>

### 제6조 운영정책
<br>

1 회사는 본 약관에 규정되지 않은 세부적인 내용에 대해 개별 서비스 운영 정책(이하 "운영 정책")을 제정하여 운영할 수 있으며, 운영 정책은 본 약관과 더불어 이용 계약의 일부를 구성합니다.<br></br>

2 회사는 운영 정책의 내용을 이용자가 알 수 있도록 웹사이트에 게시하거나 연결 화면을 제공하는 방법으로 이용자에게 공지합니다.<br></br>

3 이용자의 권리 또는 의무에 중대한 변경을 가져오거나 약관 내용을 변경하는 것과 동일한 효력이 발생하는 운영 정책 변경의 경우에는 제4조 제4항의 변경 절차를 따릅니다. 단, 운영 정책 변경이 다음 각 호의 어느 하나에 해당하는 경우에는 전항의 방법으로 사전에 공지합니다.

- (1) 약관에서 구체적으로 범위를 정하여 위임한 사항을 변경하는 경우
- (2) 이용자의 권리·의무와 관련 없는 사항을 변경하는 경우
- (3) 운영 정책의 내용이 약관에서 정한 내용과 근본적으로 다르지 않고 이용자가 예측 가능한 범위 내에서 운영 정책을 변경하는 경우
- (4) 기타의 각호에 준하는 것으로 이용자에게 중대한 영향을 미치지 아니하는 변경의 경우

<br>

### 제7조 약관 외 해석
<br>

본 약관에서 정하지 아니한 사항이나 해석에 대해서는 제6조 운영정책 및 「약관의 규제에 관한 법률」, 「정보통신망 이용 촉진 및 정보보호 등에 관한 법률」 등 관련 대한민국 법령에 따릅니다.<br></br>

<br><br>

## 제2장 서비스
<br><br>

### 제8조 서비스 이용계약의 체결
<br>

1 서비스에 대한 이용계약은 이용자의 이용 신청 및 본 약관에 동의와, 그 이용 신청에 대한 회사의 이용 승낙으로 체결됩니다.<br><br>
2 회사는 제1항에도 불구하고, 다음 각 호의 경우에 그 원인이 해소될 때까지 이용계약의 체결을 유보하거나, 이용계약의 체결을 거절할 수 있습니다.

- (1) 회사의 설비에 여유가 없는 경우
- (2) 기술상 서비스를 처리하지 못하는 장애가 발생한 경우
- (3) 이용자가 회사가 제시하는 서비스 사용 절차를 완료하지 않은 경우
- (4) 제11조 제1항 각호의 사유가 있는 경우
- (5) 이용자가 제21조 제1항 각호의 행위를 한 전력이 있는 경우
- (6) 이용자가 본 약관을 위반하거나, 기타 회사와 체결한 계약을 위반한 전력이 있는 경우
- (7) 기타 이용자로서 부적절한 행위를 할 우려가 있는 경우, 또는 해당 이용자와 서비스 이용계약을 체결한 경우 원활한 서비스 제공이 어려울 우려가 있다고 인정되는 경우
<br>

### 제9조 서비스의 제공
<br>

1 회사는 다음과 같은 서비스를 제공합니다. 다만 필요한 경우 추가하거나 제한할 수 있으며, 구체적인 내용은 회사 웹사이트 내 서비스 설명에 따릅니다.

- (1) 재화나 용역 또는 디지털 콘텐츠에 대한 정보 제공 및 구매 계약의 체결

- (2) 구매 계약이 체결된 재화나 용역의 배송 및 디지털 콘텐츠 전송 등의 서비스

- (3) 기타 위 업무에 부수하는 서비스

<br>
2 회사는 제1항 서비스의 일부를 법령이 허용하는 범위 내에서 제3자에게 위임, 위탁하여 수행하게 할 수 있습니다.<br></br>

3 회사는 회사의 재량에 따라 제1항의 서비스를 유료 서비스와 무료 서비스로 나누어 제공할 수 있고, 이에 관한 구체적인 사항은 웹사이트, 게시판, 결제 화면을 통해 게시합니다.<br></br>

4 서비스의 이용과 관련하여 서비스가 제공되는 공간은 회사의 사정 또는 재량에 따라 변경될 수 있고, 이용자는 이와 같은 서비스 제공 공간의 변경 가능성을 충분히 인지하고 이에 동의합니다.<br></br>

### 제10조 서비스 이용 기간
<br>

1 이용자는 이용 계약 성립 후 서비스를 즉시 이용할 수 있습니다. 다만, 일부 서비스의 경우에는 회사의 필요에 따라 지정된 일자부터 서비스를 개시하거나, 별도의 약관 또는 계약의 체결이 필요한 경우 관련 절차가 완료된 후 서비스를 개시할 수 있습니다.<br></br>

2 서비스 이용 기간의 종료일은, 이용자의 서비스 해지 요청 및 기타 사유에 따른 계약 종료일로 합니다.<br></br>

3 회사의 업무상 또는 기술상의 장애로 인하여 서비스를 개시하지 못하는 경우에는 웹사이트에 공지하거나 이용자에게 별도로 통지합니다.<br></br>

4 서비스 제공시간은 365일 24시간을 원칙으로 합니다. 다만, 웹사이트의 기술적인 문제로 일시적으로 서비스 제공이 중단될 수 있고, 이 경우 회사는 최대한 빠르게 조치를 취할 것입니다.<br></br>

### 제11조 서비스 제공 중단
<br>

1 회사는 다음 각호와 같은 운영상의 상당한 이유가 있는 경우 서비스 제공을 일시 중단하거나 종료할 수 있습니다.

- (1) 회사의 시스템을 포함한 정보통신 설비의 보수 점검, 정기점검, 교체 또는 고장, 통신의 두절 등의 사유가 발생한 경우
- (2) 서비스의 안정적인 제공 및 수정을 위하여 필요한 경우
- (3) 해킹 등의 전자적 침해 사고, 통신 사고, 미처 예상하지 못한 서비스의 불안정성에 대응하기 위하여 필요한 경우
- (4) 「전기통신사업법」에 의한 기간 통신 사업자가 제공하는 전기통신 서비스에 장애 등이 발생하는 경우
- (5) 천재지변, 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등 회사의 합리적인 노력으로 제어할 수 없는 불가항력적인 사유가 발생하거나 발생할 우려가 있는 경우
- (6) 이용자가 서비스 이용료의 지급을 연체하는 경우(이 경우, 대금 지급일 익일 00:00시부터 서비스 제공이 중단됩니다)
- (7) 그 밖에 서비스의 원활한 운영을 저해하는 사유가 발생한 경우<br>
<br>
2 제1항에 의한 서비스제공 중단과 관련하여, (i) 회사에게 고의 또는 중과실이 없거나 (ii) 불가항력적 사유에 의한 것이거나 (iii) 이용자에 대한 서비스 제공을 위하여 필수적인 경우, 당해 서비스제공 중단에 대한 책임을 부담하지 아니합니다.<br></br>

### 제12조 서비스 이용료의 지급
<br>

1 회사는 서비스의 성격에 따라 유료 서비스와 무료 서비스를 제공할 수 있습니다. 유료 서비스 이용과 관련하여 이용 요금, 요금 지급 방법 등 구체적인 사항은 웹사이트, 게시판, 결제 화면을 통해 게시합니다.<br></br>

2 이용요금에 관한 사항은 회사의 정책에 따라 변경될 수 있습니다. 이 경우 변경일로부터 최소한 30일 이전까지 이용자에게 해당사항을 통지합니다. 변경된 유료 서비스 이용료와 관련하여, 고지한 날 이후 최초 도래하는 대금 결제일까지는 변경 전 서비스 이용료가 부과되며, 그다음 결제일부터 변경 후 서비스 이용료가 부과됩니다.<br></br>

3 이용자가 서비스를 이용하는 과정에서 통신 사업자 등과의 거래 관계에서 발생하는 데이터 통신 요금은 이용요금과는 별개의 것입니다. 회사는 데이터 통신 요금과 관련하여 어떠한 의무나 책임을 부담하지 않습니다.<br></br>

### 제13조 이용자의 청약 철회
<br>

1 서비스의 이용에 관한 계약을 체결한 이용자는 수신확인의 통지를 받은 날로부터 7일(수신확인의 통지받은 때보다 서비스의 공급이 늦게 이루어진 경우에는 공급을 받거나 공급이 개시된 날로부터 7일) 이내에는 청약을 철회할 수 있습니다. 다만, 다음 각 호 중 어느 하나에 해당하는 경우에는 이용자의 청약 철회권이 제한될 수 있습니다.

- (1)  이용자의 책임 있는 사유로 서비스가 멸실되거나 훼손된 경우

- (2) 이용자의 사용 또는 일부 소비로 서비스의 가치가 현저히 감소한 경우

- (3)  시간의 경과에 의해 재판매가 곤란할 정도로 서비스의 가치가 현저히 감소한 경우

- (4)  복제가 가능한 서비스의 포장을 훼손한 경우
<br>
2 본 조 1항에도 불구하고 이용자는 서비스의 내용이 표시, 광고 내용과 다르거나 계약 내용과 다르게 이행된 경우에는 당해 서비스(도서 등 재화 포함)를 공급받은 날로부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던 날로부터 30일 이내에 청약철회 등을 할 수 있습니다.<br></br>

3 회사는 이용자가 청약 철회의 의사를 표시한 경우 청약 철회 의사표시를 한 날(서비스를 제공받은 경우 이를 반환받은 날)부터 3영업일 이내에 대금의 결제와 동일한 방법으로 이를 반환하여야 하며 동일한 방법으로 반환이 불가능할 때에는 이를 사전에 고지합니다.<br></br>

4 회사는 위 대금을 반환함에 있어서 이용자가 신용카드 등의 결제수단으로 서비스의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 대금의 청구를 정지 또는 취소하도록 요청합니다. 다만 본 조 5항의 금액공제가 필요한 경우에는 그러하지 아니할 수 있습니다.<br></br>

5 회사는 본 조 3항에 따라 반환할 경우 이용자가 서비스 이용으로부터 얻은 이익에 해당하는 금액을 공제하고 반환할 수 있습니다. 이때 서비스 이용으로부터 얻은 이익이란 서비스 구매 시 제공받은 사은품 등을 말합니다.<br></br>

6 이용자는 청약 철회 시 서면, 전화, 문자메시지, 전자우편, 모사전송 등으로 회사에 그 의사를 표시한 때에 효력이 발생하며 회사는 이용자가 표시한 청약 철회의 의사표시를 수신한 후 지체 없이 이러한 사실을 이용자에게 회신합니다.<br></br>

### 제14조 서비스 이용료의 환급
<br>

이용자가 유료 서비스 해지 요청 시, 서비스 이용료의 환불은 별도의 유료 서비스 약관 또는 환불 규정 등에 따릅니다.<br><br>

### 제15조 과오금의 환급
<br>

1 회사는 과오금이 발생한 경우 이용 대금의 결제와 동일한 방법으로 과오금 전액을 반환하여야 합니다. 다만 동일한 방법으로 반환이 불가능할 때에는 이를 사전에 고지합니다.<br></br>

2 회사의 책임 있는 사유로 과오금이 발생한 경우 회사는 계약 비용, 수수료 등에 관계없이 과오금 전액을 반환합니다. 다만, 이용자의 책임 있는 사유로 과오금이 발생한 경우 회사는 과오금을 환불하는데 소요되는 비용을 합리적인 범위 내에서 공제하고 환불할 수 있습니다.<br></br>

### 제16조 사이버머니 사용 및 소멸
<br>

1 사이버머니의 적립과 사용 조건은 회사가 정한 바에 따르며 회사의 사정에 따라 제5조에 따른 사전 통지 후 변경될 수 있습니다.<br></br>

2 회사의 정책에 따라 적립한 사이버머니는 적립 시점을 기준으로 1년이 경과하면 순차적으로 자동 소멸되는 등 회사의 정책에 따라 사용에 제한이 있을 수 있습니다.<br></br>

3 사이버머니는 원칙적으로 서비스 강의 등 유료상품 결제 시사용 가능하나 회사의 정책에 따라 사용 대상 상품의 범위가 제한될 수 있습니다.<br></br>

4 1회 결제 시 사이버머니의 최대 사용금액에 대해 제한이 있을 수 있습니다.<br></br>

5 사이버머니는 현금 등으로 교환 불가하며, 사이버머니로 결제한 상품의 환불 시 무상으로 적립한 사이버머니는 환불되지 않고 소멸합니다.<br></br>

6 사이버머니는 회원 탈퇴 시 자동 소멸됩니다. 소멸된 사이버머니는 재가입하더라도 복구되지 않습니다.<br></br>

7 사이버머니는 제3자에게 양도 또는 대여할 수 없습니다.<br></br>

8 회사는 경영상·기술상의 이유로 사이버머니 서비스를 종료하는 경우 최소 30일 전까지 이용자에게 이 사실을 고지하며, 이 기간 내에 사용하지 못한 사이버머니에 대해서는 사전에 정한 보상방법에 따라 이용자에게 보상할 수 있습니다.<br></br>

### 제17조 서비스와 지식 재산권 등
<br>

1 서비스 내에서 제공되는 모든 소프트웨어, 기능, 문서, 콘텐츠, 브랜드의 표현 방식 등 일체는 오직 회사의 재산이고 회사는 이를 독점적으로 소유하며, 이에 대한 특허권, 상표권, 디자인권, 저작권, 데이터베이스, 영업 비밀, 노하우 등 일체의 권리(이하 "지식 재산권")는 회사에 귀속합니다.<br></br>

2 이용자는 회사 또는 제3자가 지식 재산권을 보유하고 있는 저작물 기타 정보, 지식 재산 등을 회사 또는 제3자의 사전 승낙 없이 복제, 전송, 출판, 배포, 방송 기타 방법에 의하여 영리 목적으로 이용하거나 타인에게 이용하게 하여서는 안 됩니다.<br></br>

<br><br>

## 제3장 이용자와 회사
<br><br>

### 제18조 개인정보처리 방침의 수립 및 공개
<br>

회사는 개인정보보호법 등 관련 법령에서 정하는 바에 따라 개인정보처리 방침을 수립 및 공개합니다.<br></br>

### 제19조 회원가입
<br>

1 이용자는 이 약관의 내용에 대해 동의를 한 다음 회사가 정한 가입 양식에 따라 회원정보를 기입한 후 회원가입을 신청하고 이에 승낙함으로써 체결됩니다.<br></br>

2 본조 1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각호에 해당하지 않는 한 회원으로 등록합니다.

- (1) 실명이 아닌 타인의 명의를 이용한 경우

- (2) 이용자가 이 약관에 의해 이전에 회원자격을 상실한 적이 있는 경우, 다만 회사의 재가입 승낙을 얻은 경우에는 예외로 함

- (3) 이용자의 귀책사유로 인하여 승인이 불가능한 경우

- (4) 허위로 정보를 제공하는 경우

- (5) 중복된 아이디를 사용하는 경우

- (6) 회사가 제시하는 회원가입 신청양식에 관련 내용을 기재하지 않은 경우
<br>
3 본조 1항에 따른 신청에 있어 회사는 회원의 가입을 위해 휴대전화 번호 인증을 통해 유효성을 요청할 수 있습니다.<br></br>

4 회사는 서비스 관련 설비의 여유가 없거나 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.<br></br>

5 회사는 회원에 대해 정책에 따라 등급별로 구분하여 이용시간, 이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.<br></br>

6 회원은 가입 신청시 등록한 정보의 변경이 발생한 경우 즉시 회사에서 제공하는 정보수정의 방법으로 그 변경사항을 알려야 합니다.<br></br>

### 제20조 회원 탈퇴 및 자격 상실 등
<br>

1 회원은 회사에 언제든지 탈퇴를 요청할 수 있으며 회사는 즉시 탈퇴를 처리합니다.<br></br>

2 회원이 다음 각 호의 사유에 해당하는 경우 회사는 사전통지 없이 이용계약을 해지하거나 회원 자격을 제한 및 정지시킬 수 있습니다.

- (1) 가입 신청서에 허위 내용을 등록한 경우
- (2) 본인의 서비스 아이디 및 비밀번호를 타인에게 양도 또는 공유한 경우
- (3) 서비스를 이용하여 구입한 재화나 용역의 대금, 디지털 콘텐츠를 이용한 학습비의 대금, 기타 서비스를 이용함에 있어 회원이 부담하는 채무를 기일에 지급하지 않는 경우
- (4) 서비스 운영을 고의로 방해한 경우
- (5) 다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자거래질서를 위협하는 경우
- (6) 서비스를 이용하여 관련 법률과 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우
- (7) 디지털 콘텐츠를 빔프로젝터 및 기타 영상물로 상영하는 행위를 하는 경우
- (8) 정보통신 설비의 오작동이나 파괴를 유발하는 컴퓨터 바이러스 프로그램 등을 유포하는 경우
- (9) 본 이용약관을 포함하여 기타 회사가 정한 이용조건 및 회원의 의무를 위반한 경우
- (10) 기타 회사가 정하는 경우
<br>
3 회사는 이 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해하는 경우 사안에 따라 경고, 일시정지, 영구이용정지 등으로 서비스 이용을 단계적으로 제한할 수 있습니다.<br></br>

4 회사가 회원자격을 제한·정지시킨 후 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 않는 경우 회사는 회원자격을 상실시킬 수 있습니다.<br></br>

5 회사가 회원자격을 상실시키는 경우에는 회원 등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원 등록 말소 전에 소명할 기회를 부여합니다.<br></br>

### 제21조 이용자의 의무
<br>

1 이용자는 다음 각호의 행위 또는 다음 각호에 해당하는 내용을 목적으로 하거나 의도하는 행위를 하는 경우 회사는 사전 통보 없이 이용계약을 해지하거나 또는 기간을 정하여 서비스 이용을 중지할 수 있습니다.

- (1) 소프트웨어 버그를 악용하는 행위
- (2) 악성코드를 활용하여 회사의 서비스에 장애를 주는 행위
- (3) 서비스를 리버스 엔지니어링(reverse engineering) 하여 복제하는 행위
- (4) 타인의 권리(소유권, 지식 재산권 기타 이와 유사한 권리)를 침해하는 행위
- (5) 타인의 명예를 훼손하거나 모욕감 또는 불쾌감을 주는 행위
- (6) 서비스를 이용하여 불법행위를 저지르는 행위
- (7) 서비스의 정상적인 이용 범위를 벗어나서 서비스를 사용하는 행위
- (8) 기타 관련 법령에서 금지하거나 선량한 풍속 기타 사회통념상 허용되지 않는 행위

2 이용자는 회사가 본 약관을 포함하여 관련 법령과 회사가 별도로 제시하는 서비스 이용 기준 또는 지침을 확인하고 준수하여 서비스를 이용하여야 합니다. 해당 사항을 숙지하지 아니하여 문제가 발생할 경우 회사는 책임을 지지 않습니다.<br></br>

3 회사는 이용자가 제2항에 저촉되는 행위를 하는 경우 차단 등 임시 조치할 수 있습니다. 또한 회사는 서비스 이용 제한, 민·형사상의 고소/고발 등의 조치를 취할 수 있습니다. 이로 인하여 회사에 손해가 발생한 경우 해당 이용자에게 손해배상을 청구할 수 있습니다.<br></br>

### 제22조 이용자의 책임, 유의사항, 보안 관리 등
<br>

1 이용자는 자신의 책임으로 본인 계정의 보안을 관리하여야 합니다.<br></br>

2 이용자는 이용자의 계정 또는 비밀번호가 외부로 유출되지 않도록 주의를 다하여야 합니다.
이용자의 귀책사유로 인하여 이용자의 계정 또는 비밀번호가 외부로 유출되어 발생하는 피해 및
결과에 대해서는 회사는 책임을 지지 않습니다.<br></br>

3 이용자는 자신의 계정 또는 비밀번호가 유출되어 제3자 임의로 사용하고 있음을 알게 된 때에는
즉시 회사에 통보하고 회사의 안내에 따라야 합니다.<br></br>

4 이용자는 자신의 서비스 이용 권한 및 서비스 이용 계약상의 지위를 회사의 사전 서면동의 없이는
제3자에게 양도, 대여하거나 담보로 제공해서는 아니 됩니다.<br></br>

### 제23조 회사의 의무
<br>

1 회사는 관계법령과 본 약관을 준수하고 이용자에게 계속적이고 안정적인 서비스를 제공하기 위하여 노력합니다.<br></br>

2 회사는 이용자의 개인정보 보호를 위해 보안 시스템을 구축하며 개인정보 처리 방침을 공시하고 준수합니다.<br></br>

3 회사는 이용자의 개인정보를 본인의 승낙, 관련 법령의 근거, 기타 국가기관 등의 요구 등 없이 타인에게 누설, 배포하지 않습니다.<br></br>

<br><br>

## 제4장 기타

<br><br>

### 제24조 손해배상
<br>

1 이용자가 본 약관을 위반하여 회사에 손해가 발생할 경우, 이용자는 이와 인과관계가 있는 손해를 회사에 배상하여야 합니다.<br></br>

2 이용자가 서비스를 이용함에 있어 행한 불법행위나 본 약관 위반행위로 인하여 회사가 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의 제기를 받는 경우, 당해 이용자는 자신의 책임과 비용으로 회사를 면책시켜야 하며, 회사가 면책되지 못한 경우 당해 이용자는 그로 인하여 회사에 발생한 모든 손해를 배상하여야 합니다.<br></br>

### 제25조 면책사항
<br>

1 회사는 다음 각호의 경우, 그 책임이 면제됩니다.
- (1) 전시, 사변, 천재지변, 비상사태, 현재의 기술로는 해결이 불가능한 기술적 결함 기타 불가항력적 사유로 서비스를 제공할 수 없는 경우<br>

- (2) 이용자의 고의 또는 과실로 인한 서비스 이용에 장애가 발생한 경우
- (3) 제3자가 불법적으로 회사의 서버에 접속하거나 서버를 이용함으로써 발생하는 손해의 경우 중 회사의 고의 또는 중과실이 없는 경우
- (4) 제3자가 악성 프로그램을 전송 또는 유포함으로써 발생하는 손해의 경우 중 회사의 고의 또는 중과실이 없는 경우
- (5) 전송된 데이터의 생략, 누락, 파괴 등으로 발생한 손해, 명예훼손 등 제3자가 서비스를 이용하는 과정에서 발생된 손해 중 회사의 고의 또는 중과실이 없는 한 책임이 면제됩니다.
- (6) 기타 회사의 고의 또는 중과실이 없는 사유로 인한 손해의 경우에는 책임이 면제됩니다.
<br>
2 회사는 관련 법령상 허용되는 한도 내에서 서비스와 관련하여 본 약관에 명시되지 않은 어떠한
구체적인 사항에 대한 약정이나 보증을 하지 않으며, 회사의 과실 없이 발생된 이용자의 손해에
대하여는 책임을 부담하지 아니합니다.<br></br>

3 회사는 이용자가 서비스를 이용하여 기대하는 이익을 얻지 못하거나 상실한 것에 대하여 책임을 지지
않으며 그 밖에 서비스를 통하여 얻은 자료로 인한 손해 등에 대하여도 책임을 지지 않습니다.<br></br>

4 회사는 무료로 제공되는 서비스 이용과 관련하여 이용자에게 발생한 손해에 대하여 책임을 지지
않으나, 유료로 제공되는 서비스 이용과 관련하여 이용자에게 발생한 손해에 대하여는 이용자로부터
지급받은 대금의 범위 내에서 책임을 부담합니다. 단 회사의 고의 또는 중과실이 있는 경우에는
그러하지 않습니다.<br></br>

### 제26조 개인정보의 보호 및 사용
<br>

1 개인정보보호에 관하여, 본 약관에서 정하지 아니한 사항은 웹페이지에 별도로 게재된 개인정보취급방침에 따릅니다.<br></br>

2 이용자의 귀책사유로 인하여 이용자의 개인정보가 유출된 경우, 회사는 고의 또는 중과실이 없는 한 이에 대하여 책임을 지지 않습니다.<br></br>

3 본 서비스의 이용 결과물인 학습 결과물 상에 개인 정보 또는 금융 정보 등의 민감 정보 기입을 금지합니다. 이를 어긴, 본인의 부주의나 회사의 고의 또는 중대한 과실이 아닌 사유로 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.<br></br>

### 제27조 분쟁의 해결
<br>

1 본 약관은 대한민국 법률에 따라 규율 되고 해석됩니다.<br></br>

2 서비스 이용으로 발생한 분쟁에 대해 소송이 제기될 경우, 상호 협의에 의하여 해결하되, 분쟁이
해결되지 않는 경우 소송은 제소 당시의 이용자 주소에 의하고 주소가 없는 경우에는 거소를
관할하는 지방법원의 전속관할로 합니다. 다만 제소 당시 이용자의 주소 또는 거소가 분명하지
않거나 외국 거주자의 경우에는 서울중앙지방법원을 관할법원으로 합니다.

`;
