type URI = string | number;

export const PATH = {
  HOME: {
    base: '/',
  },
  LOGIN: {
    base   : '/login',
    toLogin: (redirect: URI) => { return `/login?redirect=${redirect}`; },
  },
  SIGNUP: {
    base: '/signup',
  },
  AUTH: {
    kakao: '/auth/kakao',
  },
  COURSE: {
    base            : '/course',
    course          : '/course/:courseId',
    chapter         : '/course/:courseId/:sequence',
    latestChapter   : '/course/:courseId/latest',
    completed       : '/course/:courseId/completed',
    payment         : '/course/:courseId/payment',
    toDescription   : (courseId: URI)                 => { return `/course/${courseId}`; },
    toCompleted     : (courseId: URI)                 => { return `/course/${courseId}/completed`;   },
    toChapter       : (courseId: URI, sequence: URI ) => { return `/course/${courseId}/${sequence}`; },
    toLatestChapter : (courseId: URI)                 => { return `/course/${courseId}/latest`; },
    toCourse        : (courseId: URI, sequence?: URI) => {
      return sequence ? `/course/${courseId}/${sequence}` : `/course/${courseId}/latest`;
    },
    toPayment: (courseId: URI, redirectUrl?: string) => { 
      return `/course/${courseId}/payment${redirectUrl ? `?redirectUrl=${redirectUrl}` : ''}`;     
    },    
  },
  PAYMENT: {
    success  : '/payment/:paymentMethod/success',
    fail     : '/payment/fail',
    wecoin   : '/payment/wecoin',
  },
  MYPAGE: {
    base            : '/mypage/:tab',
    home            : '/mypage/home',
    wecoin          : '/mypage/wecoin',
    favorite        : '/mypage/favorite',
    bookmark        : '/mypage/bookmark',
    myCourse        : '/mypage/my-course',
    purchaseHistory : '/mypage/purchase-history',
    purchaseDetail  : '/mypage/purchase-detail/:orderId',
    paymentHistory  : '/mypage/payment-history',
    paymentDetail   : '/mypage/payment-detail/:orderId',
    userProfile     : '/mypage/user-profile',
    logout          : '/mypage/logout',
    toPurhcaseDetail: (orderId: string) => { return `/mypage/purchase-detail/${orderId}`; },
    toPaymentDetail : (orderId: string) => { return `/mypage/payment-detail/${orderId}`; },
    isPurchaseDetail: (path: string)    => { return path.startsWith('/mypage/purchase-detail/'); },
    isPaymentDetail : (path: string)    => { return path.startsWith('/mypage/payment-detail'); },
  },
  CART: {
    base     : '/cart',
  },
  TERM: {
    base      : '/term/:subject',
    site      : '/term/site',
    privacy   : '/term/privacy',
    agreement : '/term/agreement',
    refund    : '/term/refund',
  },
  NOTFOUND: {
    base: '*',
    notFound: '/not-found',
  },
  ERROR: {
    base   : '/error',
    toError: (error: string) => { return `/error?error=${error}`; },
  },
};
