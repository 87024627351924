import { useQuery, gql, QueryHookOptions } from '@apollo/client';

const USER_STATS_QUERY = gql`
  query user {
    user {
      stats {
        totalWecoins
        totalCoupons
        totalPoints
      }
    }
  }
`

export const useUserStatsQuery = () => {
  const options = {
    context     : {endpoint: 'auth'},
    fetchPolicy : 'network-only'
  };

  const {data, loading, error} = useQuery(USER_STATS_QUERY, options as QueryHookOptions);

  return {stats: data?.user?.stats, loading, error};
};

