import React                        from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { PATH } from 'constant';

const availableMenu = [
  {
    id       : 1,
    title    : '회원정보',
    path     : PATH.MYPAGE.userProfile,
    selected : (path: string) => path === PATH.MYPAGE.userProfile,
  },
  {
    id       : 2,
    title    : '위코인 충전',
    path     : PATH.MYPAGE.wecoin,
    selected : (path: string) => path === PATH.MYPAGE.wecoin,
  },
  {
    id       : 3,
    title    : '내 강의',
    path     : PATH.MYPAGE.myCourse,
    selected : (path: string) => path === PATH.MYPAGE.myCourse,
  },
  {
    id       : 4,
    title    : '찜한 강의',
    path     : PATH.MYPAGE.favorite,
    selected : (path: string) => path === PATH.MYPAGE.favorite,
  },
   {
    id       : 5,
    title    : '구매 내역',
    path     : PATH.MYPAGE.purchaseHistory,
    selected : (path: string) => path === PATH.MYPAGE.purchaseHistory ||  PATH.MYPAGE.isPurchaseDetail(path),
  },
   {
    id       : 6,
    title    : '충전 내역',
    path     : PATH.MYPAGE.paymentHistory,
    selected : (path: string) => path === PATH.MYPAGE.paymentHistory || PATH.MYPAGE.isPaymentDetail(path),
  },
  {
    id       : 7,
    title    : '로그아웃',
    path     : PATH.MYPAGE.logout,
    selected : (path: string) => path === PATH.MYPAGE.logout,
  },
];

const LNB = () => {
  const { pathname } = useLocation();
  const navigate     = useNavigate();

  return (
    <div className="
      md:min-w-[150px] lg:min-w-[240px] h-full 
      hidden md:flex md:flex-col
    ">
      <h1 className="
        text-xl font-black leading-1.2 
        border-b-2 border-black
        py-4 pl-2 
      ">My Page</h1>
      {availableMenu.map(({id, title, path, selected}) => (
        <span
          key       = {id}
          onClick   = {() => navigate(path)}
          className={`
            text-base
            py-3 pl-2 
            border-b
            cursor-pointer
            select-none
            ${selected(pathname) ? 'text-primary' : ''}
            hover:text-primary
          `}
        >
          {selected(pathname) ? ">" : ""} {title}
        </span>
      ))}
    </div>
  );
}

export default LNB;
