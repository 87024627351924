import { gql, useMutation } from '@apollo/client';

const DELETE_WISHLIST = gql`
  mutation deleteWishList($courseIds: [Float!]!) {
    deleteWishList(courseIds: $courseIds) {
      userId
    }
  }
`;

type Props = {
  onCompleted?: (userId: string ) => void;
  onError?    : (error: any     ) => void;
};

const useDeleteWishListMutation = ({onCompleted, onError}: Props) => {
  const [deleteWishList, { data, loading, error }] = useMutation(
    DELETE_WISHLIST, 
    {
      context    : { endpoint: 'course' },
      onCompleted: (data)  => onCompleted && onCompleted(data?.deleteWishList.userId),
      onError    : (error) => onError && onError(error),
    }
  );

  return { deleteWishList, data, loading, error };
};

export default useDeleteWishListMutation;
