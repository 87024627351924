import { gql, useMutation } from '@apollo/client';

import { User } from 'pages/types';

export const UPDATE_USER_INFO_MUTATION = gql`
  mutation ($newUserInfo: NewUserInfo!) {
    updateUserInfo(newUserInfo: $newUserInfo) {
      id
      name
      email
      phoneNumber
      profileImage
    }
  }
`;

type Props = {
  onCompleted?: (user: User ) => void;
  onError?    : (error: any ) => void;
}

const useUpdateUserInfoMutation = (props: Props) => {
  const { onCompleted, onError } = props;
  const [updateUserInfo]      = useMutation(
    UPDATE_USER_INFO_MUTATION, 
    {
      context    : { endpoint: 'auth' },
      onCompleted: (data ) => { onCompleted && onCompleted(data.updateUserProfile); },
      onError    : (error) => { onError     && onError(error) },
    },
  );

  return updateUserInfo;
}

export default useUpdateUserInfoMutation;
