import React from 'react';
import Button from 'components/core/Button';
import Icon, { IconName } from 'components/core/Icon/Icon';
import css from './NoContent.module.scss';

interface Props {
  icon     : IconName;
  title    : string;
  subText  : string;
  btnText? : string;
  onClick? : () => void;
  width?   : string;
}

const NoContent = ({ icon, title, subText, btnText, onClick, width }: Props) => {
  return (
    <div className={css.noContent}>
      <Icon name={icon} />
      <p className={css.title}>{title}</p>
      <div className={css.description}>{subText}</div>
      {btnText && (
        <Button
          size      = "x2large"
          variant   = "primary"
          onClick   = {onClick}
          className = {css.footnote}
        >
          {btnText}
        </Button>
      )}
    </div>
  );
}

export default NoContent;
