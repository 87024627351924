import React                from 'react';
import { loadTossPayments } from '@tosspayments/payment-sdk'

type PaymentMethod     = 'CARD'    | 'EASYPAY';
type EasyPay           = 'TOSSPAY' | 'KAKAOPAY' | 'NAVERPAY';
type TossPaymentsSDK   = any;
type PaymentInfo       = {
  amount        : number;
  orderId       : string;
  orderName     : string;
  customerName  : string;
  easyPay?      : EasyPay;
  successUrl    : string;
  failUrl       : string;
};

const CLIENT_IDS          = {
  'CARD'   : process.env.REACT_APP_TOSS_CLIENT_ID         ?? "",
  'EASYPAY': process.env.REACT_APP_TOSS_EASYPAY_CLIENT_ID ?? ""
};

const useTossPayments = () => {
  const [cardPayments, setCardPayments] = React.useState<TossPaymentsSDK | null>(null);
  const [easyPayments, setEasyPayments] = React.useState<TossPaymentsSDK | null>(null);
  const [isLoading, setIsLoading      ] = React.useState(false);
  const [error, setError              ] = React.useState(null);

  React.useEffect(() => {
    setIsLoading(true);

    ['CARD', 'EASYPAY'].forEach((type: string) => {
      const CLIENT_ID: string = CLIENT_IDS[type as PaymentMethod];

      loadTossPayments(CLIENT_ID)
        .then((sdk) => {
          type === 'CARD' ? setCardPayments(sdk) : setEasyPayments(sdk);
          setError(null);
        })
        .catch((err) => {
          setError(err);
          type === 'CARD' ? setCardPayments(null) : setEasyPayments(null);
        })
    });

    setIsLoading(false);
  }, []);

  const processCard = ({
    amount,
    orderId,
    orderName,
    customerName,
    successUrl,
    failUrl,
  }: PaymentInfo) => {
    cardPayments.requestPayment('카드',{
      amount       : amount,
      orderId      : orderId,
      orderName    : orderName,
      customerName : customerName,
      successUrl   : successUrl,
      failUrl      : failUrl,
    });
  };

  const processEasyPay = ({
    amount,
    orderId,
    orderName,
    customerName,
    easyPay,
    successUrl,
    failUrl,
  }: PaymentInfo) => {
    easyPayments.requestPayment('카드',{
      amount       : amount,
      orderId      : orderId,
      orderName    : orderName,
      customerName : customerName,
      flowMode     : 'DIRECT',
      easyPay      : easyPay,
      successUrl   : successUrl,
      failUrl      : failUrl,
    });
  };

  return { 
    cardPayments,
    easyPayments,
    processCard,
    processEasyPay,
    isLoading,
    error  
  };
};

export default useTossPayments;
