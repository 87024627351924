export enum PaymentStatus {
  DONE     = "DONE",
  CANCELED = "CANCELED"
};

export enum CourseStatus {
  OPEN        = 'OPEN',
  CLOSED      = 'CLOSED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED   = 'COMPLETED',
}

export enum OrderStatus {
	PENDING   = "PENDING",
	PURCHASED = "PURCHASED",
	CANCELED  = "CANCELED"
};

export enum AccessType {
  PURCHASE = "PURCHASE",
  RENTAL   = "RENTAL",
};

export enum Difficulty {
  beginner     = 'beginner',
  intermediate = 'intermediate',
  advanced     = 'advanced',
}

export enum ProductType {
  COURSE  = 'COURSE',
  CHAPTER = 'CHAPTER',
  WECOIN  = 'WECOIN',
}

export type ProductInfo = {
  name      : string;
  price     : number;
  product   : ProductType;
  productId : number;
};

export type ContentInfo = {
  id          : number;
  courseId    : number;
  title       : string;
  sequence    : number;
  courseTitle : string;
  status      : CourseStatus | null;
}

export type UserAccessInfo = {
  progress : number;
  status   : CourseStatus;
}

export type CourseChapterInfo = {
  totalChapterCount : number;
  chapters          : {id: number, sequence: number, title: string}[];
}

export type Course = {
  id              : number;
  title           : string;
  introduction    : string;
  ogImage         : string | null;
  progress        : number;
  price           : number;
  description?    : string;
  difficulty?     : Difficulty;
  thumbnail?      : string;
  coverImage?     : string;
  status?         : CourseStatus;
  chapters?       : Chapter[];
  purchased?      : boolean;
  userAccessInfo? : UserAccessInfo;
  chapterInfo?    : CourseChapterInfo;
}

export type Cart = {
  userId : number;
  items  : CartItem[];
}

export type CartItem = {
  id          : number;
  productId   : number;
  productName : string;
  price       : number;
}

export type OrderItem = {
  id          : number;
  userId      : number;
  productName : string;
  price       : number;
  productType : ProductType;
  productId   : number;
  accessType  : AccessType;
  status?     : OrderStatus;
  createdAt   : Date;
}

export type Order = {
  id            : string;
  name          : string;
  userId        : number;
  totalPrice    : number;
  status        : OrderStatus;
  items         : OrderItem[];
  paymentMethod : PaymentMethod;
  purchaseDate  : Date;
  createdAt     : Date;
}

export type Chapter = {
  id          : number;
  sequence    : number;
  nextSequence: number;
  title       : string;
  summary     : string;
  content     : string;
  ogImage     : string;
  courseId    : number;
};

export type Stats = {
  totalWecoins       : number;
  totalCoupons       : number;
  totalPoints        : number;
  totalCourses       : number;
  totalCartItems     : number;
  totalWishListItems : number;
}

export type WishList = {
  courseId     : number;
  title        : string;
  introduction : string;
  thumbnail    : string;
  price        : number;
}

export type User = {
  id                   : number;
  name                 : string;
  nickname             : string;
  birthDate?           : string;
  email?               : string;
  phoneNumber?         : string;
  profileImage?        : string;
  gender?              : string;
  stats?               : Stats;
  completedChapterTour?: boolean;
  createdAt            : string;
};

export interface Bookmark {
  userId                : number;
  courseContentInfoList : ContentInfo[];
};

export type PaymentResult = {
  orderId   : string;
  amount    : number;
  orderName : string;
  status    : string;
  method    : string;
};

export type Purchase = {
  id?       : number;
  productId : number;
  price     : number;
  name      : string;
};

export enum PaymentMethod {
  CARD     = 'CARD',
  EASY_PAY = 'EASY_PAY',
  FREE     = 'FREE',
  WECOIN   = 'WECOIN',
}

export type Payment = {
  userId        : number;
  orderId       : string;
  orderName     : string;
  amount        : number;
  status        : PaymentResult;
  paymentMethod : PaymentMethod;
  approvedAt    : string;
  receiptUrl    : string;
};


export enum WecoinAmount {
  TWO_THOUSAND           = 2000,
  FIVE_THOUSAND          = 5000,
  TEN_THOUSAND           = 10000,
  TWENTY_THOUSAND        = 20000,
  THIRTY_THOUSAND        = 30000,
  FIFTY_THOUSAND         = 50000,
  SEVENTY_THOUSAND       = 70000,
  HUNDRED_THOUSAND       = 100000,
  TWO_HUNDRED_THOUSAND   = 200000,
  THREE_HUNDRED_THOUSAND = 300000,
  FOUR_HUNDRED_THOUSAND  = 400000,
  FIVE_HUNDRED_THOUSAND  = 500000,
};

export type AlertType = 'error' | 'info' | 'positive' | 'warn';
