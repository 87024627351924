import React, { ReactNode, useEffect } from 'react';
import { useLockBodyScroll }           from 'utils/hooks';
import css                             from './BaseModal.module.scss';
import ModalPortal                     from './ModalPortal';

// Core Modal
interface Props {
  switchModal             : () => void
  children?               : ReactNode,
  closeOnBackgroundClick? : boolean
}

const Modal = ({ switchModal, children, closeOnBackgroundClick = true }: Props) => {


  /** 
   * Close the modal when back button is clicked
   */
  const closeOnNavigation = () => {
    const handlePopState = (event: PopStateEvent) => {
      switchModal();
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }

  /**
   * Close the modal when the background is clicked
   */
  const closeIfBackgroundClicked = () => {
    if (closeOnBackgroundClick) {
      switchModal();
    }
  }

  useEffect(closeOnNavigation, []);
  useLockBodyScroll()

  return (
    <ModalPortal>
      <section className={css.container}>
        <div className={css.dimmedBackground} onClick={closeIfBackgroundClicked} />
        <article onClick={e => e.stopPropagation()}>{children}</article>
      </section>
    </ModalPortal>
  )
}

export default Modal;
