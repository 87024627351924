import React           from 'react';
import { useNavigate } from 'react-router-dom';

import Button                  from 'components/core/Button';
import { PATH, AlertMessage  } from 'constant';
import { pushPurchaseEvent   } from 'utils/gtm';
import { usePurchaseMutation } from '../hooks';
import { 
  PaymentStatus,
  ProductType      
} from 'pages/types';
import { 
  useModal,
  ModalTypes  
} from 'contexts/ModalContext';

type Props = {
  course               : any;
  sequence             : number;
  insufficientBalance  : boolean;
  userAgreementChecked : boolean;
  onSuccess            : () => void;
  onClose              : () => void;
};

const PurchaseButton = ({
  course,
  sequence,
  onSuccess,
  onClose,
  insufficientBalance,
  userAgreementChecked,
}: Props) => {
  const modal        = useModal();
  const navigate     = useNavigate(); 
  const { purchase } = usePurchaseMutation(Number(course.id), ProductType.COURSE);

  const handleInsufficientBalance = () => {
    localStorage.setItem(
    'productInfo', 
    JSON.stringify({
      courseId: course.id,
      sequence,
      productType: ProductType.COURSE
    })
  );

    // If user has insufficient balance, 
    // navigate to wecoin charge page
    onClose();
    return navigate(PATH.PAYMENT.wecoin);
  };

  const handlePurchaseSuccess = (data: Record<string, any>) => {
    // If purchase is successful, call onSuccess and onClose
    // which will close the modal and render the chapter page.
    // Also, push purchase event to GTM
    const purchaseEvent = {
      transactionId: data.orderId,
      amount       : data.amount,
      currency     : 'KRW',
      products     : [{
        id          : course?.id,
        productType : ProductType.COURSE,
        name        : data.orderName,
        price       : course?.price,
        quantity    : 1,
      }],
    }
    pushPurchaseEvent(purchaseEvent);

    onSuccess();
    onClose();
    return;
  }

  const handlePurchaseError = (error: string) => {
    if (error.toUpperCase() === "INSUFFICIENT_BALANCE" ) {
      // If user's balance is insufficient while processing on the server,
      // navigate to wecoin charge page
      onClose();

      modal?.openModal(ModalTypes.ALERT, {
        type   : 'error',
        dialog : AlertMessage.payment.error.insufficientBalance,
      }).then(() => {
        navigate(PATH.PAYMENT.wecoin);
      });

      return;
    } else {
      // All other errors are handled by the error page
      onClose();

      const errorPage = PATH.ERROR.toError(error);
      return navigate(errorPage);
    }
  }

  const processPurchase = async () => {
    if( insufficientBalance ) {
      return handleInsufficientBalance();
    };

    const status = await purchase();

    if (status.data.purchase.status === PaymentStatus.DONE) {
      return handlePurchaseSuccess(status.data.purchase);
    }

    return handlePurchaseError(status.data.purchase.message);
  }

  return (
    <div className="w-full flex flex-col justify-center py-6 pb-2">
      <Button 
        variant  = "primary"
        size     = "full"
        onClick  = {processPurchase}
        disabled = {!userAgreementChecked}
      >
        {insufficientBalance ? "Wecoin 충전하기" : "구매하기"}
      </Button>
    </div>
  );
};

export default PurchaseButton;
