import React from 'react';

const Tag = ({ tag }: { tag: string }) => {
  return (
    <span 
      key       = {tag}
      className ="
        inline-block rounded-full bg-gray-200 
        text-xs font-light sm:text-sm sm:font-semibold text-gray-700
        px-3 sm:py-1 mr-1 sm:mr-2 sm:mb-1"
      >
        {tag}
      </span>
  );
};

export default Tag;
