import { useEffect } from 'react';

export type Text              = {text: string};
export type Line              = {line: number};
export type CodeHighlight     = {highlights: {line: number}[]};
export type Code              = {files: Record<string, any>};
export type CodeAnnotation    = {annotations: {dataId: string, line: number, startColumn: number, endColumn: number}[]};
export type CursorPosition    = {position: number};
export type VideoEventPayload = Code | CodeHighlight | CodeAnnotation | CursorPosition | Text | Line;
export type Subscriber        = (events: VideoEvent[]) => void;
export type VideoEvent        = {
  type    : string;
  payload?: VideoEventPayload;
};

export enum VideoEventType {
  START                 = 'START',
  RESET                 = 'RESET',
  EDITOR_RESET          = 'EDITOR_RESET',
  PLAY                  = 'PLAY',
  ENDED                 = 'ENDED',
  READY                 = 'READY',
  PAUSE                 = 'PAUSE',
  INSERT_CODE           = 'INSERT_CODE',
  SHOW_CODE             = 'SHOW_CODE',
  SHOW_VIDEO            = 'SHOW_VIDEO',
  SHOW_TEXT             = 'SHOW_TEXT',
  SET_ACTIVE_FILE       = 'SET_ACTIVE_FILE',
  SCROLL_CODE           = 'SCROLL_CODE',
  SCROLL_TEXT           = 'SCROLL_TEXT',
  TEXT_UPDATE           = 'TEXT_UPDATE',
  TEXT_APPEND           = 'TEXT_APPEND',
  CODE_UPDATE           = 'CODE_UPDATE',
  CODE_HIGHLIGHT        = 'CODE_HIGHLIGHT',
  CODE_ANNOTATION       = 'CODE_ANNOTATION',
  CLEAR_CODE_ANNOTATION = 'CLEAR_CODE_ANNOTATION',
  CLEAR_CODE_HIGHLIGHT  = 'CLEAR_CODE_HIGHLIGHT',
};

export const useVideoEvent = (subscriber: Subscriber) => {
  useEffect(() => {
    videoEventPublisher.subscribe(subscriber);
    return () => videoEventPublisher.unsubscribe(subscriber);
  }, [subscriber]);
}

class VideoEventPublisher {
  private subscribers : Subscriber[];

  constructor() {
    this.subscribers = [];
  };

  publish(events: VideoEvent[]): void {
    this.subscribers.forEach(subscriber => subscriber(events));
  };

  subscribe(subscriber: Subscriber) {
    this.subscribers.push(subscriber);
  }

  unsubscribe(subscriber: Subscriber) {
    this.subscribers = this.subscribers.filter(s => s !== subscriber);
  }
}

export const videoEventPublisher = new VideoEventPublisher();
