import React           from 'react'
import { useNavigate } from 'react-router-dom'

import { PATH                  } from 'constant';
import { defaultContainerWidth } from 'styles/customTailwindCSS';
import Button                    from 'components/core/Button'
import Error                     from 'assets/icon/svg/xl/caption_error.svg'

export const PaymentCanceledPage = () => {
  const navigate     = useNavigate()
  const queryParams  = new URLSearchParams(location.search);
  const errorMessage = queryParams.get('message');

  const navigateToWecoinCharge = () => { navigate(PATH.PAYMENT.wecoin); }

  return (
    <div className={`${defaultContainerWidth} mx-auto mt-8 mb-16 px-6 sm:mb-25`}>
      <h1 className="text-2xl font-bold mb-1 pb-4 border-b border-gray-300">결제가 취소되었습니다.</h1>
      <div className="flex flex-col pb-2 gap-4">
        <img alt="결제취소" src={Error} className="w-full md:w-7/12 mx-auto" />
        <p className="text-center text-lg md:text-xl font-semibold">{errorMessage ?? '결제가 취소되었습니다.'}</p>
        <p className="text-center md:text-xl">다시 결제를 진행해 주세요.</p>
      </div>
      <div className="flex w-full mt-4 pt-8 border-t border-gray-400 text-center">
        <Button variant="primary" size="x6large" onClick={navigateToWecoinCharge} className="mx-auto">
          다시 시도하기
        </Button>
      </div>
    </div>
  );
};
