import React, { useState } from 'react';
import { useSandpack     } from '@codesandbox/sandpack-react';

import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';

type Props = {
  showPreview : boolean;
};

const CopyCodeButton = ({showPreview}: Props) => {
  const { sandpack }              = useSandpack();
  const [isHovered, setIsHovered] = useState(false);

  const disabled = () => { 
    return (
      showPreview || 
      sandpack.files[sandpack.activeFile].code.length === 0
    );
  };

  const copyCode = () => {
    const code = sandpack.files[sandpack.activeFile].code;

    navigator.clipboard.writeText(code).then(() => {
      alert('코드가 복사되었습니다!');
    }, (err) => {
      console.error('Failed to copy: ', err);
    });
  };


  const Tooltip = () => {
    return (
      <div className="
        absolute bottom-full left-1/2 transform -translate-x-1/2 -translate-y-1
        w-max mb-1 p-1 
        text-sm text-primary whitespace-nowrap
        bg-lightgrey rounded-md shadow-lg">
        <div className="relative">
          <span>{"코드 복사하기"}</span>
          <div className="
            absolute 
            left-1/2 transform -translate-x-1/2 translate-y-full 
            mt-[-2px] w-0 h-0 
            border-l-[6px] border-l-transparent 
            border-r-[6px] border-r-transparent 
            border-t-[6px] border-t-lightgrey
          "></div>
        </div>
      </div>
    );
  }

  return (
    <div className={`
      code-copy-button
      relative inline-block
      bg-white 
      border border-primary shadow-md rounded-full
      text-primary 
      ${disabled() ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
    `}>
      <ClipboardDocumentIcon
        className    = "h-7 w-7 p-1 md:h-9 md:w-9 md:p-2"
        onClick      = {() => !disabled() && copyCode()          } 
        onMouseEnter = {() => !disabled() && setIsHovered(true)  } 
        onMouseLeave = {() => !disabled() && setIsHovered(false) }
      />
      {isHovered && !disabled() && <Tooltip />}
    </div>
  );
};

export default CopyCodeButton;
