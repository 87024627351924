type Props = {
  link?        : string;
  showAlert?   : boolean;
  onCompleted  : ()           => void;
  onError      : (error: any) => void;
};

/**
 * 클립보드에 텍스트를 복사합니다.
 * @param link 복사할 링크
 * @param showAlert 복사 성공/실패 여부에 따라 알림창을 띄울지 여부
 */
export const copyToClipboard = async ({ link, showAlert = true, onCompleted, onError}: Props) => {
  try {
    await navigator.clipboard.writeText(link || window.location.href);

    showAlert && onCompleted();
  } catch (error) {
    showAlert && onError(error);  
  }
};
