import React           from 'react'
import { useNavigate } from 'react-router-dom'

import Icon                  from 'components/core/Icon/Icon'
import WecodeLogo            from 'components/core/Icon/Logo/Wecode'
import { ModalPortal       } from 'components/modal/BaseModal';
import { useLockBodyScroll } from 'utils/hooks'
import AuthenticatedMenu     from 'components/core/GNB/AuthenticatedMenu'
import UnauthenticatedMenu   from 'components/core/GNB/UnauthenticatedMenu'
import { useUser   }         from 'contexts/UserContext'
import css                   from './HamburgerMenuModal.module.scss'

interface Props {
  onClose: () => void
  items  : Record<string, any>[]
}

const HamburgerMenuModal = ({onClose, items}: Props) => {
  const user            = useUser()
  const navigate        = useNavigate()

  useLockBodyScroll()

  return (
    <ModalPortal>
      <section className={css.container} onClick={onClose}>
        <article onClick={e => e.stopPropagation()} className={css.modal}>
          <nav className={css.nav}>
            <div className={css.logoContainer}>
              <WecodeLogo onClick={onClose} />
            </div>
            <Icon name="close" onClick={onClose} />
          </nav>
          <div className={css.educationWrapper}>
            {items.map(({ id, path, content, shouldLogin }) => {
              if (!user?.isAuthenticated && shouldLogin) return

              return (
                <button
                  className = {css.educationItem}
                  type      = "button"
                  key       = {id}
                  onClick   = {() => {
                    navigate(path)
                    onClose()
                  }}
                >
                  {content}
                </button>
              )
            })}
          </div>
          {user?.isAuthenticated ? (
            <AuthenticatedMenu switchModal={onClose} />
          ) : (
            <UnauthenticatedMenu />
          )}
        </article>
      </section>
    </ModalPortal>
  )
}

export default HamburgerMenuModal;
