import { gql, useLazyQuery } from '@apollo/client';
import { User              } from 'pages/types';

export const GET_USER_PROFILE = gql`
  query {
    user {
      id
      name
      nickname
      birthDate
      email
      phoneNumber
      profileImage
      gender
      completedChapterTour
      createdAt
    }
  }
`;

const useGetUserProfileQuery = () => {
  const [fetchUserProfile, { data, loading, error }] = useLazyQuery<{user: User}>(
    GET_USER_PROFILE,
    {
      fetchPolicy : 'cache-and-network',
    },
  );

  return { fetchUserProfile, user: data?.user, loading, error };
};

export default useGetUserProfileQuery;
