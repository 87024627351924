import React           from 'react';
import { useNavigate } from 'react-router-dom';

import { PATH  } from 'constant';
import { Stats } from 'pages/types';

type Props = {
  stats: Stats; 
}

const UserStats = ({stats}: Props) => {
  const navigate  = useNavigate();
  const userStats = [
    {
      title  : 'Wecoin',
      value  : stats.totalWecoins + '원',
      action : '충전 하기 »',
      link   : () => navigate(PATH.MYPAGE.wecoin),
    },
    {
      title  : '리워드 포인트',
      value  : stats.totalPoints + '원',
      action : '',
    },
    {
      title  : '쿠폰',
      value  : stats.totalCoupons + '개',
      action : '쿠폰 등록 »',
    },
    {
      title: '내 강의',
      value: stats.totalCourses + '개',
      action: '내 강의 보기 »',
      link   : () => navigate(PATH.MYPAGE.myCourse),
    },
    {
      title: '찜한 강의',
      value: stats.totalWishListItems + '개',
      action: '찜한 강의 보기 »',
      link   : () => navigate(PATH.MYPAGE.favorite),
    },
  ];

 return (
  <div className="border border-gray-700 rounded-md shadow-sm lg:p-4 max-w-4xl mx-auto mt-6">
    <div className="flex flex-col lg:flex-row lg:flex-wrap">
      {userStats.map((stat, index) => (
        <div
          key       = {index}
          onClick   = {stat.link}
          className = {`
            ${stat.link ? `cursor-pointer` : ``}
            flex flex-row lg:flex-col lg:gap-4 items-center
            text-left lg:text-center
            py-4 px-4 lg:px-2 w-full lg:w-1/3
            ${index < 3 ? 'lg:border-b-2' : ''}
            ${index % 3 !== 2 ? 'lg:border-r-2' : ''}
            border-gray-100
            ${index === userStats.length - 1 ? '' : 'border-b-2 border-gray-100 '}
            ${index === userStats.length - 1 ? '' : 'lg:border-b-0 lg:border-gray-100'}
          `}
        >
          <div className="w-1/4 lg:w-auto text-gray-500 text-sm font-bold">{stat.title}</div>
          <div className="w-2/4 lg:w-auto text-blue-500 lg:text-lg font-bold mx-2">{stat.value}</div>
          <div className="w-1/4 lg:w-auto text-gray-400 text-xs text-right lg:text-center">{stat.action}</div>
        </div>
      ))}
    </div>
  </div>
  );
};

export default UserStats;
