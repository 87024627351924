import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button                        from 'components/core/Button';
import { RuleType                  } from 'components/core/Forms/Input/&Label';
import { InputType                 } from 'components/core/Forms/Input/&Base';
import { LabeledInput              } from 'components/core/Forms';
import { useModal, ModalTypes      } from 'contexts/ModalContext';
import { useUpdateUserInfoMutation } from './hooks';
import { 
  AlertMessage,
  Validations,
  PATH 
} from 'constant';

export interface InputData {
  id          : number;
  label       : string;
  placeholder : string;
  name        : string;
  type        : InputType;
  rules       : RuleType[];
  disabled?   : boolean;
}

const INPUT_DATA: InputData[] = [
  {
    id          : 1,
    label       : '이름*',
    placeholder : '이름을 입력해주세요',
    name        : 'name',
    type        : 'text',
    rules       : ['name'],
  },
  {
    id          : 2,
    label       : '휴대전화 번호*',
    placeholder : '하이픈,‘-’없이 숫자만 입력해주세요',
    name        : 'phoneNumber',
    type        : 'number',
    rules       : ['phoneNumber'],
    disabled    : true,
  },
  {
    id          : 3,
    label       : 'email*',
    placeholder : '이메일을 입력해주세요',
    name        : 'email',
    type        : 'email',
    rules       : ['email'],
  },
];

type Props = {
  fetchUserProfile: () => void;
  handleChange : (e : React.ChangeEvent<HTMLInputElement>) => void;
  formData     : {
    [userName    : string] : string;
    phoneNumber  : string;
    email        : string;
    profileImage : string;
  };
};

const UserInfo = ({fetchUserProfile, handleChange, formData}: Props) => {
  const HYPHEN_REG_PATTERN      = /^(\d{2,3})(\d{3,4})(\d{4})$/;
  const [editMode, setEditMode] = useState<boolean>(false);
  const modal                   = useModal();
  const navigate                = useNavigate();
  const updateUserInfo          = useUpdateUserInfoMutation({
    onCompleted: () => {
      modal?.openModal(ModalTypes.ALERT, {
          show   : true,
          type   : 'positive',
          dialog : AlertMessage.auth.positive.edit,
      }).then(() => fetchUserProfile());
    },
    onError(_) {
      modal?.openModal(ModalTypes.ALERT, {
        show   : true,
        type   : 'error',
        dialog : AlertMessage.auth.error.retry,
      }).then(() => navigate(PATH.MYPAGE.userProfile));
    },
  });

  const submitNewProfileData = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const isAllPassed = INPUT_DATA.every(({ name, rules }) =>
      rules.every((ruleType: any) => Validations[ruleType].rule(formData[name])),
    );

    if (!isAllPassed) {
      modal?.openModal(ModalTypes.ALERT, {
        show   : true,
        type   : 'error',
        dialog : AlertMessage.common.info.required,
      });

      return;
    }

    modal?.openModal(ModalTypes.ALERT, {
      show            : true,
      dialog          : AlertMessage.auth.info.saveUserInfo,
      hasCancelButton : true,
      onSuccess       : () => {
        updateUserInfo({
          variables: {
            newUserInfo: {
              name        : formData.name,
              email       : formData.email,
              phoneNumber : formData.phoneNumber.replace(HYPHEN_REG_PATTERN, '$1-$2-$3'),
            },
          },
        });
      },
    });
  };

  return (
    <div className="flex-1 py-4 lg:p-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">회원정보</h1>
        <Button size="medium" variant="primary" onClick = {() => setEditMode(!editMode)}>
          {editMode ? '취소' : '회원정보 수정'}
        </Button>
      </div>
      <form onSubmit={submitNewProfileData}>
        {INPUT_DATA.map((input) => {
          const { id, label, placeholder, name, type, rules, disabled } = input;

          return (
            <div key={id} className="mb-4">
            {editMode ? (
              <LabeledInput
                name        = {name}
                value       = {formData[name]}
                label       = {label}
                type        = {type}
                onChange    = {handleChange}
                placeholder = {placeholder}
                rules       = {rules}
                className   = "w-full rounded-md"
              />
            ) : (
              <>
                <label className="block text-gray-700 text-base font-bold mb-2">
                  {label}
                </label>
                <p className="w-full border rounded-md p-3">
                  {formData[name]}
                </p>
              </>
            )}
          </div>
          );
        })}
        {editMode && (
          <div className="flex justify-end">
            <Button size="large" variant="primary" type="submit">
              저장
            </Button>
          </div>
        )}
      </form>
    </div>
  );
};

export default UserInfo;
