import React                                from 'react'
import { ReactComponent as WecodeLogoIcon } from 'assets/Logo_wecode_md.svg'

type Props = { 
  width?   : string
  onClick? : () => void
}

const WecodeLogo = ({ onClick, width }: Props) => {
  const FULL_WIDTH = { width: '100%' }

  return (
    <div style={width ? {width: width} : FULL_WIDTH } onClick = {onClick}>
        <WecodeLogoIcon />
    </div>
  )
}

export default WecodeLogo
