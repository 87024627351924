import React from 'react';
import css   from './BulletList.module.scss';

interface Props {
  children: React.ReactNode;
}

const BulletList = ({ children }: Props) => {
  const convertToList = (child: string) => {
    return child.split('\n').filter(child => child.length > 0).map((text) => <li>{text.trim()}</li>);
  }

  return (
    <ul className={css.bulletList}>
      {React.Children.map(children, (child) => (
        typeof child === 'string' ? convertToList(child) : child
      ))}
    </ul>
  );
};

export default BulletList;

