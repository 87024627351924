import React, { 
  FC, 
  useContext, 
  createContext,
  ReactNode,
  useState,
  useEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { AUTH_TOKEN  } from 'constant';
import { 
  useGetUserProfileQuery,
  useGetWishListQuery
} from './hooks';
import { 
  User,
  WishList 
} from 'pages/types';

interface UserProviderProps {
  children: ReactNode
};

export interface UserContextType {
  user            : User       | undefined,
  wishList        : WishList[] | undefined,
  isAuthenticated : boolean,
  handleLogin     : (authToken   : string) => void,
  handleLogOut    : (redirectUrl?: string) => void,
  fetchUserProfile: () => void,
};

const UserContext = createContext<UserContextType | null>(null);

export const useUser                             = () => useContext(UserContext)
export const UserProvider: FC<UserProviderProps> = ({ children }) => {
  const navigate                                          = useNavigate();
  const isLoggedIn                                        = localStorage.getItem(AUTH_TOKEN) !== null;
  const [isAuthenticated, setIsAuthenticated]             = useState(isLoggedIn);
  const { fetchUserProfile, user, error: userError      } = useGetUserProfileQuery();
  const { fetchWishList, wishList, error: wishListError } = useGetWishListQuery();

  const handleLogin  = (authToken: string) => { 
    localStorage.setItem(AUTH_TOKEN, authToken);
    setIsAuthenticated(true);
  };

  const handleLogOut = (redirectUrl?: string) => {
    localStorage.removeItem(AUTH_TOKEN);
    setIsAuthenticated(false);
    redirectUrl && navigate(redirectUrl);
  };

  useEffect(() => { 
    const updateUserContext = () => {
      if (isAuthenticated) {
        fetchUserProfile();
        fetchWishList();
      }
    };

    updateUserContext();
  } , [isAuthenticated]);

  useEffect(() => { 
    const handleNotAuthorized = () => {
      const errors = [userError, wishListError];
      
      if (errors.some(error => error?.message === 'NOT AUTHORIZED')) {
        handleLogOut();
      }
    };

    handleNotAuthorized();
  } , [userError, wishListError]);

  const userContext = {
    user,
    wishList,
    isAuthenticated,
    handleLogin,
    handleLogOut,
    fetchUserProfile,
  };
  
  return (
    <UserContext.Provider value={userContext}>
      {children}
    </UserContext.Provider>
  );
}
