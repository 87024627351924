import { convertQueryString                    } from 'utils/querys'
import { kakaoQueryData, KAKAO_BASIC_OAUTH_URL } from './data'

/******************************************************
 * Handles the Kakao OAuth login process
 *
 * @param {string} redirectURL - The URL to redirect to after the login process
 ******************************************************/
export const navigateToKakaoAuth = (redirectURL: string) => {
  const kakaoQueryString = convertQueryString(kakaoQueryData(redirectURL))
  window.location.href = KAKAO_BASIC_OAUTH_URL + kakaoQueryString
}
