import React from "react";

const InsufficientBalanceMessage = () => {
   return (
    <div className="flex items-center pt-6 -mb-4 pl-1 md:pl-0 md:mx-auto w-fit">
      <div className = "ms-2 text-xs md:text-sm">
        위코인을 충전해 주세요!
      </div>
      <div className = "ms-2 text-xs md:text-sm text-red-400">
        충전과 동시에 해당 수업이 구매돼요.
      </div>
    </div>
  );
};

export default InsufficientBalanceMessage;
