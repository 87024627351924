import React, { useEffect } from 'react';
import { useNavigate      } from 'react-router-dom';
import {
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/solid';

import Button           from 'components/core/Button';
import { PATH         } from 'constant';
import { CourseStatus } from 'pages/types';
import { 
  useChapterInfoLazyQuery,
  useChapterAccessInfoQuery
} from '../../hooks';

type Props = {
  chapter : { sequence: number, courseId: number };
};

const ChapterDropdown = ({chapter}: Props) => {
  const navigate                                   = useNavigate();
  const dropdownRef                                = React.useRef<HTMLDivElement>(null);
  const [dropdownOpen      ,setDropdownOpen      ] = React.useState(false);
  const [storedChapterInfo ,setStoredChapterInfo ] = React.useState<any>(null);
  const { getChapterInfo   ,chapterInfo          } = useChapterInfoLazyQuery({courseId: chapter.courseId});
  const { chapterStatus, authorized              } = useChapterAccessInfoQuery({courseId: chapter.courseId});

  const chapters          = storedChapterInfo?.chapters          || [];
  const totalChapterCount = storedChapterInfo?.totalChapterCount || 0;

  useEffect(() => {
    // Use stored chapter info if it exists in the session storage
    // so that we don't have to fetch it again
    const storedData = sessionStorage.getItem(`chapterInfo-${chapter.courseId}`);
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setStoredChapterInfo(parsedData);
    } else {
      getChapterInfo();
    }
  }, [getChapterInfo]);

  useEffect(() => {
    if (chapterInfo) {
      // Store the chapter info in the session storage
      // so that we don't have to fetch it again
      // next time the user visits the page
      sessionStorage.setItem(`chapterInfo-${chapter.courseId}`, JSON.stringify(chapterInfo));
      setStoredChapterInfo(chapterInfo);
    }
  }, [chapterInfo, chapter.courseId]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const statusEmoji: Record<any, any> = {
    [CourseStatus.COMPLETED]   : '✅',
    [CourseStatus.IN_PROGRESS] : '👩🏻‍💻',
  };

  const activeStatus = [CourseStatus.COMPLETED, CourseStatus.IN_PROGRESS];

  return (
    <div className="chapter-list-dropdown relative" ref={dropdownRef}>
      <Button
        variant  = "lineGrey"
        size     = "medium"
        onClick  = {() => setDropdownOpen(!dropdownOpen)}
      >
        <span>{chapter.sequence} / {totalChapterCount}</span>
        <span className="">
        {dropdownOpen ? (
          <ChevronUpIcon className="h-4 w-4 ml-1 lg:ml-2" />
        ) : (
          <ChevronDownIcon className="h-4 w-4 ml-1 lg:ml-2" />
        )}
        </span>
      </Button>
      {dropdownOpen && (
        <div className="
          absolute left-1/2 transform -translate-x-1/2 bottom-full 
          mb-1 max-w-72 max-h-[300px]
          bg-white border border-gray-300 rounded shadow-lg 
          overflow-y-auto z-10 animate-dropdown text-nowrap
        ">
          <div className="relative">
            {chapters.map((ch: any) => (
              <div
                key       = {ch.id}
                className = "flex items-center justify-between px-4 py-2 hover:bg-gray-100 cursor-pointer"
                onClick   = {() => {
                  setDropdownOpen(false);
                  navigate(PATH.COURSE.toChapter(chapter.courseId, ch.sequence));
                }}
              >
                <span className={`
                  ${activeStatus.includes(chapterStatus[ch.id]) ? '' : 'text-gray-400'}
                  ${chapterStatus[ch.id] === CourseStatus.IN_PROGRESS ? 'font-bold' : ''}
                  ${(!authorized && ch.price === 0) ? 'text-themeBlack' : ''}
                  max-w-64 truncate mr-2
                `}>
                  {ch.sequence}. {ch.title}
                </span>
                { authorized && (<span className="font-bold">{statusEmoji[chapterStatus[ch.id]]}</span>)  }
                { (!authorized && ch.price === 0) && ( <span className="text-primary text-sm">Free</span>)}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChapterDropdown;
