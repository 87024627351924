import React, { useEffect, useState } from 'react';

import { compareObjects       } from 'utils/validation';
import { useModal, ModalTypes } from 'contexts/ModalContext';
import { AlertMessage         } from 'constant';
import Smile                    from 'assets/icon/svg/xl/smile.svg';
import UserStats                from './UserStats';
import UserInfo                 from './UserInfo';
import ProfileImage             from './ProfileImage';
import {
  useInputChangeValue,
  useUserQuery,
} from './hooks';

const UserProfile = () => {
  const modal                           = useModal();
  const [initUserData, setInitUserData] = useState({
    name        : '',
    email       : '',
    phoneNumber : '',
  });

  const { handleChange, formData, setFormData } = useInputChangeValue();
  const { user, loading, fetchUser            } = useUserQuery({
    onCompleted: (user) => {
      setFormData({
        name         : user.name || user.nickname || 'User',
        email        : user.email ?? '',
        phoneNumber  : user.phoneNumber?.replaceAll('-', '') ?? '',
        profileImage : user.profileImage || Smile,
      });
      setInitUserData({
        name        : user.name || user.nickname || 'User',
        email       : user.email ?? '',
        phoneNumber : user.phoneNumber?.replaceAll('-', '') ?? '',
      });
    },
    onError: (_) => {
      modal?.openModal(ModalTypes.ALERT, {
        show             : true,
        type             : 'error',
        dialog           : AlertMessage.common.error.unknown,
        hasHelpEmailInfo : true,
      });
    },
  });

  const isMatch = compareObjects(initUserData, formData);

  useEffect(() => {
    modal?.openModal(ModalTypes.PROMPT, { when: !isMatch });
  }, [isMatch]);

  if (loading || !user || !user.stats) return null;

  return (
    <div className="w-full mx-auto">
      <div className="flex flex-col lg:flex-row">
        <ProfileImage 
          profileImage     = {formData.profileImage}
          fetchUserProfile = {fetchUser}
        />
        <UserInfo 
          fetchUserProfile = {fetchUser}
          handleChange     = {handleChange}
          formData         = {formData}
        />
      </div>
      <div className="lg:p-4">
        <UserStats stats={user.stats} />
      </div>
    </div>
  );
};

export default UserProfile;

