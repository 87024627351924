import React from 'react';
import MarkdownContent from 'components/core/MarkdownContent';


const Agreement = () => <MarkdownContent content={MD.trim()} />;

export default Agreement;

const MD = `
# 개인정보 수집 및 이용 동의서

개인정보보호법에 따라 wecode에 회원가입 신청하시는 분께 수집하는 개인정보의 항목, 개인정보의 수집 및 이용목적, 개인정보의 보유 및 이용 기간, 동의 거부권 및 동의 거부 시 불이익에 관한 사항을 안내드리오니 자세히 읽은 후 동의하여 주시기 바랍니다. <br></br>

<br>

## 1. 수집하는 개인정보 
<br>

이용자가 회원제 서비스를 이용하기 위해 회원가입을 할 경우, wecode는 서비스 이용을 위해 필요한 최소한의 개인정보를 수집합니다.<br></br>

회원가입 시점에 wecode가 이용자로부터 수집하는 개인정보는 아래와 같습니다.

- 회원 가입 시 필수 항목으로 이메일, 프로필 사진, 닉네임, 카카오톡 채널 추가 상태 및 내역, 전화번호를, 선택항목으로 성별, 출생 연도, 생일, 이름을 수집합니다.

만약 이용자가 입력하는 생년월일이 만 14세 미만 아동일 경우에는 법정대리인 정보(법정대리인의 이름, 생년월일, 성별, 중복가입 확인정보(DI), 휴대전화 번호)를 추가로 수집합니다.<br></br>

서비스 이용 과정에서 이용자로부터 수집하는 개인정보는 아래와 같습니다.

- 회원정보 또는 개별 서비스에서 프로필 정보(이름, 프로필 사진)를 설정할 수 있습니다.
- wecode 내의 개별 서비스 이용, 이벤트 응모 및 경품 신청 과정에서 해당 서비스의 이용자에 한 해 추가 개인정보 수집이 발생할 수 있습니다. 추가로 개인정보를 수집할 경우에는 해당 개인정보 수집 시점에서 이용자에게 '수집하는 개인정보 항목, 개인정보의 수집 및 이용목적, 개인정보의 보관 기간’에 대해 안내드리고 동의를 받습니다.

서비스 이용 과정에서 IP 주소, 쿠키, 서비스 이용 기록, 기기 정보가 생성되어 수집될 수 있습니다. 구체적으로 1) 서비스 이용 과정에서 이용자에 관한 정보를 자동화된 방법으로 생성하여 이를 저장(수집) 하거나 2) 이용자 기기의 고유한 정보를 원래의 값을 확인하지 못하도록 안전하게 변환하여 수집합니다. 이와 같이 수집된 정보는 개인 정보와의 연계 여부 등에 따라 개인 정보에 해당할 수 있고, 개인정보에 해당하지 않을 수도 있습니다.

<br><br>

## 2. 수집한 개인정보의 이용
<br>

wecode 및 wecode 관련 제반 서비스(모바일 웹/앱 포함)의 회원관리, 서비스 개발・제공 및 향상, 안전한 인터넷 이용 환경 구축 등 아래의 목적으로만 개인정보를 이용합니다.

- 회원 가입 의사의 확인, 연령 확인 및 법정대리인 동의 진행, 이용자 및 법정대리인의 본인 확인, 이용자 식별, 회원 탈퇴 의사의 확인 등 회원관리를 위하여 개인정보를 이용합니다.
- 콘텐츠 등 기존 서비스 제공(광고 포함)에 더하여, 인구통계학적 분석, 서비스 방문 및 이용 기록의 분석, 관심사 등에 기반한 맞춤형 서비스 제공 등 신규 서비스 요소의 발굴 및 기존 서비스 개선 등을 위하여 개인정보를 이용합니다.
- 법령 및 wecode 이용약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재, 계정 도용 및 부정 거래 방지, 약관 개정 등의 고지사항 전달, 분쟁 조정을 위한 기록 보존, 민원처리 등 이용자 보호 및 서비스 운영을 위하여 개인정보를 이용합니다.
- 유료 서비스 제공에 따르는 본인인증, 구매 및 요금 결제, 상품 및 서비스의 배송을 위하여 개인정보를 이용합니다.
- 이벤트 정보 및 참여기회 제공, 광고성 정보 제공 등 마케팅 및 프로모션 목적으로 개인정보를 이용합니다.
- 서비스 이용기록과 접속 빈도 분석, 서비스 이용에 대한 통계, 서비스 분석 및 통계에 따른 맞춤 서비스 제공 및 광고 게재 등에 개인정보를 이용합니다.
- 보안, 프라이버시, 안전 측면에서 이용자가 안심하고 이용할 수 있는 서비스 이용환경 구축을 위해 개인정보를 이용합니다.

<br>

## 3. 개인정보의 보관 기간
<br>

회사는 원칙적으로 이용자의 개인정보를 회원 탈퇴 시 지체 없이 파기하고 있습니다.<br></br>

단, 이용자에게 개인정보 보관 기간에 대해 별도의 동의를 얻은 경우, 또는 법령에서 일정 기간 정보 보관 의무를 부과하는 경우에는 해당 기간 동안 개인정보를 안전하게 보관합니다.<br></br>

이용자에게 개인정보 보관 기간에 대해 회원가입 시 또는 서비스 가입 시 동의를 얻은 경우는 아래와 같습니다.

- 부정 가입 및 이용 방지

부정 이용자의 가입 인증 휴대전화 번호 또는 DI (만 14세 미만의 경우 법정대리인 DI) : 탈퇴 일로부터 6개월 보관<br></br>

탈퇴한 이용자의 휴대전화 번호(복호화가 불가능한 일방향 암호화(해시 처리)) : 탈퇴 일로부터 6개월 보관<br></br>

전자상거래 등에서의 소비자 보호에 관한 법률, 전자 문서 및 전자거래 기본법, 통신비밀 보호법 등 법령에서 일정 기간 정보의 보관을 규정하는 경우는 아래와 같습니다. wecode는 이 기간 동안 법령의 규정에 따라 개인정보를 보관하며, 본 정보를 다른 목적으로는 절대 이용하지 않습니다.

- 전자상거래 등에서 소비자 보호에 관한 법률

계약 또는 청약철회 등에 관한 기록: 5년 보관<br></br>

대금 결제 및 재화 등의 공급에 관한 기록: 5년 보관<br></br>

소비자의 불만 또는 분쟁처리에 관한 기록: 3년 보관

- 전자 문서 및 전자거래 기본법 공인 전자주소를 통한 전자 문서 유통에 관한 기록 : 10년 보관
- 전자서명 인증 업무

인증서와 인증 업무에 관한 기록: 인증서 효력 상실일로부터 10년 보관

- 통신비밀 보호법 로그인 기록: 3개월

참고로 wecode는 '개인정보 유효기간제’에 따라 1년간 서비스를 이용하지 않은 회원의 개인정보를 별도로 분리 보관하여 관리하고 있습니다.

<br><br>

## 4. 개인정보 수집 및 이용 동의를 거부할 권리
<br>

이용자는 개인정보의 수집 및 이용 동의를 거부할 권리가 있습니다. 회원가입 시 수집하는 최소한의 개인정보, 즉, 필수 항목에 대한 수집 및 이용 동의를 거부하실 경우, 회원가입이 어려울 수 있습니다.<br></br>

`;
