import { driver } from 'driver.js';
import "driver.js/dist/driver.css";

type Props = {
  onDestoryed? : () => void;
}

/*************************************************
 * useChapterTour
 *
 * @description
 * 수업 페이지에서 사용할 수업 가이드 투어를 실행합니다.
 *
 *************************************************/
export const useChapterTour = () => {
  const startChapterTour = ({ onDestoryed }: Props) => {
    const driverObj = driver({
      showProgress : true,
      onDestroyed  : () => onDestoryed && onDestoryed(),
      steps        : [
        { 
          element: '.video-pane', 
          popover: { 
            title       : '강의 비디오',
            description : '여기에서 강의 비디오를 시청할 수 있습니다.',
            side        : "left",
            align       : 'start'
          }
        },
        { 
          element: '.code-pane', 
          popover: { 
            title       : '코드 에디터',
            description : '수업에서 나오는 코드가 실시간으로 나와요.<br/><b>수업 도중에 코드를 수정하고</b><br/><b>실행해 볼 수도 있어요!</b>',
            side        : "left",
            align       : 'start'
          }
        },
        { 
          element: '.code-edit-button', 
          popover: { 
            title       : '코드 수정 버튼',
            description : '수업 도중에 코드를 수정해 볼 수 있어요.<br/><b>다시 원래 코드로 돌아가니</b> 자유롭게 마음껏<br/>수정해 보세요!<br/><b>코드 수정시 수업 영상은 일시정지 됩니다.</b>',
            side        : "left",
            align       : 'start'
          }
        },
        { 
          element: '.code-copy-button', 
          popover: { 
            title       : '코드 복사 버튼',
            description : '현재 코드를 복사할 수 있어요!',
            side        : "left",
            align       : 'start'
          }
        },
        { 
          element: '.code-run-button', 
          popover: { 
            title       : '코드 실행 버튼',
            description : '수업 도중에 현재 코드를 자유롭게 실행해 볼 수 있어요.<br/><b>코드 실행시 수업 영상은 일시정지 됩니다.</b>',
            side        : "left",
            align       : 'start'
          }
        },
        { 
          element: '.text-pane', 
          popover: { 
            title       : '추가 설명',
            description : '수업에 관련된 추가 설명이 나와요.<br/>수업을 이해하는데 도움이 될 거에요.',
            side        : "left",
            align       : 'start'
          }
        },
        { 
          element: '.chapter-list-dropdown', 
          popover: { 
            title       : '수업 목록',
            description : '현재 수업 목록을 확인할 수 있어요.<br/>다른 수업으로 이동하고 싶다면 여기를<br/>클릭해 보세요.<br/>강의 진행사항도 알 수 있어요!',
            side        : "left",
            align       : 'start'
          }
        },
        { 
          popover: { 
            title       : '이제 수업을 시작해 볼까요?',
            description : '위코드와 함께 즐겁고 유익한 수업 시작할 준비 되셨나요? ☺️ 🚀',
          } 
        }
      ]
    });

    driverObj.drive();
  };

  return startChapterTour;
};
