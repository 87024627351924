import React    from 'react';

import { defaultContainerWidth }               from 'styles/customTailwindCSS';
import ClassRow                                from './ClassRow';
import ClassCard, { ClassCardProps           } from './ClassCard';
import ClassImageCard, { ClassImageCardProps } from './ClassImageCard';

type GridSize  = "md" | "lg";
type ClassData = {
  title        : string;
  description? : string;
  items        : ClassCardProps[] | ClassImageCardProps[];
};
type ClassGridProps = {
  data       : ClassData[];
  size       : GridSize;
  type?      : "card" | "image";
  className? : string;
};

const GridSizeMap = {
  md: "w-1/2 md:w-1/3 lg:w-1/3 px-1 mb-2 sm:px-3 sm:mb-6",
  lg: "w-1/2 md:w-1/3 lg:w-1/4 px-1 mb-2 sm:px-2 sm:mb-4",
};

const ClassGrid = ({data, size, type, className}: ClassGridProps) => {

  const Items = ({row}: {row: ClassData}) => {
    return (
      <>
        {row.items.map((item, index) => (
          <div key={index} className={`${GridSizeMap[size]}`}>
            { type === 'card' ? <ClassCard {...item as ClassCardProps} /> : <ClassImageCard {...item as ClassImageCardProps} /> }
          </div>
        ))}
      </>
    ); 
  };

  return (
    <div className={`${defaultContainerWidth} py-6 mx-auto ${className ?? ''}`}>
      {data.map((row, index) => (
        <ClassRow key={index} title={row.title} description={row.description} >
          <Items row={row} />
        </ClassRow>
      ))}
    </div>
  );
};

export default ClassGrid;
