import VideoPlayer, { VideoProps, TransactionType, Transaction } from './VideoPlayer';
import { 
  Text,
  Line,
  videoEventPublisher,
  VideoEventType,
  VideoEvent,
  Code,
  CodeHighlight,
  Subscriber,
  useVideoEvent,
  CursorPosition,
  CodeAnnotation,
} from './VideoEventPublisher';

export default VideoPlayer;
export { 
  useVideoEvent,
  videoEventPublisher,
  VideoEventType,
  TransactionType 
};

export type { 
  Text,
  Line,
  CodeHighlight,
  VideoProps,
  VideoEvent,
  Code,
  Subscriber,
  CursorPosition,
  CodeAnnotation,
  Transaction,
};
