import React           from 'react';
import { useNavigate } from 'react-router-dom';

import { ErrorPage            } from 'pages';
import { Order                } from 'pages/types';
import { AlertMessage, PATH   } from 'constant';
import { useModal, ModalTypes } from 'contexts/ModalContext';
import { useOrderHistoryQuery } from './hooks';
import NoContent                from '../NoContent';

const PurchaseHistory = () => {
  const modal    = useModal();
  const navigate = useNavigate();

  const { orderHistory, loading, error } = useOrderHistoryQuery({
    onError: () => {
      modal?.openModal(ModalTypes.ALERT, {
        type             : 'error',
        dialog           : AlertMessage.common.error.unknown,
        hasHelpEmailInfo : true,
      });
    },
  });

  if (error                   ) return <ErrorPage />;
  if (loading || !orderHistory) return null;

  const Header = () => {
    const textSize = 'text-sm sm:text-base';

    return (
      <div className="flex justify-start py-4 border-b border-dotted font-semibold bg-gray">
        <div className={`${textSize} pl-4 w-[15%] text-left`}>구매일</div>
        <div className={`${textSize} w-[45%] text-left`}>주문 내역</div>
        <div className={`${textSize} w-[20%] text-right`}>주문 금액</div>
        <div className={`${textSize} w-[10%] text-right`}>결제 수단</div>
      </div>
    );
  };

  const OrderHistoryList = () => {
    return (
      <div className="mb-4">
        {orderHistory.map((order, index) => (
          <div 
            key       = {index}
            className = "border-b border-dotted"
            onClick   = {() => navigate(PATH.MYPAGE.toPurhcaseDetail(order.id))}
          >
            <Order
              key   = {order.id}
              order = {order}
            />
          </div>
        ))}
      </div>
    );
  };

  const Order = ({order}: {order: Order}) => {
    const totalAmount    = Intl.NumberFormat('ko-KR').format(order.totalPrice);
    const purchasedDate  = new Date(order.createdAt).toLocaleDateString('ko-KR');
    const textSize       = 'text-sm sm:text-base';
    const HOVER_BG_COLOR = 'hover:bg-gray-50 rounded-md';
    const paymentMethod  = {
      CARD     : '신용카드',
      EASY_PAY : '간편결제',
      FREE     : '무료',
      WECOIN   : 'Wecoin',
    }[order.paymentMethod];

    return (
      <div className={`${HOVER_BG_COLOR} flex justify-between py-4 cursor-pointer`}>
        <div className={`${textSize} text-gray-400 pl-4 w-[15%] text-left`}>{purchasedDate}</div>
        <div className={`${textSize} w-[45%] text-left truncate`}>{order.name}</div>
        <div className={`${textSize} w-[20%] text-primary text-right`}>{totalAmount}원</div>
        <div className={`${textSize} w-[10%] text-right`}>{paymentMethod}</div>
        <div className={`${textSize} w-[10%] text-gray-300 text-center`}>{'>'}</div>
      </div>
    );
  };


  if (orderHistory.length === 0) return (
    <NoContent
      icon    = "emptyPurchased"
      title   = "아직 구매하신 내역이 없네요 😅"
      subText = "내 인생에 투자하러 가볼까요? 🚀"
      btnText = "강의 골라보기"
      onClick = {() => navigate(PATH.HOME.base)}
      width   = "96px"
    />
  );

  return (
    <div className="w-full mx-auto pl-2">
      <Header />
      <OrderHistoryList />
    </div>
  );
}

export default PurchaseHistory;
