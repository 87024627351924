export const kakaoQueryData = (redirectUrl: string) => {
  return {
    client_id     : process.env.REACT_APP_KAKAO_REST_KEY ?? '',
    redirect_uri  : process.env.REACT_APP_KAKAO_REDIRECT_URI ?? '',
    response_type : 'code',
    prompt        : 'login',
    state         : redirectUrl
  }
}

export const KAKAO_BASIC_OAUTH_URL = 'https://kauth.kakao.com/oauth/authorize?'
export const KAKAO_TOKEN_OAUTH_URL = 'https://kauth.kakao.com/oauth/token?'
