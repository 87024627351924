import React, { useState   } from 'react';
import { useSandpack       } from '@codesandbox/sandpack-react';
import { PencilSquareIcon  } from '@heroicons/react/20/solid';

import { editorEventPublisher } from '../../EditorEventPublisher';
import { EditorEventType      } from '../../types';
import { ArrowPathIcon        } from '@heroicons/react/24/outline';

type Props = {
  isMobile     : boolean;
  videoEnded   : boolean;
  showEditor   : boolean;
  showPreview  : boolean;
  setShowEditor: (showEditor: boolean) => void;
};

const CodeEditButton = ({
  isMobile,
  videoEnded,
  showEditor,
  showPreview,
  setShowEditor
}: Props) => {
  const { sandpack }                          = useSandpack();
  const [isToggleVisible, setIsToggleVisible] = useState(false);

  const disabled = () => { 
    return (
      showPreview || 
      sandpack.files[sandpack.activeFile].code.length === 0
    );
  };

  const toggleCodeEditor = () => {
    if (disabled()) return;

    if (!videoEnded && !showPreview) {
      const eventType = showEditor 
        ? EditorEventType.EDITING_DONE 
        : EditorEventType.EDITING_START;

      editorEventPublisher.publish([{ type: eventType }]);
    }

    setShowEditor(!showEditor); 
  };

  const Tooltip = () => {
    return (
      <div className="
        absolute bottom-full left-[5%] transform -translate-x-1 -translate-y-1
        w-max mb-1 p-1 
        text-sm text-primary whitespace-nowrap
        bg-lightgrey rounded-md shadow-lg
      ">
        <div className="relative">
          <span>{showEditor ? "코드 되돌리기" : "코드 수정해보기"}</span>
          <div className="
            absolute 
            left-[15%] transform -translate-x-1 translate-y-full 
            mt-[-2px] w-0 h-0 
            border-l-[6px] border-l-transparent 
            border-r-[6px] border-r-transparent 
            border-t-[6px] border-t-lightgrey
          "></div>
        </div>
      </div>
    );
  }

  const showTooltip = (isMobile || isToggleVisible) && !showPreview;

  return (
    <div className={`
      code-edit-button
      relative inline-block
      bg-white 
      border border-primary shadow-md rounded-full
      text-primary 
      ${disabled() ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
    `}>
      { 
        showEditor ? ( 
          <ArrowPathIcon
            className    = "h-7 w-7 p-1 md:h-9 md:w-9 md:p-2"
            onClick      = {() => !disabled() && toggleCodeEditor()        } 
            onMouseEnter = {() => !disabled() && setIsToggleVisible(true)  } 
            onMouseLeave = {() => !disabled() && setIsToggleVisible(false) }
          />
        ) : (
          <PencilSquareIcon 
            className    = "h-7 w-7 p-1 md:h-9 md:w-9 md:p-2"
            onClick      = {() => !disabled() && toggleCodeEditor()     } 
            onMouseEnter = {() => !disabled() && setIsToggleVisible(true)  } 
            onMouseLeave = {() => !disabled() && setIsToggleVisible(false) } 
          />
        )
      }
      {showTooltip && <Tooltip />}
    </div>
  );
};

export default CodeEditButton;
