import { gql, useQuery, QueryHookOptions  } from '@apollo/client';

const GET_CHAPTER = gql`
  query chapter($courseId: Float!, $sequence: Float!) {
    chapter(
      courseId : $courseId,
      sequence : $sequence
    ) {
      id
      courseId
      sequence
      title
      summary
      content
      ogImage
      metaData
      status
      nextSequence
    }
  }
`;

const GET_LATEST_CHAPTER = gql`
  query latestChapter($courseId: Float!) {
    latestChapter(courseId: $courseId) {
      id
      courseId
      sequence
      title
      summary
      content
      ogImage
      metaData
      status
      nextSequence
    }
  }
`;

type Props = {
  courseId : number | string;
  sequence?: number | string;
}

export const useGetChapterQuery = ({courseId, sequence}: Props) => {
  // If sequence or chapterId is provided, fetch the specific chapter 
  // based on the sequence or chapterId of the course with the given courseId
  // Otherwise, fetch the latest chapter
  const query     = sequence ? GET_CHAPTER : GET_LATEST_CHAPTER;
  const variables = { courseId: Number(courseId), sequence: Number(sequence) };
  const options   = {
    variables   : variables,
    context     : { endpoint: 'course' },
    fetchPolicy : 'network-only',
  }
  
  const { data, refetch, loading, error } = useQuery(query, options as QueryHookOptions);

  return { 
    chapter        : data?.chapter ?? data?.latestChapter,
    refetchChapter : refetch,
    loading, 
    error
  };
};
