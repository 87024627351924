import React           from 'react';
import { useNavigate } from 'react-router-dom';

import Icon          from 'components/core/Icon/Icon';
import  WecondSymbol from 'assets/wecode_symbol_b@2x.png';
import { PATH    }   from 'constant';
import { useUser }   from 'contexts/UserContext';

const Profile = () => {
  const userContext      = useUser();
  const navigate         = useNavigate();

  const UserGreetings = () => {
    const user = userContext?.user;
    return (
      <>
        {user && (
          <div className="flex items-center gap-2">
            <div className="text-gray-600">안녕하세요,</div>
            <div className="font-semibold">{user.name || user.nickname || 'User'}</div>
            <div className="text-gray-600">님!</div>
          </div>
        )}
      </>
    );
  }

  const MyPageIcon = () => {
    return (
      <div
        className="relative w-8 h-8 cursor-pointer rounded-full border border-gray-300 flex items-center justify-center"
        onClick={() => navigate(PATH.MYPAGE.userProfile)}
      >
        <Icon name="my" size={25} />
      </div>
    );
  }

  return (
    <>
      <UserGreetings />
      <div className="flex gap-2 ml-8">
        <div className="relative w-8 h-8 cursor-pointer" onClick={() => navigate(PATH.MYPAGE.myCourse)}>
          <img src= {WecondSymbol} className="rounded-full object-cover" alt="Wecode" />
        </div>
        <MyPageIcon />
      </div>
    </>
  );
};

export default Profile;
