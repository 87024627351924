import React            from 'react';
import { createMarkup } from 'constant';
import css              from './Introduction.module.scss';

export interface IntroductionProps {
  text    : string;
  subtext : string; 
}

const Introduction = ({ text, subtext }: IntroductionProps) => {
  return (
    <section className={css.container}>
      <p className={css.sectionTitle}>클래스 소개</p>
      <p className={css.text} dangerouslySetInnerHTML={createMarkup(text)} />
      <p
        className={css.subText}
        dangerouslySetInnerHTML={createMarkup(subtext)}
      />
    </section>
  );
};

export default Introduction;
