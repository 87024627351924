import React                                  from 'react'
import { navigateToKakaoAuth }                from 'utils/auth';
import { ReactComponent as KakaoLoginButton } from 'assets/common/login_kakao_offical.svg'
import KakaoLogoImg                           from 'assets/common/Logo_kakao_simple.png'

interface KakaoLoginProps {
  type?       : 'square' | 'circle'
  redirectUrl : string
}

const KakaoLogin = ({ type = 'square', redirectUrl }: KakaoLoginProps) => {
  const squareBtn   = "flex justify-center items-center w-full h-14 rounded-sm bg-kakao";
  const circleBtn   = "flex justify-center items-center w-full h-14 rounded-md bg-kakao";
  const buttonStyle = type === 'square' ? squareBtn : circleBtn;
  const KakaoLogo   = (<img
    className = {`${circleBtn}`} 
    src       = {KakaoLogoImg} 
    alt       = "Kakao Logo" 
  />);


  return (
    <button className={buttonStyle} onClick={() => navigateToKakaoAuth(redirectUrl)}>
      {type === 'square' && <KakaoLoginButton />}
      {type === 'circle' && KakaoLogo}
    </button>
  );
}

export default KakaoLogin;
