import React, { CSSProperties, useState } from 'react';
import { SandpackProvider      } from '@codesandbox/sandpack-react';

import CodeEditor                from './CodeEditor';
import EditorController          from './EditorController';
import { CUSTOM_SANDPACK_THEME } from './customThemes';
import { DESKTOP               } from 'constant';
import { 
  Transaction,
  TransactionType,
  Files,
} from './types';

interface Props {
  initialFiles : Files;
  style?       : CSSProperties;
  events?      : Transaction[];
};

const WecodeEditor = ({
  initialFiles,
  style  = {},
  events = [],
}: Props) => {
  const [codes        , setCodes       ] = useState<Files>(initialFiles);
  const [transactions , setTransactions] = useState<Transaction[]>([]);
  const [showPreview  , setShowPreview ] = useState(false);
  const [showEditor   , setShowEditor  ] = useState(false);
  const [videoEnded   , setVideoEnded  ] = useState(false);
  const [isMobile     , setIsMobile    ] = useState(window.innerWidth < DESKTOP);

  const SANDBOX_CONFIG = {
    'sandbox.config.json': {
      code   : '{ "template" : "parcel" }',
      hidden : true,
    },
  };

  /*******************************************************************
   * Handle the resize event to determine if the user is on mobile
   *******************************************************************/
  const handleResize = () => { 
    const resize = () => setIsMobile(window.innerWidth < DESKTOP); 

    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  };

  /**
   * Handle the events that are passed to the editor.
   * Update the code if there is a code update event.
   * Otherwise, pass the event to the transaction state
   * so that the editor can handle it.
   *
   * @param newEvents - The events that are passed to the editor
   */
  const handleEvents = (newEvents: Transaction[]) => {
    newEvents.forEach( (transaction) => {
      switch (transaction.type) {
        case TransactionType.RESET: {
          setCodes(initialFiles);
          setShowEditor(false);
          setShowPreview(false);
          setVideoEnded(false);
          break;
        }
        case TransactionType.EDITOR_RESET: {
          setCodes(initialFiles);
          setShowEditor(false);
          setShowPreview(false);
          break;
        }
        case TransactionType.VIDEO_PLAY: {
          setShowEditor(false);
          setShowPreview(false);
          setVideoEnded(false);
          break;
        }
        case TransactionType.VIDEO_ENDED: {
          setVideoEnded(true);
          break;
        }
        case TransactionType.CODE_UPDATE: {
          const files   = { ...transaction.payload.files, ...SANDBOX_CONFIG };
          const entry   = transaction.payload.entry;
          const newCode = { files: files, entry: entry };

          setCodes(newCode);
          break;
        }
        default: break;
      };
    });
  }

  const updateTransactions = (newEvents: Transaction[]) => {
    if(newEvents.length === 0) return;

    setTransactions(newEvents);
  }

  /***********************************************************************/
  /*****                      Use Effects                            *****/
  /***********************************************************************/
  React.useMemo  (() => handleEvents(events), [events]);
  React.useEffect(() => updateTransactions(events), [events]);
  React.useEffect(handleResize, []);

  /***********************************************************************/
  /*****                      Components                             *****/
  /***********************************************************************/
  return (
    <SandpackProvider
      style       = {style}
      customSetup = {{ entry: codes.entry }}
      options     = {{initMode: 'lazy', autorun: false, recompileMode: 'delayed' }}
      files       = {codes.files}
      theme       = {CUSTOM_SANDPACK_THEME}
    >
      <div className="flex flex-col !h-full">
        <CodeEditor 
          isMobile      = {isMobile}
          files         = {codes.files}
          transactions  = {transactions}
          showPreview   = {showPreview}
          showEditor    = {showEditor}
        />
        <EditorController
          isMobile       = {isMobile}
          showEditor     = {showEditor}
          setShowEditor  = {setShowEditor}
          showPreview    = {showPreview} 
          setShowPreview = {setShowPreview} 
          videoEnded     = {videoEnded}
        />
      </div>
    </SandpackProvider>
  );
};

export default WecodeEditor;
