import React from 'react';

import PreviousButton  from './PreviousButton';
import NextButton      from './NextButton';
import ChapterDropdown from './ChapterDropdown';
import { Chapter }     from 'pages/types';

type Props = {
  chapter     : Chapter;
  className?  : string;
}

const FooterBar = ({chapter, className} : Props) => {
  const Title = () => { 
    return (
      <p className="chapter-title hidden lg:block text-themeBlack text-md font-bold">{chapter.title}</p>
     );
  };

  return (
    <footer className={`
      footer
      relative flex justify-between items-center 
      border-t border-gray-400 shadow-lg shadow-inner
      w-full py-2 px-4 lg:py-4 lg:px-10
      ${className ?? '' }
    `}>
      <Title />
      <div className="hidden lg:flex items-center absolute left-1/2 transform -translate-x-1/2">
        <ChapterDropdown chapter={chapter} />
      </div>
      <div className="flex flex-grow lg:flex-grow-0 justify-between items-center gap-2 h-full">
        <PreviousButton chapter={chapter} />
        <div className= "lg:hidden">
          <ChapterDropdown chapter={chapter} />
        </div>
        <NextButton chapter={chapter} />
      </div>
    </footer>
  );
}

export default FooterBar;
