const { Kakao }: any = window;

export const shareToKakao = (
  title        : string,
  introduction : string,
  thumbnail    : string,
) => {
  Kakao?.Share?.sendDefault({
    objectType: 'feed',
    content: {
      title       : title,
      description : introduction,
      imageUrl    : thumbnail,
      link: {
        mobileWebUrl : window.location.href,
        webUrl       : window.location.href,
      },
    },
    buttons: [
      {
        title : '웹으로 보기',
        link  : {
          mobileWebUrl : window.location.href,
          webUrl       : window.location.href,
        },
      },
    ],
  });
};
