import React, { useState } from 'react';

import { cn } from 'utils/styles';
import css    from './ProgressBar.module.scss';

interface Props {
  className? : string;
  progress   : number;
  variant?   : 'thin' | 'thick';
};

const ProgressBar = ({
  className = '',
  progress  = 0,
  variant   = 'thin',
}: Props) => {
  const [progressState, setProgressState] = useState(0);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setProgressState(progress);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [progress]);

  return (
    <>
      <div className={cn(css.progressBar, css[variant], className)}>
        <div className={css.green} style={{ width: `${progressState}%` }} />
      </div>
      <span className={css.text} data-progress={progress}>
        {Math.floor(progress)}%
      </span>
    </>
  );
};

export default ProgressBar;
