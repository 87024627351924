import { gql, useQuery  } from '@apollo/client';
import { Payment        } from 'pages/types';

const GET_PAYMENT_HISTORY = gql`
  query {
    paymentHistory {
      orderId
      orderName
      amount
      paymentMethod
      status
      approvedAt
      receiptUrl
    }
  }
`;

type Props = {
  onCompleted?: (paymentHistory: Payment[]) => void;
  onError?    : (error: any               ) => void;
};

const usePaymentHistoryQuery = ({onCompleted, onError}: Props) => {
  const { data, loading, error } = useQuery<{ paymentHistory: Payment[]; }>(
    GET_PAYMENT_HISTORY, 
    {
      context     : { endpoint : 'payment' },
      fetchPolicy : 'network-only',
      onCompleted : ({ paymentHistory }) => { onCompleted && onCompleted(paymentHistory) },
      onError     : (error) => { onError && onError(error) },
    }
  );

  return { paymentHistory: data?.paymentHistory, loading, error };
};

export default usePaymentHistoryQuery;
