import React from 'react';

import LogoCoverBelt   from 'components/core/LogoCoverBelt';
import ClassGrid       from './ClassCardGrid';
import ClassPageHeader from './ClassPageHeader';
import { 
  codingBootcampClasses,
  classPromotions 
} from './testData';

export const MainPage = () => {
  return (
    <div>
      <ClassPageHeader />
      <ClassGrid data={codingBootcampClasses} size ="lg" type = "card"  className = "mt-5 sm:mt-10" />
      <ClassGrid data={classPromotions}       size ="md" type = "image" className = "mb-5" />
      <LogoCoverBelt text="" />
    </div>
  );
};

