import React                      from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Button          from 'components/core/Button';
import KakaoLogin      from 'components/core/KakaoLogin';
import { PATH        } from 'constant';
import { cn, cond }    from 'utils/styles';
import coffee          from 'assets/coffee.svg';
import css             from './LoginPage.module.scss';

interface Props {
  isWrapped?  : boolean;
  prevAction? : () => void;
}

export const LoginPage = ({ 
  isWrapped = false, 
  prevAction, 
}: Props) => {
  const navigate = useNavigate();
  const goBack   = () => { navigate(-2) };
  const { redirect  = PATH.HOME.base } = useParams();

  return (
    <div className={cn(css.container, cond(isWrapped, css.wrapped))}>
      <img className={css.img} src={coffee} alt="커피" />
      <div className={css.contentWrapper}>
        <h1 className={css.title}>
          카카오로 3초만에 로그인하기 ☺️
          <br />
        </h1>
        <p className={`pt-4 text-lg`}>
          카카오로 간편하게 로그인하고,
          <br />
          바로 이어서 학습을 시작해보세요! 🚀
        </p>
      </div>
      <div className={css.btnWrapper}>
        <KakaoLogin redirectUrl= {redirect}/>
        <Button
          variant = "lineGrey"
          size    = "full"
          width   = "280px"
          height  = "40px"
          onClick = {prevAction || goBack}
        >
          뒤로
        </Button>
      </div>
    </div>
  );
};
