import React from 'react';

type CheckboxProps = {
  id        : string;
  checked   : boolean;
  value     : string;
  className?: string | undefined;
  size     ?: number | undefined;
  onChange  : (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Checkbox = ({ id, checked, value, className, size, onChange }: CheckboxProps) => {
  return (
    <input 
        id        = {id}
        type      = "checkbox"
        checked   = {checked}
        onChange  = {onChange}
        value     = {value}
        className ={`
          form-checkbox
          ${size ? `w-${size} h-${size}` : 'w-4 h-4'}
          text-blue-600 
          bg-gray-100 border-gray-300 rounded-md
          ${className ? className : ''}
        `}
      />
  );
};

export default Checkbox;
