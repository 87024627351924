import React    from 'react';
import { Link } from 'react-router-dom';
import { PATH } from 'constant';

export type ClassImageCardProps = {
  courseId?    : number;
  imageSrc     : string;
  price        : string;
  description? : string;
};

const ClassImageCard = ({courseId, imageSrc, description, price}: ClassImageCardProps) => {
  return (
    <Link to={courseId ? PATH.COURSE.toDescription(courseId) : ""}>
      <div className="relative bg-white rounded-md shadow overflow-hidden">
        <img src={imageSrc} alt={description} className="w-full h-auto object-cover" />
      </div>
    </Link>
  );
};

export default ClassImageCard;
