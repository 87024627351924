import { gql, useQuery } from '@apollo/client';
import { Order         } from 'pages/types';

const GET_PAYMENT_HISTORY = gql`
  query {
    orderHistory {
      id
      name
      totalPrice
      status
      paymentMethod
      createdAt
    }
  }
`;

type Props = {
  onCompleted?: (orderHistory: Order[] ) => void;
  onError?    : (error: any            ) => void;
};

const usePaymentHistoryQuery = ({onCompleted, onError}: Props) => {
  const { data, loading, error } = useQuery<{ orderHistory: Order[]; }>(
    GET_PAYMENT_HISTORY, 
    {
      context     : { endpoint : 'payment' },
      fetchPolicy : 'network-only',
      onCompleted : ({ orderHistory }) => { onCompleted && onCompleted(orderHistory) },
      onError     : (error)            => { onError && onError(error) },
    }
  );

  return { orderHistory: data?.orderHistory, loading, error };
};

export default usePaymentHistoryQuery;
