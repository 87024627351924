import React from 'react';

import ShareButton                from 'components/core/Share';
import { BaseModal, ModalHeader } from 'components/modal/BaseModal'
import { Course                 } from 'pages/types';
import css                        from './ShareCourseModal.module.scss';

interface Props {
  course  : Course,
  onClose : () => void,
}

const ShareCourseModal = ({course, onClose}: Props) => {
  return (
    <BaseModal switchModal={onClose}>
      <div className={css.shareModal}>
        <ModalHeader title="강의 공유하기" switchModal={onClose} />
        <div className={css.shareButtonWrapper}>
          <ShareButton course={course} />
        </div>
      </div>
    </BaseModal>
  );
}
export default ShareCourseModal;
