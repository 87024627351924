import React, { useState   } from 'react';

import CircledXButton             from 'components/core/CircledXButton';
import Thumbnail                  from './Thumbnail';
import ChapterInfo                from './ChapterInfo';
import MyWecoinInfo               from './MyWecoinInfo';
import PurchaseButton             from './PurchaseButton';
import UserAgreementCheckBox      from './UserAgreementCheckBox';
import InsufficientBalanceMessage from './InsufficientBalanceMessage';
import { useModal               } from 'contexts/ModalContext';
import { BaseModal              } from 'components/modal/BaseModal';
import { ProductType            } from 'pages/types';
import { pushBeginCheckoutEvent } from 'utils/gtm';
import { 
  useCourseQuery,
  useUserStatsQuery,
} from './hooks';

type PurchaseModalProps = {
  courseId  : number;
  sequence  : number;
  onSuccess : () => void;
  onClose   : () => void;
};

const PurchaseModal = ({courseId, sequence, onSuccess, onClose}: PurchaseModalProps) => {
  const modal      = useModal();
  const { course } = useCourseQuery(Number(courseId));
  const { stats  } = useUserStatsQuery();
  const closeModal = () => modal?.closeModal();

  const [insufficientBalance, setInsufficientBalance ] = useState(stats?.totalWecoins < course?.price);
  const [userAgreementChecked,setUserAgreementChecked] = useState(false);

  React.useEffect(() => {
    if (!course) return;

    const productInfo = {
      id          : course.id,
      productType : ProductType.COURSE,
      name        : course.title,
      price       : course.price,
      quantity    : 1,
    };
    pushBeginCheckoutEvent(course.price, productInfo);
  }, [course]);

  React.useEffect(() => {
    setInsufficientBalance(stats?.totalWecoins < course?.price);
  }, [course, stats]);

  if (!stats || !course ) return null;

  return (
    <BaseModal switchModal={closeModal} closeOnBackgroundClick={false}>
      <div className="
        flex flex-col
        items-center
        w-auto md:w-[440px] h-fit
        mx-2 p-6 
        bg-white shadow-md rounded-md
        relative
      ">
        <CircledXButton onClose={onClose} />
        <div className="flex w-full items-start gap-4">
          <Thumbnail   course = {course} />
          <ChapterInfo course = {course} />
        </div>
        <MyWecoinInfo 
          stats               = {stats}
          course              = {course}
          insufficientBalance = {insufficientBalance}
        />
        { insufficientBalance && <InsufficientBalanceMessage /> }
        <UserAgreementCheckBox 
          onClose                 = {closeModal}
          userAgreementChecked    = {userAgreementChecked}
          setUserAgreementChecked = {setUserAgreementChecked}
        /> 
        <PurchaseButton 
          course               = {course}
          sequence             = {sequence}
          onSuccess            = {onSuccess}
          onClose              = {closeModal}
          insufficientBalance  = {insufficientBalance}
          userAgreementChecked = {userAgreementChecked}
        />
      </div>
    </BaseModal>
  );
};

export default PurchaseModal;
