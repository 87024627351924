import React, { useState     } from 'react';
import { useNavigate         } from 'react-router-dom';
import { PlusIcon, MinusIcon } from '@heroicons/react/24/solid';

import Icon             from 'components/core/Icon/Icon';
import Button           from 'components/core/Button'
import { PATH }         from 'constant';
import { CourseStatus } from 'pages/types';

type OpenSections = {[key: string]: boolean};

type Section = {
  title    : string,
  chapters : Chapter[],
};

type Chapter = {
  id         : number,
  title      : string,
  sequence   : number,
  price      : number,
  groupTitle : string,
};

type ChapterProgress = {
  chapterId : number,
  status    : CourseStatus,
};

type Props = {
  courseId        : number,
  chapters        : Chapter[]
  chapterProgress : ChapterProgress[]
  authorized      : boolean,
};

const CurriculumTable = ({courseId, chapters, chapterProgress, authorized}: Props) => {
  const [openSections, setOpenSections] = useState<OpenSections>({0: true}); // By default, open the first section
  const [isOpenAll, setIsOpenAll      ] = useState(false);
  const navigate                        = useNavigate();

  const activeStatus                  = [CourseStatus.COMPLETED, CourseStatus.IN_PROGRESS];
  const statusEmoji: Record<any, any> = {
    [CourseStatus.COMPLETED]   : '✅',
    [CourseStatus.IN_PROGRESS] : '👩🏻‍💻',
  };

  const progress = chapterProgress
    .reduce((acc: any, chapter: any) => {
      acc[chapter.chapterId] = chapter.status;
      return acc;
    }, {});

  const sections: Section[] = [...chapters]
    .sort((a, b) => a.sequence - b.sequence)
    .reduce((acc, chapter) => {
      const existingSection = acc.find((section) => section.title === chapter.groupTitle);

      if (existingSection) {
        existingSection.chapters.push(chapter);
      } else {
        acc.push({title: chapter.groupTitle, chapters: [chapter]});
      }

      return acc;
    }, [] as Section[]);

  /*******************************************************************
   * Toggle all sections to open or close all sections
   *******************************************************************/
  const toggleAllSections = () => {
    if (!isOpenAll) {
      const allSectionsOpen = Object.keys(sections)
        .reduce((acc: OpenSections, _, index: number) => {
          acc[index] = true;
          return acc;
        }, {});

      setOpenSections(allSectionsOpen);
      setIsOpenAll(true);
    } else {
      setOpenSections({});
      setIsOpenAll(false);
    }
  };

  /*******************************************************************
   * Toggle single section to open or close
   *******************************************************************/
  const toggleSection = (key: number) => {
    setOpenSections((prevOpenSections) => ({
      ...prevOpenSections,
      [key]: !prevOpenSections[key],
    }));
  };

  /***********************************************************************/
  /*****                      Components                             *****/
  /***********************************************************************/
  const PlayIcon   = () => <Icon name = 'circlePlay' size = {20} />;
  const ListHeader = () => {
      return (
       <div className="py-5">
        <h1 className="leading-6 text-3xl font-bold text-gray-900">커리큘럼</h1>
        <div className="mt-2 md:flex md:items-center md:justify-between">
          <p className="flex-1 text-md text-gray-600">미리보기를 통해 콘텐츠를 확인해보세요.</p>
          <Button
            onClick   = {toggleAllSections}
            variant   = 'lineBase'
            size      = 'medium'
            className = "mt-4 md:mt-0"
          >
          {isOpenAll ? '모두 접기' : '모두 펼치기'}
          </Button>
        </div>
      </div>
    );
  };

  const ListSection = (
    props: {
      sectionTitle : string,
      last         : boolean,
      index        : number,
      children     : React.ReactNode
    }
  ) => {
    const {sectionTitle, last, index, children} = props;

    return (
      <div 
        key       = {index}
        className = {`
          text-sm md:text-lg
          border-b border-gray-300 bg-gray-50 
          ${last ? 'rounded-md' : ''} 
          ${index === 0 ? 'border-t rounded-t-md' : ''}
        `}>
        <button 
          className = 'flex items-center gap-1 w-full text-left p-4 font-bold' 
          onClick   = {() => toggleSection(index)}>
          {openSections[index] ? ( <MinusIcon className="h-5 w-5" />) : ( <PlusIcon className="h-5 w-5" />)}
          {sectionTitle}
        </button>
        {openSections[index] && children}
      </div>
    );
  };

  const List = ({ section }: {section: Section}) => {
    return (
      <ul className='list-none p-0 m-0'>
        {section.chapters.map((chapter, index) => (
          <li 
            key       = {index}
            onClick   = {() => navigate(PATH.COURSE.toChapter(courseId, chapter.id))}
            className = {`
              flex items-center bg-white p-4 border-t border-gray-200 cursor-pointer
              ${index === section.chapters.length - 1 ? 'border-b' : ''}
              hover:bg-gray-50
            `}
          >
            <PlayIcon />
            <span className={`
              flex-grow pl-2
              text-xs md:text-lg
              ${(!authorized || activeStatus.includes(progress[chapter.id])) ? '' : 'text-gray-400'}
              ${progress[chapter.id] === CourseStatus.IN_PROGRESS ? 'font-bold' : ''}
            `}>{chapter.title}</span>
            { (!authorized && chapter.price === 0) && ( <span className="text-xs md:text-lg text-primary">Free</span>)}
            { authorized && (<span className="text-sm md:text-2xl font-bold">{statusEmoji[progress[chapter.id]]}</span>)  }
          </li>
        ))}
      </ul>
    );
  };

  return (
    <>
      <ListHeader />
      <div className ='border-x border-gray-300 rounded-md mb-10 lg:mb-0'>
        {sections.map((section: Section, index: any) => (
          <ListSection 
            key          = {index}
            index        = {index}
            sectionTitle = {section.title}
            last         = {sections.length === (index+1)}
          >
            <List section ={section} />
          </ListSection>
        ))}
      </div>
    </>
  );
};

export default CurriculumTable;
