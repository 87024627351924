import React, { useEffect         } from 'react';
import { RoutesProps, useLocation } from 'react-router-dom';

import { PATH  }          from 'constant';
import channelTalkService from './ChannelService';

const ChannelTalkProvider = ({ children }: RoutesProps) => {
  const { pathname } = useLocation();

  useEffect(() => {
    channelTalkService.boot()

    if (pathname.includes(PATH.COURSE.base)) {
      channelTalkService.hideChannelButton()
    }
  }, []);

  useEffect(() => {
    if (pathname.includes(PATH.COURSE.base)) {
      channelTalkService.hideChannelButton();

      return;
    }

    channelTalkService.showChannelButton();
  }, [pathname]);

  return <>{children}</>;
}

export default ChannelTalkProvider;

