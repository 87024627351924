import React from 'react';

import Icon                     from 'components/core/Icon/Icon';
import { useModal, ModalTypes } from 'contexts/ModalContext';
import { Course               } from 'pages/types';

type Props = {
  course       : Course;
  description? : string;
}

const ShareIconButton = ({ course, description }: Props) => {
  const modal          = useModal();
  const openShareModal = () => modal?.openModal(ModalTypes.SHARE_COURSE, {course: course});

  return (
    <div 
      className ="
        transition-transform transition-opacity duration-300 ease-in-out
        flex flex-col items-center cursor-pointer gap-1 relative
      "
      onClick = {openShareModal}
    >
      <div className="hover:text-blue-500 hover:scale-125">
        <Icon name="share" />
      </div>
      { description ? <p className="text-xs font-semibold">{description}</p> : null }
    </div>
  );
};

export default ShareIconButton;
