import React from 'react';

type ClassRowProps = {
  title        : string;
  description? : string;
  children     : React.ReactNode;
};

const ClassRow = ({ title, description, children }: ClassRowProps) => {
  const Title = () => {
    return (
      <h1 className="text-xl md:text-3xl lg:text-4xl font-bold py-4b mb-2 sm:mb-4">{title}</h1>
    );
  };

  const Description = () => {
    return (
      <p className="break-words text-justify text-gray-500 text-base md:text-lg mb-4">{description}</p>
    );
  };

  const Rows = () => {
    return (
      <div className="flex flex-wrap -mx-2">{children}</div>
    );
  };

  return (
    <div>
      <Title       /> 
      <Description /> 
      <Rows        /> 
    </div>
  );
};

export default ClassRow;

