import React, { FC, useEffect, useState, useContext, useCallback } from 'react';

import LoginModal            from 'components/modal/LoginModal';
import UnfinishedCourseModal from 'components/modal/UnfinishedCourseModal';
import ShareCourseModal      from 'components/modal/ShareCourseModal';
import HamburgerMenuModal    from 'components/modal/HamburgerMenuModal';
import ImageTypeAlertModal   from 'components/modal/ImageTypeAlertModal';
import RefundModal           from 'components/modal/RefundModal';
import PurchaseModal         from 'components/modal/PurchaseModal';
import { Alert }             from 'components/modal/Alert';

export enum ModalTypes {
  LOGIN,
  PAYMENT,
  ALERT,
  PROMPT,
  UNFINISHED_COURSE,
  SHARE_COURSE,
  HAMBURGER_MENU,
  IMAGE_TYPE_ALERT,
  REFUND,
  NONE,
}

interface ModalProviderProps {
  children: React.ReactNode;
}

export interface ModalContextType {
  modalType  : ModalTypes;
  modalProps : Record<string, any>;
  openModal  : (type: ModalTypes, props? : Record<string, any>) => Promise<void>;
  closeModal : () => void;
}

type ModalProps = Record<string, any>;

const ModalContext    = React.createContext<ModalContextType | undefined>(undefined);
export const useModal = () => useContext(ModalContext);

export const ModalProvider: FC<ModalProviderProps> = ({ children }) => {
  const [modalType,  setModalType          ] = useState<ModalTypes>(ModalTypes.NONE);
  const [modalProps, setModalProps         ] = useState<ModalProps>({});
  const [resolveOnClose, setResolveOnClose ] = useState<(() => void) | null>(null);

  const openModal = useCallback((type: ModalTypes, props: Record<string, any> = {}) => {
    return new Promise<void>((resolve) => {
      setResolveOnClose(() => resolve);
      setModalType(type);
      setModalProps(props);
    });
  }, []);

  const closeModal = useCallback(() => {
    if (resolveOnClose) {
      resolveOnClose();
      setResolveOnClose(null);
    }

    setModalType(ModalTypes.NONE);
    setModalProps({});
  }, [resolveOnClose]);

  const registerEsacapeToCloseModal = () => {
    if ([
      ModalTypes.NONE,
      ModalTypes.PAYMENT,
      ModalTypes.LOGIN
    ].includes(modalType)) { 
      return; 
    }

    const handleKeyUp = (e: KeyboardEvent) => { e.code === 'Escape' && closeModal(); };
    document.addEventListener('keyup', handleKeyUp);

    // Cleanup event listener
    return () => { document.removeEventListener('keyup', handleKeyUp); }
  };

  useEffect(registerEsacapeToCloseModal, [modalType]);

  return (
    <ModalContext.Provider value={{ modalType, modalProps, openModal, closeModal }}>
      {children}
      {modalType === ModalTypes.LOGIN   && (<LoginModal onClose={modalProps.onClose  || closeModal} />)}
      {modalType === ModalTypes.ALERT   && (<Alert {...modalProps}/>)}
      {modalType === ModalTypes.REFUND  && (<RefundModal onClose={modalProps.onClose || closeModal} />)}
      {modalType === ModalTypes.PAYMENT && (<PurchaseModal 
        courseId  = {modalProps.courseId}
        sequence  = {modalProps.sequence}
        onSuccess = {modalProps.onSuccess}
        onClose   = {modalProps.onClose || closeModal}
        {...modalProps} 
      />)}
      {modalType === ModalTypes.UNFINISHED_COURSE && (
        <UnfinishedCourseModal 
          courseId = {modalProps.courseId}
          onClose  = {modalProps.onClose || closeModal}
        />
      )}
      {modalType === ModalTypes.SHARE_COURSE && (
        <ShareCourseModal 
          course  = {modalProps.course}
          onClose = {modalProps.onClose || closeModal}
        />
      )}
      {modalType === ModalTypes.HAMBURGER_MENU && (
        <HamburgerMenuModal items={modalProps.items} onClose={modalProps.onClose || closeModal} />
      )}
      {modalType === ModalTypes.IMAGE_TYPE_ALERT && (
        <ImageTypeAlertModal onClose={modalProps.onClose || closeModal}/>
      )}
    </ModalContext.Provider>
  );
};

