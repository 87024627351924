import { gql, useQuery } from '@apollo/client'
import { WishList      } from 'pages/types'

const GET_WISHLIST = gql`
  query getWishList {
    wishList {
      userId
      wishList {
        courseId
        title
        introduction
        thumbnail
        price
      }
    }
  }
`;

type Props = {
  onCompleted?: (wishList: WishList[]) => void;
  onError?    : (error   : any       ) => void;
};

const useWishListQuery = ({onCompleted, onError}: Props) => {
  const { data, loading, error, refetch } = useQuery<{
    wishList: { 
      userId   : number,
      wishList : WishList[]
    }
  }>(
    GET_WISHLIST, 
    {
      context     : {endpoint: 'course' },
      fetchPolicy : 'network-only',
      onCompleted : (data ) => onCompleted && onCompleted(data?.wishList.wishList),
      onError     : (error) => onError && onError(error),
    }
  );

  return { wishList: data?.wishList.wishList, loading, error, fetchWishList: refetch };
};

export default useWishListQuery;
