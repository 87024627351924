import React from 'react';

import Button                   from 'components/core/Button';
import Icon                     from 'components/core/Icon/Icon';
import { AlertMessage, PATH   } from 'constant';
import { useUser              } from 'contexts/UserContext'
import { useModal, ModalTypes } from 'contexts/ModalContext';
import css                      from './Logout.module.scss';

const Logout = () => {
  const user     = useUser();
  const modal    = useModal();
  const logOut   = () => {
    modal?.openModal(ModalTypes.ALERT, {
      show   : true,
      dialog : AlertMessage.auth.positive.logout,
    }).then(() => { 
      user?.handleLogOut(PATH.HOME.base);
    });
  }

  return (
    <div className={css.container}>
      <Icon name="smile" />
      <p className={css.title}>지금 로그아웃 하시겠습니까?</p>
      <div className={css.description}>내일 다시 만나요!</div>
      <Button size="large" onClick={logOut}>
        로그아웃
      </Button>
    </div>
  );
};

export default Logout;
