import React, {
  createElement,
  CSSProperties,
  memo,
  MouseEvent,
} from 'react';

import { LargeIcon  } from 'components/core/Icon/Large';
import { MediumIcon } from 'components/core/Icon/Medium';
import { SmallIcon  } from 'components/core/Icon/Small';
import { XLargeIcon } from 'components/core/Icon/XLarge';
import { cn         } from 'utils/styles';
import css            from './Icon.module.scss';

export type IconName =
  | 'arrowCircleLine'
  | 'arrowDownGreyDefault'
  | 'arrowDownGreyDisabled'
  | 'arrowFillDown'
  | 'arrowFillUp'
  | 'arrowLeftGreyDefault'
  | 'arrowLeftGreyDisabled'
  | 'arrowLinkBlack'
  | 'arrowLinkWhite'
  | 'arrowRightGreyDefault'
  | 'arrowRightGreyDisabled'
  | 'arrowUpGreyDefault'
  | 'arrowUpGreyDisabled'
  | 'bullet'
  | 'captionCircle'
  | 'checkCircleLine'
  | 'coinSm'
  | 'circlePlay'
  | 'completed'
  | 'footnotePrefixIconClip'
  | 'footnotePrefixIconError'
  | 'footnotePrefixIconFile'
  | 'footnotePrefixIconLink'
  | 'footnotePrefixIconReload'
  | 'footnotePrefixIconUpload'
  | 'footnotePrefixIconYoutube'
  | 'footnoteSuffixIconDelete'
  | 'footnoteSuffixIconOutlink'
  | 'icMinus'
  | 'icPlus'
  | 'icContents'
  | 'icDifficult'
  | 'icTime'
  | 'icCard'
  | 'icVideo'
  | 'LNBProcessCheck'
  | 'LNBProcessDelay'
  | 'LNBProcessDone'
  | 'LNBProcessDoneDisabled'
  | 'LNBProcessDot'
  | 'LNBProcessNot'
  | 'LNBProcessReady'
  | 'LNBProcessDoing'
  | 'missionPrefixIconDefault'
  | 'missionPrefixIconDone'
  | 'questionHelp'
  | 'search'
  | 'sessionLinkLink'
  | 'sessionLinkNext'
  | 'sessionLinkPrev'
  | 'arrowCollapseClose'
  | 'arrowCollapseOpen'
  | 'arrowCollapseCloseM'
  | 'arrowCollapseOpenM'
  | 'bellAlarmDefault'
  | 'bellAlarmNoti'
  | 'coin'
  | 'expendMuFalse'
  | 'expendMuTrue'
  | 'fireCoding'
  | 'footnotePrefixClip'
  | 'footnotePrefixFile'
  | 'footnotePrefixLink'
  | 'footnotePrefixUpload'
  | 'footnotePrefixYoutube'
  | 'levelBg'
  | 'hamburger'
  | 'list'
  | 'trash'
  | 'loginEmail'
  | 'logoKakaoSimple'
  | 'receiptAlarm'
  | 'receiptAlarmColored'
  | 'reset'
  | 'resultPlay'
  | 'reviewAngry'
  | 'reviewAngryColored'
  | 'reviewHmm'
  | 'reviewHmmColored'
  | 'reviewSmile'
  | 'reviewSmileColored'
  | 'reviewStarDisabled'
  | 'reviewStarFalse'
  | 'reviewStarTrue'
  | 'curriculumDone'
  | 'curriculumReview'
  | 'deny'
  | 'fileAdd'
  | 'homeworkUpload'
  | 'moviePlay'
  | 'movieStop'
  | 'moviePause'
  | 'blankWindow'
  | 'smile'
  | 'underConst'
  | 'bookmarkTrue'
  | 'bookmarkFalse'
  | 'close'
  | 'share'
  | 'shopCart'
  | 'shopCartNoti'
  | 'heart'
  | 'heartFilled'
  | 'heartDefault'
  | 'heartActive'
  | 'heartActive40'
  | 'history'
  | 'mdLock'
  | 'profile'
  | 'bookmark'
  | 'logout'
  | 'icCartCheck'
  | 'pageUp'
  | 'cartCheck'
  | 'checkInput'
  | 'done'
  | 'xlLock'
  | 'mail'
  | 'mailFail'
  | 'retry'
  | 'talk'
  | 'under'
  | 'url'
  | 'warning'
  | 'emptyCart'
  | 'emptyWish'
  | 'emptyPurchased'
  | 'emptyBookmark'
  | 'emptyMyCourse'
  | 'cartActive'
  | 'receipt'
  | 'my'
  | 'backGrey'
  | 'delPhoneGrey'
  | 'refresh'
  | 'playCircleBlue'
  | 'playCircleRed'
  | 'playCircleGreen'
  | 'notDone'
  | 'kakaoPay'
  | 'tossPay'
  | 'naverPay'
  | 'emptyHeartBold'
  | 'emptyShopCartBold'
  | 'check'
  | 'heartGrey'
  | 'cartGrey'
  | 'nextGrey'
  | 'bookmarkEmpty'
  | 'captionError'
  | 'cartEmpty'
  | 'checkPermission'
  | 'coffee'
  | 'likeEmpty'
  | 'listEmpty'
  | 'login'
  | 'myEmpty'
  | 'myNot'
  | 'slice1'
  | 'watingAnswer'
  | 'waitingStart'
  | 'heartWhite'
  | 'cartWhite'
  | 'shareWhite'
  | 'wonPrice'
  | 'wonPriceWhite'
  | 'homeIcon'
  | 'helpEmail'
  | 'justcodeLogo'
  | 'correctAnswer'
  | 'wrongAnswer';

export interface IconProps {
  name       : IconName;
  size?      : number;
  className? : string;
  disabled?  : boolean;
  onClick?   : (e: MouseEvent<HTMLButtonElement>) => void;
};

const Icon = (props: IconProps) => {
  const { onClick, size, disabled, className = '' } = props;

  return onClick ? (
    <button
      className = {cn(css.iconButton, className)}
      onClick   = {onClick}
      disabled  = {disabled}
    >
      <IconSvg {...props} style={{ width: size, height: size }} />
    </button>
  ) : (
    <IconSvg
      {...props}
      className = {className}
      style     = {{ width: size, height: size }}
    />
  );
};

const fetchFromAllSvg = (name: IconName, style: CSSProperties) => {
  // camelCase를 UpperCamelCase로 변환
  if (typeof name === 'undefined') return;

  const svgName = (name.charAt(0).toUpperCase() + name.slice(1)) as Capitalize<IconName>;

  // 모든 svg를 모아둔 객체
  const allSvg = {
    ...LargeIcon,
    ...MediumIcon,
    ...SmallIcon,
    ...XLargeIcon,
  };

  return createElement(allSvg[svgName], { style });
}

const IconSvg = ({
  name,
  style,
  className = '',
}: {
  name       : IconName
  style      : CSSProperties
  className? : string
}) => {
  const svg = fetchFromAllSvg(name, style)

  if (typeof svg !== 'undefined') {
    return svg;
  } else {
    return <div className={cn(css.iconPlaceholder, className)} />;
  }
}

export default memo(Icon)
