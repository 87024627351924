import React             from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { githubGist }    from 'react-syntax-highlighter/dist/cjs/styles/hljs';

interface CodeHighlighterProps {
  inline?    : boolean;
  className? : string;
  children   : string | React.ReactNode;
  props      : Record<string, any>;
}

const CodeHighlighter = ({inline, className, children, ...props}: CodeHighlighterProps) => {
  const match = /language-(\w+)/.exec(className || '');

  return !inline && match ? (
    <SyntaxHighlighter
      style       = {githubGist}
      language    = {match[1]}
      customStyle = {{
        fontSize        : '15px',
        padding         : '20px',
        lineHeight      : '1.3em',
        backgroundColor : '#faf9f8',
      }}
      PreTag        = "span"
      wrapLongLines = {true}
      {...props}
    >
      {String(children).replace(/\n$/, '')}
    </SyntaxHighlighter>
  ) : (
    <code
      className={className}
      style={{
        padding         : '2px 4px',
        borderRadius    : '5px',
        color           : '#eb5757',
        backgroundColor : '#ededeb',
        fontWeight      : 600,
        fontSize        : '14px',
        fontFamily      : 'noto-sans-cjk-kr, sans-serif',
      }}
      {...props}
    >
      {children}
    </code>
  );
};

export default CodeHighlighter;
