import { CSSProperties } from 'react';

export const CUSTOM_SANDPACK_THEME = {
  colors: {
    surface1     : '#FFFFFF',
    surface2     : '#112331',
    surface3     : '#E2E8F0',
    clickable    : '#6988a1',
    base         : '#808080',
    disabled     : '#4D4D4D',
    hover        : '#c5e4fd',
    accent       : '#112331',
    error        : '#ffcdca',
    errorSurface : '#811e18',
  },
  syntax: {
    plain       : 'black',
    comment     : '#999999',
    keyword     : '#6796e1',
    tag         : '#410bb5',
    punctuation : '#7fdbca',
    definition  : '#410bb5',
    property    : 'black',
    static      : '#c792ea',
    string      : '#e79e3e',
  },
  font: {
    size       : '15px',
    lineHeight : '1.5',
  },
};

export const CUSTOM_PRISM_THEME = {
    "code[class*=\"language-\"]": {
        color         : CUSTOM_SANDPACK_THEME.syntax.plain,
        fontFamily    : "Consolas, Monaco, 'Andale Mono', monospace",
        direction     : "ltr",
        textAlign     : "left",
        whiteSpace    : "pre",
        wordSpacing   : "normal",
        wordBreak     : "normal",
        lineHeight    : CUSTOM_SANDPACK_THEME.font.lineHeight,
        MozTabSize    : "4",
        OTabSize      : "4",
        tabSize       : "4",
        WebkitHyphens : "none",
        MozHyphens    : "none",
        msHyphens     : "none",
        hyphens       : "none"
    },
    "pre[class*=\"language-\"]": {
        color         : CUSTOM_SANDPACK_THEME.syntax.plain,
        fontFamily    : "Consolas, Monaco, 'Andale Mono', monospace",
        direction     : "ltr",
        textAlign     : "left",
        whiteSpace    : "pre",
        wordSpacing   : "normal",
        wordBreak     : "normal",
        lineHeight    : CUSTOM_SANDPACK_THEME.font.lineHeight,
        MozTabSize    : "4",
        OTabSize      : "4",
        tabSize       : "4",
        WebkitHyphens : "none",
        MozHyphens    : "none",
        msHyphens     : "none",
        hyphens       : "none",
        padding       : "1em",
        margin        : ".5em 0",
        overflow      : "auto",
        background    : CUSTOM_SANDPACK_THEME.colors.surface1
    },
    "pre[class*=\"language-\"]::-moz-selection": {
        "color": "inherit",
        "background": "rgba(33, 66, 131, .85)"
    },
    "pre[class*=\"language-\"] ::-moz-selection": {
        "color": "inherit",
        "background": "rgba(33, 66, 131, .85)"
    },
    "code[class*=\"language-\"]::-moz-selection": {
        "color": "inherit",
        "background": "rgba(33, 66, 131, .85)"
    },
    "code[class*=\"language-\"] ::-moz-selection": {
        "color": "inherit",
        "background": "rgba(33, 66, 131, .85)"
    },
    "pre[class*=\"language-\"]::selection": {
        "color": "inherit",
        "background": "rgba(33, 66, 131, .85)"
    },
    "pre[class*=\"language-\"] ::selection": {
        "color": "inherit",
        "background": "rgba(33, 66, 131, .85)"
    },
    "code[class*=\"language-\"]::selection": {
        "color": "inherit",
        "background": "rgba(33, 66, 131, .85)"
    },
    "code[class*=\"language-\"] ::selection": {
        "color": "inherit",
        "background": "rgba(33, 66, 131, .85)"
    },
    ":not(pre) > code[class*=\"language-\"]": {
        "background": "#2b2b2b",
        "padding": ".1em",
        "borderRadius": ".3em"
    },
    "comment": {
        "color": CUSTOM_SANDPACK_THEME.syntax.comment, //"#999988"
    },
    "prolog": {
        "color": "#808080"
    },
    "cdata": {
        "color": "#808080"
    },
    "delimiter": {
        "color": "#cc7832"
    },
    "boolean": {
        "color": CUSTOM_SANDPACK_THEME.syntax.static,
    },
    "keyword": {
        "color": CUSTOM_SANDPACK_THEME.syntax.keyword,
    },
    "selector": {
        "color": "#cc7832"
    },
    "important": {
        "color": "#cc7832"
    },
    "atrule": {
        "color": "#cc7832"
    },
    "operator": {
        "color": CUSTOM_SANDPACK_THEME.syntax.punctuation,
    },
    "punctuation": {
        "color": CUSTOM_SANDPACK_THEME.syntax.punctuation,
    },
    "attr-name": {
        "color": CUSTOM_SANDPACK_THEME.syntax.punctuation,
    },
    "tag": {
        "color": CUSTOM_SANDPACK_THEME.syntax.tag,
    },
    "tag.punctuation": {
        "color": CUSTOM_SANDPACK_THEME.syntax.punctuation,
    },
    "doctype": {
        "color": "#e8bf6a"
    },
    "builtin": {
        "color": "#e8bf6a"
    },
    "entity": {
        "color": "#6897bb"
    },
    "number": {
        "color": "#6897bb"
    },
    "symbol": {
        "color": "#6897bb"
    },
    "property": {
        "color": CUSTOM_SANDPACK_THEME.syntax.property,
    },
    "constant": {
        "color": CUSTOM_SANDPACK_THEME.syntax.plain,
    },
    "variable": {
        "color": "#9876aa"
    },
    "string": {
        "color": CUSTOM_SANDPACK_THEME.syntax.string,
    },
    "char": {
        "color": "#6a8759"
    },
    "attr-value": {
        "color": "#a5c261"
    },
    "attr-value.punctuation": {
        "color": "#a5c261"
    },
    "attr-value.punctuation:first-child": {
        "color": "#a9b7c6"
    },
    "url": {
        "color": "#287bde",
        "textDecoration": "underline"
    },
    "function": {
        "color": CUSTOM_SANDPACK_THEME.syntax.definition,
    },
    "regex": {
        "background": "#364135"
    },
    "bold": {
        "fontWeight": "bold"
    },
    "italic": {
        "fontStyle": "italic"
    },
    "inserted": {
        "background": "#294436"
    },
    "deleted": {
        "background": "#484a4a"
    },
    "code.language-css .token.property": {
        "color": "#a9b7c6"
    },
    "code.language-css .token.property + .token.punctuation": {
        "color": "#a9b7c6"
    },
    "code.language-css .token.id": {
        "color": "#ffc66d"
    },
    "code.language-css .token.selector > .token.class": {
        "color": "#ffc66d"
    },
    "code.language-css .token.selector > .token.attribute": {
        "color": "#ffc66d"
    },
    "code.language-css .token.selector > .token.pseudo-class": {
        "color": "#ffc66d"
    },
    "code.language-css .token.selector > .token.pseudo-element": {
        "color": "#ffc66d"
    }
} as { [key:string]: CSSProperties };
