import { useQuery, gql, QueryHookOptions } from '@apollo/client';

const COURSE_QUERY = gql`
  query getCourseInfo($id: Float!) {
    course(id: $id) {
      id
      title
      price
      thumbnail
    }
  }
`
export const useCourseQuery = (id: number) => {
  const options = {
    variables   : {id},
    context     : {endpoint: 'course'},
    fetchPolicy : 'network-only'
  };

  const {data, loading, error} = useQuery(COURSE_QUERY, options as QueryHookOptions);

  return {course: data?.course, loading, error};
};

