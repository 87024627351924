import React                      from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { ErrorPage            } from 'pages';
import { useModal, ModalTypes } from 'contexts/ModalContext';
import { AlertMessage, PATH   } from 'constant';
import { useOrderDetailQuery  } from './hooks';

const PurchaseDetail = () => {
  const modal            = useModal();
  const navigate         = useNavigate();
  const { orderId = '' } = useParams();

  const { order, loading, error } = useOrderDetailQuery({
    orderId,
    onError: () => {
      modal?.openModal(ModalTypes.ALERT, {
        type             : 'error',
        dialog           : AlertMessage.common.error.unknown,
        hasHelpEmailInfo : true,
      });
    },
  });

  if (error            ) return <ErrorPage />;
  if (loading || !order) return null;


  /***************************************************************************/
  /*                        Components                                       */
  /***************************************************************************/
  const PurchaseDetailInfo = () => {
    const purchasedDate  = new Date(order.createdAt).toLocaleString('ko-KR').slice(0,-3);
    const paymentMethod  = {
      CARD     : '카드',
      EASY_PAY : '간편결제',
      FREE     : '무료',
      WECOIN   : 'Wecoin',
    }[String(order.paymentMethod)];

    const orderDetail   = [
      { title: '주문 번호' , value: order.id }, 
      { title: '구매일'    , value: purchasedDate },
      { title: '주문명'    , value: order.name }, 
      { title: '총 상품 수' ,value: order.items.length },
      { title: '총 금액'   , value: Intl.NumberFormat('ko-KR').format(order.totalPrice) + "원" }, 
      { title: '결제 수단' , value: paymentMethod}, 
    ]

    const textSize = 'text-lg';
    return (
      <div className={`flex flex-col itmes-center justify-between border-t`}>
        {
          orderDetail.map((detail, index) => (
            <div className="flex items-center justify-between" key={index} >
              <div className={`
                bg-gray-100 
                border-r border-b
                pr-4 py-2 
                w-[30%] 
                ${textSize} font-semibold text-gray-500 text-right
              `}>{detail.title}</div>
              <div className={`pl-4 py-2 ${textSize} w-[70%] text-left border-b truncate`}>{detail.value}</div>
            </div>
        ))}
      </div>
    );
  };

  const Header = () => {
    const textSize = 'text-sm sm:text-base';

    return (
      <div className="flex justify-start py-4 border-b border-dotted font-semibold bg-gray">
        <div className={`${textSize} pl-4 w-[20%] text-left`}></div>
        <div className={`${textSize} w-[50%] text-left`}>상품명</div>
        <div className={`${textSize} w-[20%] text-right`}>주문 금액</div>
      </div>
    );
  };

  const ProductList = () => {
    const products = order.items.map((item: any) => {
        return {
          title    : item.name,
          price    : item.price,
          createdAt: item.createdAt,
          courseId : item.productInfo.courseId,
          sequence : item.productInfo.sequence,
        }
    });

    return (
      <div className="mb-4">
        {products.map((product: any, index: number) => (
          <div 
            key       = {index}
            className = "border-b border-dotted"
            onClick = {() => navigate(PATH.COURSE.toChapter(product.courseId, product.sequence))}
          >
            <ProductInfo
              index   = {index}
              key     = {product.id}
              product = {product}
            />
          </div>
        ))}
      </div>
    );
  };

  const ProductInfo = ({index, product}: {index:number, product: any}) => {
    const price          = Intl.NumberFormat('ko-KR').format(product.price);
    const textSize       = 'text-base md:text-base';
    const HOVER_BG_COLOR = 'hover:bg-gray-50 rounded-md';

    return (
      <div className={`${HOVER_BG_COLOR} flex justify-start py-4 cursor-pointer`}>
        <div className={`${textSize} text-gray-400 w-[20%] text-center`}>{index + 1}</div>
        <div className={`${textSize} w-[50%] text-left truncate`}>{product.title}</div>
        <div className={`${textSize} w-[20%] text-primary text-right`}>{price}원</div>
        <div className={`${textSize} w-[10%] text-gray-300 text-center`}>{'>'}</div>
      </div>
    );
  };

  return (
    <>
      <h1 className="font-bold text-xl mb-4">주문 정보</h1>
      <PurchaseDetailInfo />
      <h1 className="font-bold text-xl mt-8 mb-4">상품 정보</h1>
      <Header />
      <ProductList />
    </>
  )
}

export default PurchaseDetail;
