import React from 'react';
import css   from './CheckBulletBox.module.scss';

export interface CheckBulletBoxProps {
  children : React.ReactNode;
  title    : string;
}

const CheckBulletBox = ({ children, title }: CheckBulletBoxProps) => {
  return (
    <div className={css.container}>
      <p className={css.title}>{title}</p>
      <ul className={css.contents}>{children}</ul>
    </div>
  );
};

export default CheckBulletBox;
