import { useEffect   } from 'react'
import { useNavigate } from 'react-router-dom'

import { useKakaoToken       } from './hooks'
import { useUser             } from 'contexts/UserContext'
import { pushUserSignUpEvent } from 'utils/gtm';

const KakaoRedirect = () => {
  const user                           = useUser()
  const navigate                       = useNavigate()
  const { data, redirectUrl, loading } = useKakaoToken()

  useEffect(() => {
    if (!loading && data) {
      const {accessToken: authToken, isNewUser, userId } = data.createKakaoLogin

      if (isNewUser) {
        // Sends the new user sign up event to GTM
        pushUserSignUpEvent(userId)
      }

      const previousLocation = redirectUrl || '/'

      user?.handleLogin(authToken)
      navigate(`${previousLocation}`, {replace: true})
    }
  }, [data, loading])

  return null
}

export default KakaoRedirect
