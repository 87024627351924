import React           from 'react'
import { useNavigate } from 'react-router-dom'

import { ErrorPage            } from 'pages';
import { PATH                 } from 'constant';
import { useMyCourseInfoQuery } from './hooks';
import NoContent                from '../NoContent'
import MyPageCard               from '../MyPageCard'

const MyCourseInfo = () => {
  const navigate                      = useNavigate()
  const { myCourses, loading, error } = useMyCourseInfoQuery({})

  if (error                 ) return <ErrorPage />;
  if (loading || !myCourses ) return null;

  const noContent = <NoContent
    icon    = "cartEmpty"
    title   = "아직 학습한 수업이 없어요 😮"
    subText = "귀중한 나의 시간, 지금 바로 나의 성장을 위해 투자하세요!"
    btnText = "나를 위한 수업 찾으러 가기"
    onClick = {() => navigate(PATH.HOME.base)}
    width   = "96px"
  />;

  const myClases = myCourses.map(course => (
    <div key={course.id} className="w-full lg:w-[31%]">
      <MyPageCard data={{...course, progress: course.userAccessInfo?.progress}} variant="myCourse" />
    </div>
  ));

  return (
    <div>
      <section className="flex flex-wrap items-start justify-start h-full gap-4">
        {myCourses.length === 0 ? noContent : myClases}
      </section>
    </div>
  )
}

export default MyCourseInfo
