/**
 * 쿼리 스트링을 만들어주는 함수
 * @param queryParam 객체형태
 * @returns queryParam 객체에 대해서 쿼리스트링을 리턴
 */
export const convertQueryString = (queryParam: Record<string, string>): string => {
  const searchParams = new URLSearchParams()

  for (const [key, value] of Object.entries(queryParam)) {
    searchParams.set(key, value)
  }
  return searchParams.toString()
}
