interface ObjectType {
  [key: string]: string | number | undefined;
}

/**
 * 얕은 복사한 객체가 같은지 확인하는 함수
 *
 * @param originalObject 비교하는 기준객체
 * @param comparingObject 비교하는 대상객체
 * @return 비교하는 기준과 대상객체가 동일하면 true, 아니면 false 반환
 */

export const compareObjects = (
  originalObject  : ObjectType,
  comparingObject : ObjectType,
): boolean => {
  const originalObjectKeys = Object.keys(originalObject);
  const result             = originalObjectKeys.every(key => { return originalObject[key] === comparingObject[key] });

  return result;
};
