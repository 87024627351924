import { 
  from, 
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  makeVar,
} from '@apollo/client';
import { onError    } from '@apollo/client/link/error';
import { AUTH_TOKEN } from 'constant';

const loadingVar  = makeVar(false);
const loadingLink = new ApolloLink((operation, forward) => {
  loadingVar(true);

  return forward(operation).map(data => {
    loadingVar(false);
    return data;
  });
});

const loadingErrorLink = onError(() => {
  loadingVar(false);
});

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      Authorization: (localStorage.getItem(AUTH_TOKEN)) ?? '',
      ...operation.getContext().headers,
    },
  });
  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (Array.isArray(graphQLErrors))
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.warn(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
    });
  if (networkError) {
    console.warn(`[Network error]: ${networkError}`);
  }
});

const httpLink = new ApolloLink((operation, forward) => {
  const { endpoint = 'auth' } = operation.getContext();

  operation.setContext({
    uri: `${process.env.REACT_APP_API_ENDPOINT}/${endpoint}`,
  });

  return forward(operation);
}).concat(new HttpLink());

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        loading: {
          read() {
            return loadingVar();
          },
        },
      },
    },
  },
});

const apolloClient = new ApolloClient({
  link: from([loadingLink, loadingErrorLink, authLink, errorLink, httpLink]),
  cache,
});

export default apolloClient;
