import { useMutation, gql } from '@apollo/client'

const CREATE_WISH_LIST = gql`
  mutation createWishList($courseIds: [Float!]!) {
    createWishList(courseIds: $courseIds) {
      userId
    }
  }
`
type Props = {
  courseId     : number,
  onError?     : (error: any) => void
  onCompleted? : () => void
}

const useCreateHeartMutation = ({courseId, onCompleted, onError}: Props) => {
  const [createHeart] = useMutation(
    CREATE_WISH_LIST, 
    {
      context     : {endpoint: 'course'},
      variables   : { courseIds: [courseId] },
      onError     : (error) => { onError && onError(error) },
      onCompleted : () => { onCompleted && onCompleted() },
    }
  )

  return createHeart
}

export default useCreateHeartMutation
