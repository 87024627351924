import React from "react";

type Props = {
  course: {
    title: string;
  };
};

const ChapterInfo = ({ course }: Props) => {
  return (
    <div className="flex flex-col w-[70%] ">
      <h1 className="text-lg font-semibold text-black text-wrap">{course?.title}</h1>
    </div>
  );
};

export default ChapterInfo;
