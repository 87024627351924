import { gql, useQuery } from '@apollo/client'
import { Course        } from 'pages/types';

const GET_MY_COURSE_INFO = gql`
  query myCourses {
    myCourses {
      id
      title
      introduction
      description
      thumbnail
      userAccessInfo {
        progress
      }
    }
  }
`;

type Props = {
  onCompleted?: (courseInfo: Course[]) => void;
  onError?    : (error: any          ) => void;
};

const useMyCourseInfoQuery = ({ onCompleted, onError }: Props) => {
  const { data, loading, error } = useQuery<{myCourses: Course[]}>(
    GET_MY_COURSE_INFO, 
    {
      context    : {endpoint: 'course' },
      fetchPolicy: 'network-only',
      onCompleted: (data)  => onCompleted && onCompleted(data?.myCourses),
      onError    : (error) => onError && onError(error),
    }
  );

  return { 
    myCourses: data?.myCourses,
    loading,
    error,
  };
};

export default useMyCourseInfoQuery;
