import React from 'react';
import css   from './Columns.module.scss';

export interface ColumnProps {
  number   : number;
  children : React.ReactNode;
}

const Columns = ({ number, children }: ColumnProps) => {
  return (
    <div
      className={css.columnsWrapper}
      style={{ gridTemplateColumns: `repeat(${number}, 1fr)` }}
    >
      {children}
    </div>
  );
};

export default Columns;
