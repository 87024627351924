import React              from 'react'
import { ApolloProvider } from '@apollo/client'
import { hydrate        } from 'react-dom'
import { createRoot     } from 'react-dom/client'
import TagManager         from 'react-gtm-module'

import OpenGraph    from 'components/core/Helmet/OpenGraph'
import Router       from 'router'
import apolloClient from 'apolloClient'

import 'styles/tailwind.scss';
import 'styles/common.scss';
import 'styles/variables/animation.scss';

TagManager.initialize({ gtmId: 'GTM-5KPTPH6L' });

const rootElement    = document.getElementById('root')
const app            = (
  <ApolloProvider client={apolloClient}>
    <OpenGraph />
    <Router />
  </ApolloProvider>
)

if (!rootElement) throw new Error('rootElement is null or undefined')
if (rootElement?.hasChildNodes()) {
  hydrate(app, rootElement)
} else {
  createRoot(rootElement).render(app)
}
