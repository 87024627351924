/**
 * 아이콘을 import 할때에는 반드시 PascalCamelCase로 작성한다 (2022.10.22 YunKukPark)
 */
import { ReactComponent as BlankWindow } from 'assets/icon/svg/xl/blank_window.svg';
import { ReactComponent as BookmarkEmpty } from 'assets/icon/svg/xl/bookmark_empty.svg';
import { ReactComponent as CaptionError } from 'assets/icon/svg/xl/caption_error.svg';
import { ReactComponent as CartCheck } from 'assets/icon/svg/xl/cart_check.svg';
import { ReactComponent as CartEmpty } from 'assets/icon/svg/xl/cart_empty.svg';
import { ReactComponent as CheckInput } from 'assets/icon/svg/xl/check_input.svg';
import { ReactComponent as CheckPermission } from 'assets/icon/svg/xl/check_permission.svg';
import { ReactComponent as Coffee } from 'assets/icon/svg/xl/coffee.svg';
import { ReactComponent as Completed } from 'assets/icon/svg/xl/completed.svg';
import { ReactComponent as Done } from 'assets/icon/svg/xl/done.svg';
import { ReactComponent as EmptyBookmark } from 'assets/icon/svg/xl/empty_bookmark.svg';
import { ReactComponent as EmptyCart } from 'assets/icon/svg/xl/empty_cart.svg';
import { ReactComponent as EmptyHeartBold } from 'assets/icon/svg/xl/empty_heart_bold.svg';
import { ReactComponent as EmptyMyCourse } from 'assets/icon/svg/xl/empty_myCourse.svg';
import { ReactComponent as EmptyPurchased } from 'assets/icon/svg/xl/empty_purchased.svg';
import { ReactComponent as EmptyShopCartBold } from 'assets/icon/svg/xl/empty_shop_cart_bold.svg';
import { ReactComponent as EmptyWish } from 'assets/icon/svg/xl/empty_wish.svg';
import { ReactComponent as IcCartCheck } from 'assets/icon/svg/xl/ic_cart_check.svg';
import { ReactComponent as LikeEmpty } from 'assets/icon/svg/xl/like_empty.svg';
import { ReactComponent as ListEmpty } from 'assets/icon/svg/xl/list_empty.svg';
import { ReactComponent as XlLock } from 'assets/icon/svg/xl/lock.svg';
import { ReactComponent as Login } from 'assets/icon/svg/xl/login.svg';
import { ReactComponent as Mail } from 'assets/icon/svg/xl/mail.svg';
import { ReactComponent as MailFail } from 'assets/icon/svg/xl/mail_fail.svg';
import { ReactComponent as MyEmpty } from 'assets/icon/svg/xl/my_empty.svg';
import { ReactComponent as MyNot } from 'assets/icon/svg/xl/my_not.svg';
import { ReactComponent as NotDone } from 'assets/icon/svg/xl/not_done.svg';
import { ReactComponent as PageUp } from 'assets/icon/svg/xl/page_up.svg';
import { ReactComponent as Retry } from 'assets/icon/svg/xl/retry.svg';
import { ReactComponent as Slice1 } from 'assets/icon/svg/xl/slice1.svg';
import { ReactComponent as Smile } from 'assets/icon/svg/xl/smile.svg';
import { ReactComponent as Talk } from 'assets/icon/svg/xl/talk.svg';
import { ReactComponent as Under } from 'assets/icon/svg/xl/under.svg';
import { ReactComponent as UnderConst } from 'assets/icon/svg/xl/under_const.svg';
import { ReactComponent as Url } from 'assets/icon/svg/xl/url.svg';
import { ReactComponent as Warning } from 'assets/icon/svg/xl/warning.svg';
import { ReactComponent as WatingAnswer } from 'assets/icon/svg/xl/wating_answer.svg';
import { ReactComponent as WaitingStart } from 'assets/icon/svg/xl/wating_start.svg';
import { ReactComponent as CorrectAnswer } from 'assets/icon/svg/xl/q_right.svg';
import { ReactComponent as WrongAnswer } from 'assets/icon/svg/xl/q_deny.svg';

export const XLargeIcon = {
  BlankWindow,
  Completed,
  Smile,
  UnderConst,
  IcCartCheck,
  EmptyCart,
  EmptyWish,
  EmptyBookmark,
  EmptyPurchased,
  EmptyMyCourse,
  EmptyHeartBold,
  EmptyShopCartBold,
  PageUp,
  CartCheck,
  CheckInput,
  Done,
  XlLock,
  Mail,
  MailFail,
  Retry,
  Talk,
  Under,
  Url,
  Warning,
  NotDone,
  BookmarkEmpty,
  CaptionError,
  CartEmpty,
  CheckPermission,
  Coffee,
  LikeEmpty,
  ListEmpty,
  Login,
  MyEmpty,
  MyNot,
  Slice1,
  WatingAnswer,
  WaitingStart,
  CorrectAnswer,
  WrongAnswer,
};
