import { gql, useLazyQuery } from '@apollo/client'
import { WishList          } from 'pages/types'

const GET_WISHLIST = gql`
  query {
    wishList {
      wishList {
        courseId  
        title
        introduction
        thumbnail
        price
      }
    }
  }
`;

const useGetCartQuery = () => {
  const [ fetchWishList, { data, error, loading } ] = useLazyQuery<{wishList: { wishList: WishList[] }}>(
    GET_WISHLIST, 
    {
      context     : { endpoint: 'course' },
      fetchPolicy : 'network-only',
    }
  )

  return {fetchWishList, wishList: data?.wishList.wishList, error, loading}

}

export default useGetCartQuery;
