import { gql, useQuery } from '@apollo/client';

import { User } from 'pages/types';

type Props = {
  onCompleted? : (data: User) => void;
  onError?     : (error: any) => void;
}

export const USER_QUERY = gql`
  query {
    user {
      name
      nickname
      email
      phoneNumber
      profileImage,
      stats {
        totalWecoins,
        totalCoupons,
        totalPoints,
        totalCourses,
        totalCartItems,
        totalWishListItems
      }
    }
  }
`;

const useUserQuery = (props: Props) => {
  const onCompleted = props.onCompleted;
  const onError     = props.onError;

  const { data, loading, refetch } = useQuery<{user: User}>(
    USER_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (data      ) => { onCompleted && onCompleted(data.user ) },
      onError    : (error: any) => { onError     && onError(error)          },
    },
  );

  return { user: data?.user, loading, fetchUser: refetch }
};

export default useUserQuery;
