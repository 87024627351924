import React           from 'react';
import { useLocation } from 'react-router-dom';

import Icon          from 'components/core/Icon/Icon';
import KakaoLogin    from 'components/core/KakaoLogin';
import { BaseModal } from 'components/modal/BaseModal';

interface Props {
  onClose: () => void
}

const LoginModal = ({onClose}: Props) => {
  const location = useLocation();

  return (
    <BaseModal switchModal={() => {}}>
      <div className="w-full md:w-auto p-6 rounded-md bg-white">
        <div className="flex justify-end">
          <Icon name="close" onClick={onClose} />
        </div>
        <div className="flex justify-center items-center my-6 text-themeBlack text-lg font-bold leading-3">
          <p>카카오로 3초만에 로그인하기 ☺️</p>
        </div>
        <div className="flex flex-col w-full items-center justify-center">
          <div className="my-4 w-full">
            <KakaoLogin redirectUrl= {location.pathname}/>
          </div>
        </div>
      </div>
    </BaseModal>
  )
}
export default LoginModal
