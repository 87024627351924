export type Files              = Record<string, any>;
export type Highlight          = {highlights : {line: number}[]};
export type ActiveFile         = {activeFile : string};
export type CursorPosition     = {line: number, column: number};
export type Transaction        = {type       : TransactionType, payload? : any };
export type Decorators         = Record<string, Decorator[]>;
export type EditorEventPayload = Record<string, any>;
export type Subscriber         = (events: EditorEvent[]) => void;
export type Annotation         = {
  annotations: {
    dataId      : string,
    line        : number,
    startColumn : number,
    endColumn   : number
  }[]
};

export type EditorEvent = {
  type    : string;
  payload?: EditorEventPayload;
};

export interface Decorator {
  className?         : string;
  line               : number;
  startColumn?       : number;
  endColumn?         : number;
  elementAttributes? : Record<string, string>;
};

export enum TransactionType {
  HIGHLIGHT        = 'HIGHLIGHT',
  CLEAR_HIGHLIGHT  = 'CLEAR_HIGHLIGHT',
  ANNOTATION       = 'ANNOTATION',
  CLEAR_ANNOTATION = 'CLEAR_ANNOTATION',
  SCROLL_CODE      = 'SCROLL_CODE',
  SCROLL_TEXT      = 'SCROLL_TEXT',
  CODE_UPDATE      = 'CODE_UPDATE',
  EDIT_CODE        = 'EDIT_CODE',
  INSERT_CODE      = 'INSERT_CODE',
  SHOW_PREVIEW     = 'SHOW_PREVIEW',
  SET_ACTIVE_FILE  = 'SET_ACTIVE_FILE',
  VIDEO_PLAY       = 'VIDEO_PLAY',
  VIDEO_ENDED      = 'VIDEO_ENDED',
  RESET            = 'RESET',
  EDITOR_RESET     = 'EDITOR_RESET',
  NOOP             = 'NOOP',
};

export enum EditorEventType {
  EDITING_START = 'EDITING_START',
  EDITING_DONE  = 'EDITING_DONE',
  REFRESH       = 'REFRESH',
  SHOW_PREVIEW  = 'SHOW_PREVIEW',
};
