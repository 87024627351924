import React, { ButtonHTMLAttributes } from 'react'
import { Link }                        from 'react-router-dom'

import Icon   from 'components/core/Icon/Icon'
import { cn } from 'utils/styles'
import css    from './Button.module.scss'

export type BtnVariantType =
  | 'primary'
  | 'lineBase'
  | 'lineGrey'
  | 'ghost'
  | 'highlight'
  | 'white'

export type ButtonSize = 
  | 'small' 
  | 'medium' 
  | 'large' 
  | 'xlarge' 
  | 'x2large' 
  | 'x3large' 
  | 'x4large' 
  | 'x5large' 
  | 'x6large' 
  | 'x7large' 
  | 'full'

export interface CoreButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?      : ButtonSize
  variant?   : BtnVariantType
  type?      : 'button' | 'submit' | 'reset'
  disabled?  : boolean
  margin?    : string
  width?     : string
  height?    : string
  animation? : boolean
  isSelected?: boolean
  check?     : boolean
  className? : string
  to?        : string
  href?      : string
};

// Tailwind classes mapping
const sizeClasses = {
  small  : 'py-1 px-2 text-xs min-w-16 max-w-20',
  medium : 'py-2 px-4 text-sm min-w-20 max-w-25',
  large  : 'py-3 px-6 text-base w-40',
  xlarge : 'py-3 px-6 text-base w-48',
  x2large: 'py-3 px-6 text-base w-56',
  x3large: 'py-3 px-6 text-base w-60',
  x4large: 'py-3 px-6 text-base w-64',
  x5large: 'py-3 px-6 text-base w-72',
  x6large: 'py-3 px-6 text-base w-80',
  x7large: 'py-3 px-6 text-base w-96',
  full   : 'py-3 px-6 text-base w-full'
};

const hoverColorTransition = 'transition-colors duration-200'
const variantClasses       = {
  primary  : `bg-primary text-white hover:bg-blue-700 ${hoverColorTransition}`,
  lineBase : `bg-transparent text-lightprimary border border-blue-300 hover:text-blue-700 hover:border-lightprimary ${hoverColorTransition}`,
  lineGrey : `bg-transparent text-grey border border-gray-300 hover:border-lightprimary hover:text-lightprimary ${hoverColorTransition}`,
  ghost    : `bg-transparent text-gray-500 hover:text-gray-900 ${hoverColorTransition}`,
  highlight: `bg-highlight hover:bg-yellow-600 text-white ${hoverColorTransition}`,
  white    : `bg-white text-lightprimary border border-blue-300 hover:text-blue-700 hover:border-lightprimary ${hoverColorTransition}`,
};

const Button = (props: CoreButtonProps) => {
  const {
    children,
    size    = 'medium',
    variant = 'primary',
    type    = 'button',
    disabled,
    margin,
    width,
    height,
    animation  = false,
    isSelected = false,
    check      = true,
    className  = '',
    to,
    href,
    onClick,
    ...others
  } = props

  const buttonClass = `
    inline-flex items-center justify-center 
    rounded-md shadow 
    ${sizeClasses[size]} 
    ${variantClasses[variant]} 
    ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'} 
    ${className}
  `;

  if (to) {
    return (
      <Link
        data-testid = "button"
        to          = {to}
        className   = {cn(css.base, css[size], css[variant], className)}
        style       = {{ margin, width, height }}
      >
        {children}
      </Link>
    )
  }

  if (href) {
    return (
      <a
        data-testid = "button"
        href        = {href}
        className   = {cn(css.base, css[size], css[variant], className)}
        style       = {{ margin, width }}
        target      = "_blank"
        rel         = "noreferrer"
      >
        {children}
      </a>
    )
  }

  return (
    <button
      data-testid="button"
      className= {buttonClass}
      onClick  = {onClick}
      type     = {type}
      disabled = {disabled}
      style    = {{ margin, width, height }}
      {...others}
    >
      {isSelected && check && (
        <>
          <Icon name="LNBProcessCheck" />
          <div className={css.iconCover} />
        </>
      )}
      {children}
    </button>
  )
}

export default Button
