import { useMutation, gql } from '@apollo/client'

const DELETE_WISH_LIST = gql`
  mutation deleteWishList($courseIds: [Float!]!) {
    deleteWishList(courseIds: $courseIds) {
      userId
    }
  }
`

type Props = {
  courseId     : number;
  onError?     : (error: any) => void;
  onCompleted? : () => void;
}

const useDeleteHeartMutation = ({courseId, onError, onCompleted}: Props) => {
  const [deleteHeart] = useMutation(
    DELETE_WISH_LIST, 
    {
      context       : { endpoint: 'course' },
      variables     : { courseIds: [courseId] },
      onError       : (err) => { onError && onError(err) },
      onCompleted   : () => { onCompleted && onCompleted() },
    }
  )

  return deleteHeart
}

export default useDeleteHeartMutation
