import React           from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ArrowLeftCircleIcon,
} from '@heroicons/react/24/solid';

import Button      from 'components/core/Button';
import { PATH    } from 'constant';

type Props = {
  chapter: { sequence: number, courseId: number };
}

const PreviousButton = ({chapter}: Props) => {
  const navigate           = useNavigate();
  const isTheFirstChapter  = chapter.sequence === 1;
  const getPreviousChapter = () => {
    if (chapter.sequence > 1) {
      return navigate(PATH.COURSE.toChapter(chapter.courseId!, chapter.sequence - 1));
    } 
  }

  return (
    <div className="chapter-previous-button h-full flex items-center">
      <ArrowLeftCircleIcon 
        className ={`lg:hidden h-[40px] ${isTheFirstChapter ? 'text-lightgrey' : 'text-primary'}`}
        onClick   = {() => !isTheFirstChapter && getPreviousChapter()}
      />
      <Button
        disabled  = {isTheFirstChapter}
        size      = "medium"
        className = "hidden lg:block lg:h-[40px]"
        onClick   = {() => getPreviousChapter()}
      >
        이전으로
      </Button>
    </div>
  );
};

export default PreviousButton;
