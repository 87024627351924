import { ReactComponent as ArrowCircleLine           } from 'assets/icon/svg/sm/arrow_circle_line.svg';
import { ReactComponent as ArrowDownGreyDefault      } from 'assets/icon/svg/sm/arrow_down_grey_default.svg';
import { ReactComponent as ArrowDownGreyDisabled     } from 'assets/icon/svg/sm/arrow_down_grey_disabled.svg';
import { ReactComponent as ArrowFillDown             } from 'assets/icon/svg/sm/arrow_fill_down.svg';
import { ReactComponent as ArrowFillUp               } from 'assets/icon/svg/sm/arrow_fill_up.svg';
import { ReactComponent as ArrowLeftGreyDefault      } from 'assets/icon/svg/sm/arrow_left_grey_default.svg';
import { ReactComponent as ArrowLeftGreyDisabled     } from 'assets/icon/svg/sm/arrow_left_grey_disabled.svg';
import { ReactComponent as ArrowLinkBlack            } from 'assets/icon/svg/sm/arrow_link_black.svg';
import { ReactComponent as ArrowLinkWhite            } from 'assets/icon/svg/sm/arrow_link_white.svg';
import { ReactComponent as ArrowRightGreyDefault     } from 'assets/icon/svg/sm/arrow_right_grey_default.svg';
import { ReactComponent as ArrowRightGreyDisabled    } from 'assets/icon/svg/sm/arrow_right_grey_disabled.svg';
import { ReactComponent as ArrowUpGreyDefault        } from 'assets/icon/svg/sm/arrow_up_grey_default.svg';
import { ReactComponent as ArrowUpGreyDisabled       } from 'assets/icon/svg/sm/arrow_up_grey_disabled.svg';
import { ReactComponent as Bullet                    } from 'assets/icon/svg/sm/Bullet_icon.svg';
import { ReactComponent as CaptionCircle             } from 'assets/icon/svg/sm/caption_circle.svg';
import { ReactComponent as Check                     } from 'assets/icon/svg/sm/check.svg';
import { ReactComponent as CheckCircleLine           } from 'assets/icon/svg/sm/check_circle_line.svg';
import { ReactComponent as CoinSm                    } from 'assets/icon/svg/sm/coin_sm.svg';
import { ReactComponent as CirclePlay                } from 'assets/icon/svg/sm/circle_play.svg';
import { ReactComponent as FootnotePrefixIconClip    } from 'assets/icon/svg/sm/footnote_prefix_icon_clip.svg';
import { ReactComponent as FootnotePrefixIconError   } from 'assets/icon/svg/sm/footnote_prefix_icon_error.svg';
import { ReactComponent as FootnotePrefixIconFile    } from 'assets/icon/svg/sm/footnote_prefix_icon_file.svg';
import { ReactComponent as FootnotePrefixIconLink    } from 'assets/icon/svg/sm/footnote_prefix_icon_link.svg';
import { ReactComponent as FootnotePrefixIconReload  } from 'assets/icon/svg/sm/footnote_prefix_icon_reload.svg';
import { ReactComponent as FootnotePrefixIconUpload  } from 'assets/icon/svg/sm/footnote_prefix_icon_upload.svg';
import { ReactComponent as FootnotePrefixIconYoutube } from 'assets/icon/svg/sm/footnote_prefix_icon_youtube.svg';
import { ReactComponent as FootnoteSuffixIconDelete  } from 'assets/icon/svg/sm/footnote_suffix_icon_delete.svg';
import { ReactComponent as FootnoteSuffixIconOutlink } from 'assets/icon/svg/sm/footnote_suffix_icon_outlink.svg';
import { ReactComponent as HelpEmail                 } from 'assets/icon/svg/sm/help_email.svg';
import { ReactComponent as IcMinus                   } from 'assets/icon/svg/sm/ic_minus.svg';
import { ReactComponent as IcPlus                    } from 'assets/icon/svg/sm/ic_plus.svg';
import { ReactComponent as LNBProcessCheck           } from 'assets/icon/svg/sm/LNB_process_check.svg';
import { ReactComponent as LNBProcessDelay           } from 'assets/icon/svg/sm/LNB_process_delay.svg';
import { ReactComponent as LNBProcessDoing           } from 'assets/icon/svg/sm/LNB_process_doing.svg';
import { ReactComponent as LNBProcessDone            } from 'assets/icon/svg/sm/LNB_process_done.svg';
import { ReactComponent as LNBProcessDoneDisabled    } from 'assets/icon/svg/sm/LNB_process_done_disabled.svg';
import { ReactComponent as LNBProcessDot             } from 'assets/icon/svg/sm/LNB_process_dot.svg';
import { ReactComponent as LNBProcessNot             } from 'assets/icon/svg/sm/LNB_process_not.svg';
import { ReactComponent as LNBProcessReady           } from 'assets/icon/svg/sm/LNB_process_ready.svg';
import { ReactComponent as MissionPrefixIconDefault  } from 'assets/icon/svg/sm/mission_prefix_icon_default.svg';
import { ReactComponent as MissionPrefixIconDone     } from 'assets/icon/svg/sm/mission_prefix_icon_done.svg';
import { ReactComponent as QuestionHelp              } from 'assets/icon/svg/sm/question_help.svg';
import { ReactComponent as Search                    } from 'assets/icon/svg/sm/search.svg';
import { ReactComponent as SessionLinkLink           } from 'assets/icon/svg/sm/session_link_link.svg';
import { ReactComponent as SessionLinkNext           } from 'assets/icon/svg/sm/session_link_next.svg';
import { ReactComponent as SessionLinkPrev           } from 'assets/icon/svg/sm/session_link_prev.svg';
import { ReactComponent as ResultPlay                } from 'assets/icon/svg/sm/result_play.svg';

export const SmallIcon = {
  ArrowCircleLine,
  ArrowDownGreyDefault,
  ArrowDownGreyDisabled,
  ArrowFillDown,
  ArrowFillUp,
  ArrowLeftGreyDefault,
  ArrowLeftGreyDisabled,
  ArrowLinkBlack,
  ArrowLinkWhite,
  ArrowRightGreyDefault,
  ArrowRightGreyDisabled,
  ArrowUpGreyDefault,
  ArrowUpGreyDisabled,
  Bullet,
  CaptionCircle,
  CheckCircleLine,
  CoinSm,
  CirclePlay,  
  FootnotePrefixIconClip,
  FootnotePrefixIconError,
  FootnotePrefixIconFile,
  FootnotePrefixIconLink,
  FootnotePrefixIconReload,
  FootnotePrefixIconUpload,
  FootnotePrefixIconYoutube,
  FootnoteSuffixIconDelete,
  FootnoteSuffixIconOutlink,
  IcMinus,
  IcPlus,
  LNBProcessCheck,
  LNBProcessDelay,
  LNBProcessDone,
  LNBProcessDoneDisabled,
  LNBProcessDot,
  LNBProcessNot,
  LNBProcessReady,
  LNBProcessDoing,
  MissionPrefixIconDefault,
  MissionPrefixIconDone,
  QuestionHelp,
  Search,
  SessionLinkLink,
  SessionLinkNext,
  SessionLinkPrev,
  Check,
  HelpEmail,
  ResultPlay,
};
