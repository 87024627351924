import React   from 'react';

import { defaultContainerWidth } from 'styles/customTailwindCSS';
import Content                   from './Content';
import LNB                       from './LNB';

export const MyPage = () => {
  return (
    <div className={`
      flex justify-center items-start 
      ${defaultContainerWidth}
      md:mx-auto md:mt-10 md:mb-25 gap-10 px-0 py-0
    `}>
      <LNB     />
      <Content />
    </div>
  );
};
