import React, {useEffect                           } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { gql, useMutation                          } from '@apollo/client';
import axios                                         from 'axios';

import { PATH                  } from 'constant';
import { convertQueryString    } from 'utils/querys';
import { useModal, ModalTypes  } from 'contexts/ModalContext';
import { AlertMessage          } from 'constant/alertMessage';
import { KAKAO_TOKEN_OAUTH_URL } from 'utils/auth';

interface KakaoLoginInfo {
  createKakaoLogin: {
    userId      : number;
    accessToken : string;
    isNewUser?  : boolean;
  };
};

interface KakaoTokenData {
  data        : KakaoLoginInfo | null | undefined,
  redirectUrl : string | null,
  loading     : boolean,
};

type Props = {
  kakaoAuthToken: string;
  onCompleted?  : (kakaoLoginInfo: KakaoLoginInfo) => void;
  onError?      : (error: any                    ) => void;
};

const CREATE_KAKAO_TOKEN = gql`
  mutation createKakaoLogin($kakaoAuthToken: String!) {
    createKakaoLogin(
      kakaoAuthToken  : $kakaoAuthToken
    ) {
      userId
      accessToken
      isNewUser
    }
  }
`;

const useCreateKakaoLoginMutation = ({ kakaoAuthToken, onCompleted, onError }: Props) => {
 const [createKakaoToken, { data, loading }] = useMutation<KakaoLoginInfo>(
    CREATE_KAKAO_TOKEN,
    {
      variables   : { kakaoAuthToken: kakaoAuthToken              },
      onError     : (error) => { onError && onError(error)        },
      onCompleted : (data ) => { onCompleted && onCompleted(data) },
    },
  );

  return { createKakaoToken, data, loading };
};


const useKakaoToken: () => KakaoTokenData = () => {
  const modal                       = useModal();
  const [kakaoToken, setKakaoToken] = React.useState('');
  const navigate                    = useNavigate();
  const location                    = useLocation();
  const [searchParams]              = useSearchParams();

  const { createKakaoToken, data, loading } = useCreateKakaoLoginMutation({
    kakaoAuthToken : kakaoToken,
    onError        : (error) => {
      modal?.openModal(ModalTypes.ALERT, {
        type             : 'error',
        dialog           : AlertMessage.common.error.unknown,
        hasHelpEmailInfo : true,
      });

      navigate(PATH.SIGNUP.base);
    },
  });

  useEffect(() => {
    if (!kakaoToken) { return };

    createKakaoToken();
  }, [kakaoToken])

  useEffect(() => {
    const accessTokenUrl = location.search

    if (!accessTokenUrl.includes('code')) {
      modal?.openModal(ModalTypes.ALERT, {
        type             : 'error',
        dialog           : AlertMessage.common.error.unknown,
        hasHelpEmailInfo : true,
      });
      navigate(PATH.SIGNUP.base)
    };

    const kakaoAccessTokenData = {
      grant_type   : 'authorization_code',
      client_id    : process.env.REACT_APP_KAKAO_REST_KEY ?? '',
      redirect_uri : process.env.REACT_APP_KAKAO_REDIRECT_URI ?? '',
      code         : searchParams.get('code') ?? '',
    }

    const getKakaoToken = async () => {
      try {
        const url         = KAKAO_TOKEN_OAUTH_URL
        const queryString = convertQueryString(kakaoAccessTokenData)
        const { data }    = await axios.post(url, queryString)

        setKakaoToken(data?.access_token)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.error(error.message);
      
          modal?.openModal(ModalTypes.ALERT, {
            type             : 'error',
            dialog           : AlertMessage.common.error.unknown,
            hasHelpEmailInfo : true,
          });

          navigate(PATH.SIGNUP.base);
        }
      }
    }

    getKakaoToken();
  }, []);

  const redirectUrl = new URLSearchParams(location.search).get('state') ?? '';

  return { data, redirectUrl, loading };
}

export default useKakaoToken;
