import React from 'react';

type Props = {
  stats              : any;
  course             : any;
  insufficientBalance: boolean;
};

const MyWecoinInfo = ({stats, course, insufficientBalance}: Props) => {
  return (
    <div className="
      flex flex-col 
      w-full 
      mt-6 py-2 px-4 
      bg-gray-100 
      text-sm text-gray-500
      rounded-md
    ">
      <div className="flex justify-between pt-2 md:pt-4 pb-2">
        <p>보유 리워드포인트</p>
        <p>{stats?.totalPoints?.toLocaleString() ?? 0}원</p>
      </div>
      <div className={`flex justify-between pb-4`}>
        <p>보유 위코인</p>
        <p>{stats?.totalWecoins?.toLocaleString() ?? 0}원</p>
      </div>
      <div className={`flex justify-between border-t font-semibold py-2 md:py-4 pb-2 md:pb-4 ${!insufficientBalance && `text-primary`}`}>
        <p>강의 가격</p>
        <p>{course?.price?.toLocaleString() ?? ''}원</p>
      </div>
      { 
        insufficientBalance && (
          <div className={`
            flex justify-between 
            font-semibold text-red-400 font-bold
            -mt-2 pb-2 md:pb-4`
          }>
            <p>부족한 위코인</p>
            <p>{(stats?.totalWecoins - course?.price).toLocaleString() ?? ''}원</p>
          </div>
        )
      }
    </div>
  );
};

export default MyWecoinInfo;
