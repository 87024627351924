import React, {useRef, useState} from 'react';
import axios                     from 'axios';


import Smile                       from 'assets/icon/svg/xl/smile.svg';
import { AlertMessage            } from 'constant';
import { useModal, ModalTypes    } from 'contexts/ModalContext';
import { useProfileImageMutation } from './hooks';

type Props = {
  profileImage     : string;
  fetchUserProfile : () => void;
};

const ProfileImage = ({profileImage, fetchUserProfile}: Props) => {
  const modal                           = useModal();
  const fileRef                         = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [s3File, setS3File]             = useState<File | undefined>(undefined);
  const [imagePreview, setImagePreview] = useState<string>('');

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    fileRef.current.click();
  };

  const handleErrorImg = (e: React.SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = Smile;
  };

  const handleChangeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const file = e.target.files as FileList;

    if (!file[0].type.includes('image')) {
      modal?.openModal(ModalTypes.IMAGE_TYPE_ALERT);
      return;
    }

    const fileArray = Array.from(file);
    const selectedFiles: string[] = fileArray.map((file) => {
      return URL.createObjectURL(file);
    });

    setImagePreview(selectedFiles?.[0]);
    setS3File(file[0]);
    getProfileImageUrl();
  };

  const getProfileImageUrl = useProfileImageMutation({
    onCompleted: (imageUrl: string) => {
      s3File && uploadImageToS3(imageUrl, s3File);
    },
    onError: (error: any) => {
      modal?.openModal(ModalTypes.ALERT, {
        show             : true,
        type             : 'error',
        dialog           : AlertMessage.common.error.unknown,
        hasHelpEmailInfo : true,
      });
    },
  });

  const uploadImageToS3 = async (presignedUrl: string, file: File) => {
    try {
      const result = await axios.put(presignedUrl, file, {
        headers: { 'Content-Type': file.type },
      });

      if (result.status === 200) {
        modal
          ?.openModal(ModalTypes.ALERT, {
            type: 'positive',
            dialog: AlertMessage.auth.positive.edit,
          })
          .then(() => fetchUserProfile());
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        setImagePreview('');
        modal?.openModal(ModalTypes.ALERT, {
          type: 'error',
          dialog: AlertMessage.auth.error.retry,
        });
      }
    }
  };


  return (
    <div className="w-full lg:w-1/3 flex flex-col items-center p-4">
      <div className="mb-6 mt-6">
        <img
          className = "rounded-full w-40 h-40 object-cover"
          src       = {imagePreview || profileImage}
          alt       = "profile"
          onError   = {handleErrorImg}
        />
      </div>
      <button
        className = "mt-4 bg-white border border-gray-300 rounded-md px-4 py-2"
        onClick   = {handleClick}
        type      = "button"
      >
        사진 업로드
      </button>
      <input
        type      = "file"
        ref       = {fileRef}
        className = "hidden"
        onChange  = {handleChangeFiles}
      />
      <p className="text-sm text-gray-600 mt-4">
        JPG 혹은 PNG 이미지로
        <br />
        320*320 이상 사진을 사용해주세요.
      </p>
    </div>
  ); 
}

export default ProfileImage;
