import { gql, useLazyQuery } from '@apollo/client';

const GET_CHAPTER_INFO = gql`
  query chapterInfo($courseId: Float!) {
    course(id: $courseId) {
      chapterInfo {
        totalChapterCount
        chapters {
          id
          sequence
          title
          price
        }
      }
    }
  }
`;

type Props = {
  courseId : number | string;
}

export const useChapterInfoLazyQuery = ({courseId}: Props) => {
  const variables = { courseId: Number(courseId) };
  const options   = {
    variables : variables,
    context   : {
      endpoint    : 'course',
      fetchPolicy : 'network-only'
    }
  };

  const [getChapterInfo, { data, loading, error }] = useLazyQuery(GET_CHAPTER_INFO, options);
  
  return {
    getChapterInfo,
    chapterInfo: data?.course?.chapterInfo,
    loading, 
    error,
  };
};
