import React, { useState  } from 'react';
import { CircleStackIcon, } from '@heroicons/react/24/solid';
import { 
  CircleStackIcon as CircleStackOutlineIcon,
  CreditCardIcon
} from '@heroicons/react/24/outline';

import { useTossPayments       } from 'hooks';
import { defaultContainerWidth } from 'styles/customTailwindCSS';
import { WecoinAmount          } from 'pages/types';
import { useModal, ModalTypes  } from 'contexts/ModalContext';
import { useUser               } from 'contexts/UserContext';
import { PATH, AlertMessage    } from 'constant';
import Icon                      from 'components/core/Icon/Icon'
import Button                    from 'components/core/Button';
import Checkbox                  from 'components/core/Checkbox';
import RadioButton               from 'components/core/RadioButton';
import { 
  useUserStatsQuery,
  useCreateWecoinOrder
  } from './hooks';

const priceOptions = [
  [
    { amount: 2000   , percentage: 3 , reward: 60    },
    { amount: 5000   , percentage: 3 , reward: 150   },
    { amount: 10000  , percentage: 3 , reward: 300   },
    { amount: 20000  , percentage: 3 , reward: 600   },
  ],
  [
    { amount: 30000  , percentage: 4 , reward: 1200  },
    { amount: 50000  , percentage: 4 , reward: 2000  }, 
    { amount: 70000  , percentage: 4 , reward: 2800  }, 
  ],
  [
    { amount: 100000 , percentage: 5 , reward: 5000  },
    { amount: 200000 , percentage: 5 , reward: 10000 },
    { amount: 300000 , percentage: 5 , reward: 15000 },
    { amount: 400000 , percentage: 5 , reward: 20000 },
    { amount: 500000 , percentage: 5 , reward: 25000 },
  ]
];

const SELECTED_BG_COLOR   = 'bg-blue-50 rounded-md';
const HOVER_BG_COLOR      = 'hover:bg-lightgrey rounded-md';
const CARD_SUCESS_URL     = process.env.REACT_APP_TOSS_CARD_SUCCESS_URL!;
const EASY_PAY_SUCESS_URL = process.env.REACT_APP_TOSS_EASY_PAY_SUCCESS_URL!;
const FAIL_URL            = process.env.REACT_APP_TOSS_FAIL_URL!;

type PaymentMethod   = 'CARD' | 'KAKAOPAY' | 'NAVERPAY' | 'TOSSPAY';
type PriceOptionType = {
  amount     : number;
  percentage : number;
  reward     : number;
};

type PriceOptionProps = {
  option   : PriceOptionType;
  selected : boolean;
  onSelect : () => void;
};

export const WecoinChargePage = () => {
  const user                                            = (useUser()?.user);
  const modal                                           = useModal();
  const { stats                                       } = useUserStatsQuery();
  const createWecoinOrder                               = useCreateWecoinOrder();
  const {processCard,          processEasyPay         } = useTossPayments();
  const [selectedAmount,       setSelectedAmount      ] = useState(priceOptions[0][0].amount);
  const [userAgreementChecked, setUserAgreementChecked] = useState(false);
  const [paymentMethod,        setPaymentMethod       ] = useState<PaymentMethod>('CARD');

  /***********************************************************************/
  /*****                      Components                             *****/
  /***********************************************************************/
  if (!user) {
    modal?.openModal(ModalTypes.LOGIN) 
    return null;
  }

  const Title = () => {
    return (
      <div className="mb-4 md:my-4 flex gap-2">
        <h1 className="font-bold text-2xl">위코인 충전</h1>
        <CircleStackIcon className="w-8 h-8 text-lightprimary" />
      </div>
    );
  };

  const SubTitle = () => {
    return (
      <h2 className="text-xl mb-4">복잡한 결제를 <span className="text-primary">위코인</span>으로 간편하게!</h2>
    );
  };

  const Description = () => {
    return (
      <p className  = "text-base text-mediumgrey" >최대 5% 위코인 적립 혜택도 놓치지 마세요! 한 번 충전하면 결제 정보 입력 없이 쉽게 결제할 수 있는 위코인.</p>
    );
  };

  const MyWecoin = () => {
    return (
      <div className="flex gap-1 items-center mt-8">
        <div className="text-xl mt-4">
          내 위코인: <span className="text-primary">{(stats?.totalWecoins ?? 0) + (stats?.totalPoints ?? 0)}</span>
        </div>
        <CircleStackOutlineIcon className="w-4 h-4 text-lightprimary mt-4" />
      </div>
    ); 
  };

  const PriceOptionHeader = () => {
    const textSize = 'text-sm sm:text-base';

    return (
      <div className="flex justify-between p-4 border-y border-black font-semibold">
        <div className={`${textSize} pl-4 md:pl-10 w-1/3`}>Wecoin 충전</div>
        <div className={`${textSize} w-1/3 text-center`}>적립률</div>
        <div className={`${textSize} w-1/3 text-right`}>리워드 적립</div>
      </div>
    );
  };

  const PriceOptions = () => {
    return (
      <div className="mb-4">
        {priceOptions.map((options, index) => (
          <div className="border-b" key={index} >
            {options.map((option) => (
              <PriceOption
                key      = {option.amount}
                option   = {option}
                selected = {selectedAmount === option.amount}
                onSelect = {() => setSelectedAmount(option.amount)}
              />
            ))}
          </div>
        ))}
      </div>
    );
  };

  const PriceOption = ({ option, selected, onSelect }: PriceOptionProps) => {
    const optionClass = selected ? SELECTED_BG_COLOR : '';
    const amount      = Intl.NumberFormat('ko-KR').format(option.amount);
    const reward      = Intl.NumberFormat('ko-KR').format(option.reward);
    const textSize    = 'text-sm sm:text-base';

    return (
      <div 
        className = {`
          flex justify-between items-center 
          p-4 cursor-pointer 
          ${optionClass} ${HOVER_BG_COLOR}`
        }
        onClick   = {onSelect}
      >
        <div className="flex items-center">
          <input 
            type      = "radio"
            checked   = {selected}
            onChange  = {onSelect}
            className = "form-radio h-3 w-3 sm:h-4 sm:w-4 text-primary md:mr-4"
          />
          <span className={`${textSize} md:mr-2 w-[83px] md:w-[90px] text-right`}>{amount}원</span>
        </div>
        <div className={`${textSize} flex items-center justify-between w-1/2`}>
          <span>{option.percentage}%</span>
          <span className={`${textSize} text-primary`}>+{reward}원</span>
        </div>
      </div>
    );
  };

  const UserAgreementCheckBox = () => {
    return (
      <div className="flex items-center mb-4 mt-10 pl-1 md:pl-0 md:mx-auto w-fit">
        <Checkbox 
          id        = "user-agreement"
          value     = "agreed"
          checked   = {userAgreementChecked}
          onChange  = {() => setUserAgreementChecked(!userAgreementChecked)}
        />
        <label 
          htmlFor   = "user-agreement"
          className = "ms-2 text-[0.67rem] sm:text-xs md:text-sm font-semibold text-slate-700">
          상품, 가격, 할인 정보, 이용 안내 등을 확인하였으며 구매에 동의합니다.
        </label>
      </div>
    );
  };

  const ChargeButton = () => {
    const chargeWecoin = () => {
      createWecoinOrder({
        variables   : { amount: WecoinAmount[selectedAmount] },
        onCompleted : (data) => {
          const orderId   = data.createWecoinOrder.id;
          const orderName = data.createWecoinOrder.name;

          paymentMethod === 'CARD' 
            ? processCard({
              amount       : selectedAmount,
              orderId      : orderId,
              orderName    : orderName,
              customerName : user.name, 
              successUrl   : `${CARD_SUCESS_URL}?orderName=${encodeURIComponent(orderName)}`,
              failUrl      : FAIL_URL,
            }) : processEasyPay({
              amount       : selectedAmount,
              orderId      : orderId,
              orderName    : orderName,
              customerName : user.name,
              easyPay      : paymentMethod,
              successUrl   : `${EASY_PAY_SUCESS_URL}?orderName=${encodeURIComponent(orderName)}`,
              failUrl      : FAIL_URL,
            });
        },
        onError: (error) => {
          console.error(error);

          modal?.openModal(ModalTypes.ALERT, {
            type   : 'error',
            dialog : AlertMessage.common.error.unknown
          });
        }
      });
    };

    return (
      <div className="md:w-1/3 mx-auto mb-8">
        <Button size="full" onClick={chargeWecoin} disabled={!userAgreementChecked} >
          <CircleStackOutlineIcon className="w-4 h-4"      /> 
          <CircleStackOutlineIcon className="w-4 h-4 mr-2" /> 
          위코인 충전
        </Button>
      </div>
    );
  };

  const UserAgreement = () => {
    return (
      <>
        <h1 className="mb-2">이용 안내</h1>
        <div className="text-xs text-mediumgrey">
          {userAgreement.trim().split('\n').map((line, index) => (
            <p key={index} className="mb-2">{line}</p>
          ))}
        </div>
      </>
    );
  };

  const PaymentMethod = () => {
    const availablePaymentMethods: PaymentMethod[] = ['CARD', 'TOSSPAY', 'KAKAOPAY', 'NAVERPAY'];
    const icons                                    = {
      'CARD'     : <CreditCardIcon className="w-6 h-6 text-lightprimary" />,
      'TOSSPAY'  : <Icon name="tossPay"  />,
      'KAKAOPAY' : <Icon name="kakaoPay" />,
      'NAVERPAY' : <Icon name="naverPay" />,
    }

    return (
      <div className="my-6"> 
        <h1 className="text-xl mb-2">결제 수단</h1>
        <div 
          className="px-2 my-4 py-2 flex flex-wrap border border-mediumgrey rounded-md"
        > 
          {availablePaymentMethods.map((method) => (
            <label 
              key       = {method}
              className = {`
                flex items-center 
                py-2 px-4 w-1/2
                rounded-md
                cursor-pointer
                ${HOVER_BG_COLOR}
                ${method === paymentMethod ? SELECTED_BG_COLOR : ''}
              `}
            >
              <RadioButton
                name     = "payment-methods"
                value    = {method}
                checked  = {method === paymentMethod}
                onChange = {() => setPaymentMethod(method)}
              /> 
              {method === 'CARD' ? (<span className="text-sm mr-1">신용카드</span>) : null}
              {icons[method]}
            </label>
          ))}
        </div>
      </div>
    );
  };

  const isMyPage       = location.pathname === PATH.MYPAGE.wecoin;
  const containerWidth = isMyPage 
    ? `w-full mx-auto md:pl-2` 
    : `${defaultContainerWidth} mx-auto py-4 mb-4`

  return (
    <div className={containerWidth}>
      { !isMyPage && <Title /> }
      <div className = "mb-4">
        <SubTitle    /> 
        <Description /> 
        <MyWecoin    /> 
      </div>
      <PriceOptionHeader     /> 
      <PriceOptions          /> 
      <PaymentMethod         />
      <UserAgreementCheckBox /> 
      <ChargeButton          /> 
      <UserAgreement         /> 
    </div>
  );
};

const userAgreement = `
· 1코인은 1원입니다.
· 코인 사용시 리워드 포인트가 먼저 사용되고, 위코인이 사용됩니다.
· 결제 취소는 결제 후 7일 이내에만 할 수 있습니다. 단, 결제한 위코인이나 리워드 포인트를 일부를 사용하면 결제 취소할 수 없습니다.\n
· 위코인은 마지막 이용일로부터 5년 경과 시까지 이용 내역이 없으며 1년 동안 위코드에 접속하지 않은 경우, <상법 제 64조 상사채권 소멸시효> 조항에 따라 소멸됩니다.\n
· 잔여 위코인은 환불받을 수 있으며 충전 시 무료로 지급받은 위포인트를 공제한 후, 잔액 환급 비율(90%)에 해당하는 금액을 환불해 드립니다.\n
· 무료로 지급받은 코인이나 리워드 포인트는 환불받을 수 없습니다.\n
· 위코인 충전시에는 문화비소득공제 신청을 할 수 없습니다.\n
· 미성년자의 경우 결제 시 법정대리인의 동의가 필요하며, 이에 따라 법정대리인의 동의를 받았음을 확인합니다.
`
