import React                            from 'react';
import { BrowserRouter, Route, Routes, Outlet } from 'react-router-dom';

import Footer              from 'components/core/Footer';
import GNB                 from 'components/core/GNB';
import KakaoRedirect       from 'components/core/KakaoLogin/KakaoRedirect';
import ScrollToTop         from './ScrollToTop';
import ChannelTalkProvider from 'contexts/ChannelTalkProvider';
import { PATH          }   from 'constant';
import { withAuth      }   from './AuthRoute';
import { UserProvider  }   from 'contexts/UserContext';
import { ModalProvider }   from 'contexts/ModalContext';
import {
  ErrorPage,
  MainPage,
  CoursePage,
  ChapterPage,
  MyPage,
  SignUpPage,
  TermPage,
  WecoinChargePage,
  LoginPage,
  PaymentSuccessPage, 
  PaymentCanceledPage,
  CourseCompletedPage,
} from 'pages';

const Layout = () => {
  return (
    <>
      <GNB />
      <Outlet />
      <Footer />
    </>
  );
};

const Router = () => {
  return (
    <BrowserRouter>
      <UserProvider>
      <ModalProvider>
        <ScrollToTop />
        <ChannelTalkProvider>
          <Routes>
            <Route element = {<Layout />}>
              <Route path = {PATH.HOME.base             } element = { <MainPage/>                   } />
              <Route path = {PATH.AUTH.kakao            } element = { <KakaoRedirect/>              } />
              <Route path = {PATH.SIGNUP.base           } element = { <SignUpPage/>                 } />
              <Route path = {PATH.LOGIN.base            } element = { <LoginPage/>                  } />
              <Route path = {PATH.COURSE.course         } element = { <CoursePage/>                 } />
              <Route path = {PATH.COURSE.completed      } element = { <CourseCompletedPage/>        } />
              <Route path = {PATH.TERM.base             } element = { <TermPage/>                   } />
              <Route path = {PATH.ERROR.base            } element = { <ErrorPage/>                  } />
              <Route path = {PATH.PAYMENT.success       } element = { withAuth(PaymentSuccessPage)  } />
              <Route path = {PATH.PAYMENT.fail          } element = { withAuth(PaymentCanceledPage) } />
              <Route path = {PATH.MYPAGE.base           } element = { withAuth(MyPage)              } />
              <Route path = {PATH.MYPAGE.purchaseDetail } element = { withAuth(MyPage)              } />
              <Route path = {PATH.MYPAGE.paymentDetail  } element = { withAuth(MyPage)              } />
              <Route path = {PATH.PAYMENT.wecoin        } element = { withAuth(WecoinChargePage)    } />
              <Route path = {PATH.NOTFOUND.base         } element = { <MainPage />                  } />
            </Route>
            <Route path = {PATH.COURSE.latestChapter } element = { <ChapterPage/> } />
            <Route path = {PATH.COURSE.chapter       } element = { <ChapterPage/> } />
          </Routes>
        </ChannelTalkProvider>
      </ModalProvider>
      </UserProvider>
    </BrowserRouter>
  );
};

export default Router;
