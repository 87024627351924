import React, { useEffect, useState } from 'react';
import { 
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import OpenGraph                from 'components/core/Helmet/OpenGraph';
import MarkdownContent          from 'components/core/MarkdownContent';
import NavBar                   from './NavBar';
import FooterBar                from './FooterBar';
import VideoLecture             from './VideoLecture';
import { useModal, ModalTypes } from 'contexts/ModalContext';
import { useUser              } from 'contexts/UserContext';
import { 
  PATH, 
  AlertMessage,
  DESKTOP
} from 'constant';
import { 
  useGetChapterQuery,
  useChapterTour,
  useMarkChapterTourAsCompletedMutation,
} from './hooks';

export const ChapterPage = () => {
  const user     = useUser();
  const modal    = useModal();
  const location = useLocation();
  const navigate = useNavigate();

  const { courseId= ''    , sequence            } = useParams();
  const [ activeTab       , setActiveTab        ] = useState('Video');
  const [mainContentHeight, setMainContentHeight] = useState('100vh');
  const [hasTourStarted   , setHasTourStarted   ] = useState(false);
  const markChapterTourAsCompleted                = useMarkChapterTourAsCompletedMutation();
  const startChapterTour                          = useChapterTour();

  /***********************************************************************/
  /*****                      Fetches                                *****/
  /***********************************************************************/
  const { chapter, refetchChapter, error } = useGetChapterQuery({courseId, sequence});

  /***********************************************************************/
  /*****                      Use Effects                            *****/
  /***********************************************************************/
  useEffect(() => {
    // Calculate and set the height of the main content dynamically
    const navBarHeight    = 65;
    const footerBarHeight = 65;

    const updateContentHeight = () => {
      const viewportHeight = window.innerHeight;
      setMainContentHeight(`${viewportHeight - navBarHeight - footerBarHeight}px`);
    };

    updateContentHeight(); // Initial calculation
    window.addEventListener('resize', updateContentHeight); // Update on resize

    return () => {
      window.removeEventListener('resize', updateContentHeight);
    };
  }, []);

  useEffect(() => {
    // Disable scrolling when the component is mounted 
    // and enable scrolling when it is unmounted
    document.body.classList.add('overflow-hidden');

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  useEffect(() => {
    // Set the active tab to 'Video' when the chapter is fetched
    setActiveTab('Video');
  }, [chapter]);

  useEffect(() => {
    if(error) {
      if(error.message === 'NOT FOUND') {
        return navigate(PATH.NOTFOUND.base);
      }

      if(error?.message === 'NOT AUTHORIZED') {
        if(! user?.isAuthenticated) {
          return navigate(PATH.LOGIN.toLogin(location.pathname));
        }

        modal?.openModal(ModalTypes.PAYMENT, {
          courseId  : Number(courseId),
          sequence  : Number(sequence ?? 1),
          onSuccess : refetchChapter,
          onClose   : () => {
            modal?.openModal(ModalTypes.ALERT, {
              type   : 'info',
              dialog : AlertMessage.payment.info.paymentCanceled,
              onClose: () => navigate(PATH.COURSE.toDescription(courseId)),
            });
          }
        });
        return;
      }

      const errorPage = PATH.ERROR.toError(error.message);
      return navigate(errorPage);
    }
  }, [error]);

  useEffect(() => {
    const isMobile                = window.innerWidth < DESKTOP;
    const hasCompletedChapterTour = user?.user?.completedChapterTour;
    const isUserLoaded            = user?.user !== undefined;

    if (
      isUserLoaded             && 
      !isMobile                && 
      !hasCompletedChapterTour && 
      chapter                  && 
      !hasTourStarted
    ) {
      const onTourCompleted = () => {
        markChapterTourAsCompleted({onCompleted: () => user?.fetchUserProfile()});
      }

      setHasTourStarted(true);
      startChapterTour({onDestoryed: onTourCompleted});
    }
  }, [chapter, user]);

  /***********************************************************************/
  /*****                      components                             *****/
  /***********************************************************************/
  if (! chapter ) { return null; }
  
  const isVisualCodeMentor = chapter.content.includes('<visualcodementor');

  return (
    <div className="flex flex-col items-center w-full h-screen">
      <OpenGraph
        title       = {chapter.title}
        description = {chapter.summary}
        image       = {chapter.ogImage}
      />
      <NavBar 
        className    = "navbar h-[65px]"
        activeTab    = {activeTab}
        setActiveTab = {setActiveTab}
      />
      <div className = "w-full" style={{height: mainContentHeight}}>
      {
        isVisualCodeMentor 
          ? <VideoLecture 
              content      = {chapter.content}
              height       = {mainContentHeight}
              data         = {chapter.metaData}
              activeTab    = {activeTab}
              setActiveTab = {setActiveTab}
            /> 
          : <MarkdownContent content = {chapter.content} />
      }
      </div>
      <FooterBar 
        className = "h-[65px]"
        chapter   = {chapter}
      />
    </div>
  );
};
