import { useMutation, gql, MutationHookOptions } from '@apollo/client';

import { ProductType } from 'pages/types';

const PURCHASE_MUTATION = gql`
  mutation purchase($courseId: Float!, $productType: ProductType!) {
    purchase(courseId: $courseId, productType: $productType) {
      orderId
      orderName
      amount
      status
      message
    }
  }
`

export const usePurchaseMutation = (courseId: number, productType: ProductType) => {
  const options = {
    variables   : {courseId: courseId, productType: productType},
    context     : {endpoint: 'payment'},
    fetchPolicy : 'network-only'
  };

  const [purchase, {loading, error}] = useMutation(PURCHASE_MUTATION, options as MutationHookOptions);

  return {purchase, loading, error};
};

