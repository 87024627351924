import React from 'react';
import Icon  from 'components/core/Icon/Icon';
import css   from './BaseModal.module.scss';

const ModalHeader = ({
  title,
  switchModal,
}: {
  title: string;
  switchModal: () => void;
}) => {
  if (!title) return <></>;

  return (
    <header className={css.header}>
      <h4>{title}</h4>
      <Icon name="close" onClick={switchModal} />
    </header>
  );
};

export default ModalHeader;
