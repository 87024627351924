import React from 'react';

import { AlertMessage                                   } from 'constant';
import { useModal, ModalTypes                           } from 'contexts/ModalContext';
import { shareToKakao, shareToFacebook, copyToClipboard } from 'utils/share';
import { cn, cond                                       } from 'utils/styles';
import FacebookLink                                       from 'assets/common/Facebook_logo.png';
import FacebookLinkDiasbled                               from 'assets/common/Facebook_logo_disabled.png';
import KakaoLink                                          from 'assets/common/Kakao_logo.png';
import KakaoLinkDisabled                                  from 'assets/common/Kakao_logo_disabled.png';
import DefaultLink                                        from 'assets/common/Link.png';
import DefaultLinkDisabled                                from 'assets/common/Link_disabled.png';
import css                                                from './Share.module.scss';
import { Course }                                         from 'pages/types';

interface Props {
  course    : Course;
  width?    : string;
  disabled? : boolean;
}

const Share = ({ course, width, disabled = false }: Props) => {
  const modal                              = useModal();
  const { title, introduction, thumbnail } = course;

  const LINK_DATA = [
    {
      id         : 1,
      name       : 'kakao link',
      socialName : '카카오톡',
      imgUrl     : disabled ? KakaoLinkDisabled : KakaoLink,
      onClick    : () => { shareToKakao(title, introduction, thumbnail!); },
    },
    {
      id         : 2,
      name       : 'facebook link',
      socialName : '페이스북',
      imgUrl     : disabled ? FacebookLinkDiasbled : FacebookLink,
      onClick    : () => { shareToFacebook(); },
    },
    {
      id         : 3,
      name       : 'default link',
      socialName : 'URL 복사',
      imgUrl     : disabled ? DefaultLinkDisabled : DefaultLink,
      onClick    : () => {
        copyToClipboard({
          onCompleted: () => {  
            modal?.openModal(ModalTypes.ALERT, {
              dialog : AlertMessage.common.info.copiedUrl,
            })
          },
          onError: (_) => {
            modal?.openModal(ModalTypes.ALERT, {
              type   : 'error',
              dialog : AlertMessage.common.error.copiedError,
            });
          }
        });
      },
    },
  ];

  return (
    <ul className={css.linkBox} style={{ width }}>
      {LINK_DATA.map(data => {
        const { id, name, imgUrl, socialName, onClick } = data;
        return (
          <li
            key={id}
            className={cn(css.shareBtn, cond(disabled, css.disabled))}
            onClick={disabled ? undefined : onClick}
          >
            <img
              src={imgUrl}
              alt={name}
              className={cn(css.linkImg, cond(disabled, css.disabled))}
            />
            <p className={cn(css.type, cond(disabled, css.disabled))}>
              {socialName}
            </p>
          </li>
        );
      })}
    </ul>
  );
};

export default Share;
