import React  from 'react';
import css    from './Callout.module.scss';

const BACKGROUND_COLOR = {
  light  : '#ffffff',
  grey   : 'rgb(235 236 237 / 50%)',
  blue   : 'rgb(5 108 245 / 13%)',
  green  : 'rgb(6 243 112 / 12%)',
  yellow : 'rgb(242 243 6 / 21%)',
}

export interface CalloutProps {
  emoji   : string;
  content : React.ReactNode[] | string;
  theme?  : 'light' | 'grey' | 'blue' | 'green' | 'yellow';
}

const Callout: React.FC<CalloutProps> = ({ emoji, content, theme = 'grey' }) => {
  const backgroundColor = { backgroundColor: BACKGROUND_COLOR[theme] };

  return (
    <span className = {css.callout} style = {backgroundColor}>
      <span className = {css.iconBox}>{emoji}</span>
      <span className = {css.content}>{content}</span>
    </span>
  );
};

export default Callout;
