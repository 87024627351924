const data: Record<number, any> = {
  1: [
    "총 49 강의 (2시간 10분)",
    "난이도: 초급",
    "49개의 실습 과제",
    "HTML, CSS",
    "전체 강의 총 가격 6,960원",
  ],
  2: [
    "총 57개 강의 (2시간 30분)",
    "난이도: 초급",
    "57개 실습 과제",
    "HTML, CSS, JavaScript",
    "전체 강의 총 가격 7,680원",
  ],
  3: [
    "총 92개 강의 (약 3시간 48분)",
    "난이도: 초급",
    "92개 실습 과제",
    "HTML, CSS, JavaScript",
    "전체 강의 총 가격 9,600원",
  ]
}

export default data;
