import React           from 'react';
import { useNavigate } from 'react-router-dom';
import { isValidURL  } from 'utils/validation';

export interface LinkToSubContentBoxProps {
  link        : string;
  children    : React.ReactNode;
  isReference : boolean;
}

const LinkToSubContentBox = ({ link, children, isReference }: LinkToSubContentBoxProps) => {
  const navigate = useNavigate();

  return (
    <>
      {isValidURL(link) ? (
        <a href={link} target="_blank" rel="noreferrer" className="linkBox">
          {children}
        </a>
      ) : (
        <span
          onClick={() => navigate(link)}
          className={isReference ? 'reference' : 'linkBox'}
        >
          {children}
        </span>
      )}
    </>
  );
};

export default LinkToSubContentBox;
