import React           from 'react'
import { useLocation } from "react-router-dom"

import KakaoLogin      from 'components/core/KakaoLogin'
import shotSampleLogin from 'assets/page/main/phone_code.svg'

export const REDIRECT_URL = 'redirectUrl' 
export const SignUpPage   = () => {
  const queryParams = new URLSearchParams(useLocation().search)
  const redirectUrl = queryParams.get(REDIRECT_URL) ?? '/'
  const highlight   = {
    background: "linear-gradient(180deg, rgb(255 255 255 / 0%) 70%, #ffd700 30%)"
  } as React.CSSProperties

  return (
    <main className="flex h-[80vh] lg:bg-sec-bg lg:bg-cover lg:bg-center">
      <div className="
        flex flex-col lg:flex-row flex-grow
        justify-center items-center 
        w-full max-w-screen-xl 
        mx-auto lg:px-5 
        gap-10 lg:gap-30 xl:gap-40
        p-4
      ">
        <section className="flex flex-col gap-8">
          <div className="hidden lg:block w-[336px] h-[365px] overflow-hidden">
            <img src={shotSampleLogin} alt="login" className="w-full h-full object-cover" />
          </div>
        </section>
        <section className="flex flex-col items-center lg:items-start w-full lg:w-auto px-4 lg:px-0">
          <div className="
            flex flex-col 
            min-w-screen lg:w-full 
            py-14 lg:px-[86px] 
            bg-white rounded-md text-center
          ">
            <header className="
              flex flex-col justify-center items-center 
              w-full 
              mb-8 pb-10 
              border-b border-gray-300
            ">
              <h1 className="mb-2 text-darkBlue font-title text-3xl font-normal">
                카카오로 간편하게 회원가입!<br />
              </h1>
              <p className="
                text-base text-gray-500 
                w-full pt-2 pb-6 lg:pb-10 
                border-b border-gray-300 lg:border-none
              ">
                <mark style={highlight}>쉽게</mark>, 
                <mark style={highlight}>재밌게</mark>, 
                <mark style={highlight}>빠르게</mark>, 
                <mark style={highlight}>저렴하게</mark><br />
                내 가능성을 발전시킬 수 있는곳, 위코드!<br />
                카카오로 로그인하고 바로  시작하세요 🚀
              </p>
              <div className="mt-8 lg:mt-0">
                <KakaoLogin redirectUrl= {redirectUrl}/>
              </div>
            </header>
            <footer>
              <p className={`hidden lg:block text-base text-darkBlue mb-4`}>
                <mark style={highlight}>사전 지식 없이도</mark>, 누구나 쉽게<br />
                <mark style={highlight}>모바일</mark>로, 
                <mark style={highlight}>데스크탑</mark>으로, 언제 어디서든 <br />
                <mark style={highlight}>전철</mark>에서, 
                <mark style={highlight}>버스</mark>에서, 
                <mark style={highlight}>집</mark>에서 <b>위코드</b>!
              </p>
            </footer>
          </div>
        </section>
      </div>
    </main>
  )
};
