import React from 'react'

import { useNavigate } from 'react-router-dom'
import { PATH        } from 'constant';
import { IconName    } from 'components/core/Icon/Icon';
import Icon            from 'components/core/Icon/Icon'
import css             from './AuthenticatedMenu.module.scss'

const availableMenu = [
  {
    id       : 1,
    title    : '회원정보',
    path     : PATH.MYPAGE.userProfile,
    iconName : 'profile',
  },
  {
    id       : 2,
    title    : '내 강의',
    path     : PATH.MYPAGE.myCourse,
    iconName : 'history',
  },
  {
    id       : 3,
    title    : '찜한 강의',
    path     : PATH.MYPAGE.favorite,
    iconName : 'heartDefault',
  },
   {
    id       : 4,
    title    : '구매 내역',
    path     : PATH.MYPAGE.purchaseHistory,
    iconName : 'receipt',
  },
   {
    id       : 5,
    title    : '로그아웃',
    path     : PATH.MYPAGE.logout,
    iconName : 'logout',
  },
];

interface Props { switchModal: () => void }

const AuthenticatedMenu = (props: Props) => {
  const { switchModal } = props
  const navigate        = useNavigate()

  return (
    <div className={css.mypageWrapper}>
      <div className={css.tabsWrapper}>
        {availableMenu.map(tab => {
          const { id, title, path, iconName } = tab

          return (
            <div
              key       = {id}
              className = {css.categoryBox}
              onClick   = {() => {
                navigate(path)
                switchModal()
              }}
            >
              <Icon name   = {iconName as IconName} />
              <p className = {css.categoryText}>{title}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default AuthenticatedMenu
