import React         from 'react';
import { useParams } from 'react-router-dom';

import { ErrorPage            } from 'pages';
import Button                   from 'components/core/Button';
import { useModal, ModalTypes } from 'contexts/ModalContext';
import { AlertMessage         } from 'constant';
import { usePaymentInfoQuery  } from './hooks';

const PaymentDetail = () => {
  const modal            = useModal();
  const { orderId = '' } = useParams();

  const { payment, loading, error } = usePaymentInfoQuery({
    orderId,
    onError: () => {
      modal?.openModal(ModalTypes.ALERT, {
        type             : 'error',
        dialog           : AlertMessage.common.error.unknown,
        hasHelpEmailInfo : true,
      });
    },
  });

  if (error              ) return <ErrorPage />;
  if (loading || !payment) return null;

  /***************************************************************************/
  /*                        Components                                       */
  /***************************************************************************/
  const PaymentDetailInfo = () => {
    const approvedDate   = new Date(payment.approvedAt.replace('Z',''));
    const purchasedDate  = approvedDate.toLocaleString('ko-KR').slice(0,-3);
    const paymentMethod  = {
      CARD     : '카드',
      EASY_PAY : '간편결제',
      FREE     : '무료',
      WECOIN   : 'Wecoin',
    }[String(payment.paymentMethod)];

    const paymentDetail   = [
      { title: '주문 번호' , value: payment.orderId }, 
      { title: '결제일'    , value: purchasedDate },
      { title: '주문명'    , value: payment.orderName }, 
      { title: '결제 금액' , value: Intl.NumberFormat('ko-KR').format(payment.amount) + "원" }, 
      { title: '결제 수단' , value: paymentMethod}, 
    ]

    const textSize = 'text-lg';
    return (
      <div className={`flex flex-col itmes-center justify-between border-t`}>
        {
          paymentDetail.map((detail, index) => (
            <div className="flex items-center justify-between" key={index} >
              <div className={`
                bg-gray-100 
                border-r border-b
                pr-4 py-2 
                w-[30%] 
                ${textSize} font-semibold text-gray-500 text-right
              `}>{detail.title}</div>
              <div className={`pl-4 py-2 ${textSize} w-[70%] text-left border-b truncate`}>{detail.value}</div>
            </div>
        ))}
      </div>
    );
  };

  const RecieptButton = () => {
    const showReciept = () => {
      window.location.href = payment.receiptUrl;
    }

    return (
      <Button className="mt-4" onClick={showReciept}>영수증 보기</Button>
    )
  }

  return (
    <>
      <h1 className="font-bold text-xl mb-4">결제 정보</h1>
      <PaymentDetailInfo />
      <RecieptButton     />
    </>
  )
}

export default PaymentDetail;
