import TagManager from 'react-gtm-module';

import { ProductType } from 'pages/types';

export const generateItemId = (id: number, productType: ProductType) => {
  return `${productType}_${id}`;  
}

export const pushPurchaseEvent = (
  args: {
    transactionId : string,
    amount        : number,
    currency      : string,
    products      : Record<string, any>[]
  }
) => {
  const totalAmount   = args.products.reduce((acc, item) => acc + item.price, 0.00);
  const items         = args.products.map(product => ({
    item_id  : generateItemId(product.id, product.productType),
    item_name: product.name,
    price    : parseFloat(product.price),
    quantity : parseInt(product.quantity),
  }));

  const dataLayerArgs = {
    dataLayer: {
      event     : 'purchase',
      ecommerce : {
        transaction_id : args.transactionId,
        value          : totalAmount,
        currency       : 'KRW',
        items          : items
      }
    }
  };

  TagManager.dataLayer(dataLayerArgs);
};

export const pushViewItemEvent = (
  args: {
    productId   : number,
    productType : ProductType,
    name        : string,
    price       : number
  }
) => {
  const dataLayerArgs = {
    dataLayer: {
      event     : 'view_item',
      ecommerce : {
        items: [
          {
            item_id  : generateItemId(args.productId, args.productType),
            item_name: args.name,
            price    : args.price,
          }
        ]
      }
    }
  };

  TagManager.dataLayer(dataLayerArgs);
};

export const pushAddToCartEvent = (
  args: {
    productId   : number,
    productType : ProductType,
    name        : string,
    price       : number,
    quantity    : number
  }
) => {
  const dataLayerArgs = {
    dataLayer: {
      event     : 'add_to_cart',
      ecommerce : {
        currency: 'KRW',
        items   : [
          {
            item_id  : generateItemId(args.productId, args.productType),
            item_name: args.name,
            price    : args.price,
            quantity : args.quantity
          }
        ]
      }
    }
  };

  TagManager.dataLayer(dataLayerArgs);
};


export const pushRemoveFromCartEvent = (
  args: {
    productId   : number,
    productType : ProductType,
    name        : string,
    price       : number,
    quantity    : number
  }
) => {
  const dataLayerArgs = {
    dataLayer: {
      event     : 'remove_from_cart',
      ecommerce : {
        items: [
          {
            item_id  : generateItemId(args.productId, args.productType),
            item_name: args.name,
            price    : args.price,
            quantity : args.quantity
          }
        ]
      }
    }
  };

  TagManager.dataLayer(dataLayerArgs);
};

export const pushBeginCheckoutEvent = (totalAmount : number, product: Record<string, any>) => {
  const item = {
    item_id  : generateItemId(product.id, product.productType),
    item_name: product.name,
    price    : parseFloat(product.price),
    quantity : parseInt(product.quantity),
  };
  
  const dataLayerArgs = {
    dataLayer: {
      event     : 'begin_checkout',
      ecommerce : {
        currency: 'KRW',
        value   : totalAmount,
        items   : [item]
      }
    }
  };

  TagManager.dataLayer(dataLayerArgs);
}

export const pushUserSignUpEvent = (userId: number) => {
  const dataLayerArgs = {
    dataLayer: {
      event         : 'user_signup',
      user_id       : userId,
      user_type     : 'new_user',
      signup_method : 'kakao'
    }
  };

  TagManager.dataLayer(dataLayerArgs);
}


