import { gql, useMutation, MutationHookOptions  } from '@apollo/client';

const UPDATE_CHAPTER_STATUS_AS_COMPLETED = gql`
  mutation updateChapterStatusAsCompleted($courseId: Float!, $chapterId: Float!) {
    updateChapterStatusAsCompleted(
      courseId  : $courseId,
      chapterId : $chapterId
    ) {
      status
    }
  }
`;

export const useUpdateChapterStatusAsACompleted = () => {
  const query     = UPDATE_CHAPTER_STATUS_AS_COMPLETED;
  const options   = {
    context     : { endpoint: 'course' },
    fetchPolicy : 'network-only',
  } as MutationHookOptions;
  
  const [updateChapterStatusAsCompleted] = useMutation(query, options);

  return (variables: {courseId: number | string, chapterId: number | string}) => {
    return updateChapterStatusAsCompleted({
      variables: {
        courseId : Number(variables.courseId),
        chapterId: Number(variables.chapterId)
      }
    });
  };
};
