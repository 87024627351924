import React          from 'react';
import logoCoverImage from 'assets/home-cover.png'

type Props = {
  text       : string,
  className? : string,
}

const LogoCoverBelt = ({ text, className }: Props) => {
  return (
    <div className={`relative ${className ? `${className}` : ``}`}>
      <img className="h-80 object-cover w-full" src={logoCoverImage} alt="Wecode Logo" />
      <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
        <h1 className="text-white text-5xl font-bold">{text}</h1>
      </div>
    </div>
  );
};

export default LogoCoverBelt;
