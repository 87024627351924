import React from 'react';

type Theme = 
  'grey'  |
  'blue'  |
  'green' |
  'purple'|
  'pink'  |
  'yellow';

interface HighlightProps {
  theme?   : Theme;
  style?   : 'underline' | 'background';
  children : string | React.ReactNode;
}

const BACKGROUND_COLOR = {
  grey   : 'rgb(235 236 237 / 50%)',
  blue   : '63b7ee5e',
  green  : '#ecf6e6',
  yellow : '#fef85a91',
  purple : 'f95afe45',
  pink   : 'ffc0cb59',
}

const Highlight = ({ 
  children,
  theme = 'yellow',
  style = 'underline',
}: HighlightProps) => {

  const backgroundColor = BACKGROUND_COLOR[theme];
  const highlightStyle  = style === 'underline' ? { 
    boxShadow: `inset 0 -7px ${backgroundColor}` 
  } : { 
    backgroundColor: backgroundColor 
  };
  const css = Object.assign({padding: '0 3px'}, highlightStyle); 

  return <span style={css}>{children}</span>;
};

export default Highlight;
