import React  from 'react';
import css    from './ImageCard.module.scss';
import { cn } from 'utils/styles';

interface ImageCardProps {
  src  : string;
  alt? : string;
  size?: 'small' | 'medium' | 'large' | 'full';
}

const ImageCard = ({src, alt, size = 'medium'}: ImageCardProps) => {
  return (
    <div className={cn(css.imageCard, css[`imageCard--${size}`])}>
      <img src={src} alt={alt} />
    </div>
  );
};

export default ImageCard;
