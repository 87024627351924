import { gql, useMutation } from '@apollo/client';

export const UPDATE_PROFILE_IMAGE_MUTATION = gql`
  mutation {
    updateProfileImage {
      url
    }
  }
`;

type Props = {
  onCompleted?: (imageUrl: string ) => void;
  onError?    : (error: any       ) => void;
}

const useProfileImageMutation = (props: Props) => {
  const { onCompleted, onError } = props;
  const [getProfileImageUrl]     = useMutation(
    UPDATE_PROFILE_IMAGE_MUTATION, 
    {
      context: { endpoint: 'auth' },
      onCompleted: (data      ) => { onCompleted && onCompleted(data.updateProfileImage.url) },
      onError    : (error: any) => { onError     && onError(error) },
    }
  );

  return getProfileImageUrl;
}

export default useProfileImageMutation;
