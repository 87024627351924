import React from 'react';

import Button        from 'components/core/Button';
import Icon          from 'components/core/Icon/Icon';
import { BaseModal } from 'components/modal/BaseModal';
import css           from './ImageTypeAlertModal.module.scss';

interface Props {
  onClose: () => void;
}

const ImageTypeAlertModal = ({onClose}: Props) => {
  return (
    <BaseModal switchModal={onClose}>
      <div className={css.container}>
        <Icon name="warning" />
        <p className={css.message}>
          JPG, JPEG, PNG, HEIC를
          <br /> 선택해주세요.
        </p>
        <div className={css.btnWrap}>
          <Button size="large" onClick={onClose}>
            확인
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};

export default ImageTypeAlertModal;
