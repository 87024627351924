import { gql, useMutation, MutationHookOptions  } from '@apollo/client';

const MARK_CHAPTER_TOUR_AS_COMPLETED = gql`
  mutation markChapterTourAsCompleted {
    markChapterTourAsCompleted {
      completedChapterTour
    }
  }
`;

export const useMarkChapterTourAsCompletedMutation = () => {
  const query   = MARK_CHAPTER_TOUR_AS_COMPLETED;
  const options = {
    context     : { endpoint: 'auth' },
    fetchPolicy : 'network-only',
  } as MutationHookOptions;
  
  const [markChapterTourAsCompleted] = useMutation(query, options);

  return markChapterTourAsCompleted;
};
