export const codingBootcampClasses = [
  {
    title: '🚩 HTML & CSS 기초부터 실전까지 웹개발 한 번에 끝내기',
    description: '믿을 수 있는 위코드의 커리큘럼! HTML & CSS부터 순서대로 공부하며 웹개발의 모든 것을 체계적으로 배워보세요!',
    items: [
      {
        sequence: 1,
        courseId: 4,
        title: 'CSS Display 속성 처음부터 끝까지 완전 정복',
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course4-main-thumbnail-small.png',
        description: '웹사이트 레이아웃, 혼자 만들다가 길을 잃진 않으셨나요? Display 속성 입문자를 위한 강의예요! 함께 만들어봐요',
        price: '₩ 3,000',
        chaptersCount: 7,
        tags: ['Display', 'Block', 'Inline', 'Inline-block'],
      },
      {
        sequence: 2,
        courseId: 7,
        title: '오늘부터 바로 써먹는 CSS Flex 필수 가이드',
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course7-main-thumbnail-small.png',
        description: 'Flex로 웹사이트를 만드는 진짜 방법을 배워보세요. 듣자마자 오늘부터 바로 써먹을 수 있는 Flex를 알려드려요!',
        price: '₩ 3,000',
        chaptersCount: 7,
        tags: ['Flex', 'justify-content', 'align-items'],
      },
      {
        sequence: 3,
        title: '프론트엔드 본격 시작, JS로 만드는 weflix',
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course3-main-thumbnail-small-comingsoon.png',
        description: '프론트엔트 본격 시작을 알려요! JavaScript를 시작하며, 웹 개발 언어 3종을 모두 배울 수 있어요!',
        price: '₩ 7,500',
        chaptersCount: 12,
        tags: ['HTML', 'CSS', 'JavaScript'],
      },
      {
        sequence: 4,
        title: '처음부터 시작하는 React',
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course4-main-thumbnail-small-comingsoon.png',
        description: '실무에서 가장 많이 쓰는 React! 그 기본적인 원리를 함께 이해하며 실력을 발전시켜 보아요!',
        price: '₩ 7,500',
        chaptersCount: 10,
        tags: ['프론트엔드', 'React', 'JS'],
      },
      {
        sequence: 5,
        title: '백엔드 첫걸음 Node.js로 만드는 예약 플랫폼',
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course5-main-thumbnail-small-comingsoon.png',
        description: `JavaScript 하나로 백엔드까지! 예약 플랫폼을 만들면서, 백엔드의 가장 기본을 다집니다.`,
        price: '₩ 7,500',
        chaptersCount: 10,
        tags: ['백엔드', 'JS', 'Node.js']
      },
      {
        sequence: 6,
        title: '실전! 관계형 데이터베이스와 함께하는 완성형 웹사이트 만들기',
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course6-main-thumbnail-small-comingsoon.png',
        description: '체계적인 데이터 관리의 시작은 관계형 데이터베이스! 데이터까지 다룰 수 있는 웹 개발 핵심 인재가 되어볼까요?',
        price: '₩ 7,500',
        chaptersCount: 10,
        tags: ['Database', 'RDB', 'SQL'],
      },
      {
        sequence: 7,
        title: '프론트엔드 레벨업! 상태 관리와 함께하는 주문 시스템 개발',
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course7-main-thumbnail-small-comingsoon.png',
        description: '웹서비스의 반응 속도를 높이고, 오류 발생을 줄여 더 나은 사용자 경험을 제공해요! 프론트엔드 레벨업! ',
        price: '₩ 7,500',
        chaptersCount: 10,
        tags: ['React', '상태관리'],
      },
      {
        sequence: 8,
        title: '백엔드 레벨업! 트랜잭션 처리와 쿼리 최적화를 통한 사용성 개선',
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course8-main-thumbnail-small-comingsoon.png',
        description: '글로벌 탑티어 회사들부터 개인 서비스에 이르기까지 모든 서비스들이 고민하는 백엔드 최적화. 지금부터 성장하세요!',
        price: '₩ 7,500',
        chaptersCount: 10,
        tags: ['Transaction', 'Query', 'RDB'],
      },
      {
        sequence: 9,
        title: '클라우드 호스팅과 함께하는 첫 서비스 배포',
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course9-main-thumbnail-small-comingsoon.png',
        description: '아마존(Amazon)에서 제공하는 클라우드 서비스에 대해 학습하며, 웹서비스를 실제로 배포하고 사용해보아요!',
        price: '₩ 7,500',
        chaptersCount: 10,
        tags: ['AWS', 'Cloud', '배포'],
      },
      {
        sequence: 10,
        title: '내 손으로 직접하는 서비스 배포, 그리고 자동화 구축까지',
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course10-main-thumbnail-small-comingsoon.png',
        description: '실무에 착! 배포 전과정 실습부터, 완전한 CI/CD 파이프라인으로 자동화 구축까지!',
        price: '₩ 7,500',
        chaptersCount: 10,
        tags: ['배포', '자동화', 'CI/CD'],
      },
      {
        sequence: 11,
        title: '마이크로 서비스 아키텍쳐란 무엇일까요?',
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course11-main-thumbnail-small-comingsoon.png',
        description: '더이상 MSA 무서워하지 마세요! 서비스별로 필요한 마이크로 서비스 아키텍처에 대해 배워봅니다.',
        price: '₩ 7,500',
        chaptersCount: 10,
        tags: ['MSA', 'Project'],
      },
      {
        sequence: 12,
        title: '한걸음씩 시작하는 모니터링',
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course12-main-thumbnail-small-comingsoon.png',
        description: '서비스의 균형과 안정, 보안을 위해 놓칠 수 없는 모니터링. 상세한 설명과 부하 테스트 실습과 함께해요!',
        price: '₩ 7,500',
        chaptersCount: 10,
        tags: ['모니터링', '안정성'],
      },
    ]
  },
];

export const classPromotions = [
  {
    title: "⏳ 곧 열려요! 핵심 내용에 집중한 개별 강의",
    description: '내게 맞는 강의를 찾고 계시나요? 더 깊은 지식을 배우고 싶으신가요? Wecode가 여러분을 위해 딱 맞춰 준비한 강의를 곧 만나보세요!',
    items: [
      {
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course13-main-thumbnail-comingsoon-simple.png',
        price: '₩ 3,000',
        title: 'Wecode',
      },
      {
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course14-main-thumbnail-comingsoon-simple.png',
        price: '₩ 3,000',
        title: 'Wecode',
      }, {
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course15-main-thumbnail-comingsoon-simple.png',
        price: '₩ 3,000',
        title: 'Wecode',
      }, {
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course16-main-thumbnail-comingsoon-simple.png',
        price: '₩ 3,000',
        title: 'Wecode',
      }, {
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course17-main-thumbnail-comingsoon-simple.png',
        price: '₩ 3,000',
        title: 'Wecode',
      }, {
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course18-main-thumbnail-comingsoon-simple.png',
        price: '₩ 3,000',
        title: 'Wecode',
      }, {
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course19-main-thumbnail-comingsoon-simple.png',
        price: '₩ 3,000',
        title: 'Wecode',
      }, {
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course20-main-thumbnail-comingsoon-simple.png',
        price: '₩ 3,000',
        title: 'Wecode',
      }, {
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course21-main-thumbnail-comingsoon-simple.png',
        price: '₩ 3,000',
        title: 'Wecode',
      }
    ]
  }
];
