import React from 'react'

import { useLocation } from 'react-router-dom'
import KakaoLogin      from 'components/core/KakaoLogin'
import css             from './UnauthenticatedMenu.module.scss'

const UnauthenticatedMenu = () => {
  const location = useLocation()

  return (
    <div className={css.loginWrapper}>
      <div className={css.btnBox}>
        <KakaoLogin redirectUrl= {location.pathname}/>
      </div>
    </div>
  )
}

export default UnauthenticatedMenu
