type CommonMessageKeys =
  | 'unknown'
  | 'nonAuthorization'
  | 'nonAuthorizationPage'
  | 'tryAgain'
  | 'uploadImage'
  | 'copiedError'
  | 'failedSendEmail'
  | 'invalidAccess'
  | 'required'
  | 'copiedUrl'
  | 'checkForm'
  | 'needLogin'
  | 'needInfo'
  | 'success'

type AuthMessageKeys =
  | 'resetPasswordFailed'
  | 'retry'
  | 'modifyAvatar'
  | 'invalidAccess'
  | 'sessionExpired'
  | 'sentResetPasswordEmail'
  | 'edit'
  | 'logout'

export type ModalMessageKeys = CommonMessageKeys | AuthMessageKeys

const Common = {
  error: {
    message: (message: string) => ({ 
      icon    : 'warning',
      message : message ?? '예상치 못한 오류가 발생했어요!',
      desc    : '다시 시도해주세요.',
    }),
    unknown: {
      icon    : 'warning',
      message :  '예상치 못한 오류가 발생했어요!',
      desc    : '다시 시도해주세요.',
    },
    uploadImage: {
      icon    : 'warning',
      message : '사진이 저장되지 않았습니다. 다시 시도해주세요.',
      desc    : '다시 시도해주세요.',
    },
    nonAuthorization: {
      icon    : 'xlLock',
      message : '접근 권한이 없습니다.',
      desc    : '로그인이나 구매가 필요합니다.',
    },
    nonAuthorizationPage: {
      icon    : 'xlLock',
      message : '접근 권한이 없습니다.',
      desc    : '해당 페이지에 접근 권한이 없습니다.',
    },
    tryAgain: {
      icon    : 'retry',
      message : '다시 시도해주세요.',
      desc    : '',
    },
    copiedError: {
      icon    : 'warning',
      message : '복사에 실패했습니다.',
      desc    : '다시 시도해주세요.',
    },
    failedSendEmail: {
      icon    : 'mailFail',
      message : '이메일 전송에 실패했습니다.',
      desc    : '다시 시도해주세요.',
    },
    invalidAccess: {
      icon    : 'underConst',
      message : '비정상적인 접근입니다.',
      desc    : '다시 시도해주세요.',
    },
  },
  info: {
    required: {
      icon    : 'checkInput',
      message : '*는 필수 입력사항입니다. 입력 정보를 확인해주세요.',
      desc    : '',
    },
    tryAgain: {
      icon    : 'retry',
      message : '다시 시도해주세요.',
      desc    : '',
    },
    copiedUrl: {
      icon    : 'url',
      message : 'URL을 복사하였습니다.',
      desc    : '',
    },
    checkForm: {
      icon    : 'talk',
      message : '입력정보를 확인해주세요!',
      desc    : '',
    },
  },
  warn: {
    needLogin: {
      icon    : 'xlLock',
      message : '로그인이 필요한 서비스입니다.',
      desc    : '',
    },
    needInfo: {
      icon    : 'talk',
      message : '수강하시는 분 정보를 입력해주세요.',
      desc    : '입력정보를 다시 확인해주세요.',
    },
  },
  positive: {
    success: {
      icon    : 'done',
      message : '성공적으로 처리되었습니다.',
      desc    : '',
    },
  },
} as const

// Messages that depend on a particular domain
const Cart = {
  info: {
    add: {
      icon    : 'emptyCart',
      message : '장바구니에 담으시겠습니까?',
      desc    : '',
    },
  },
  positive: {
    success: {
      icon    : 'icCartCheck',
      message : '장바구니로 이동하시겠습니까?',
      desc    : '장바구니에 담겼습니다.',
    },
  },
  warn: {
    delete: {
      icon    : 'warning',
      message : '선택한 상품을 삭제하시겠습니까?',
      desc    : '',
    },
    deleteAll: {
      icon    : 'warning',
      message : '전체 상품을 삭제하시겠습니까?',
      desc    : '',
    },
  },
}

const Payment = {
  info: {
    agreementRequired: {
      icon    : 'warning',
      message : '구매동의를 체크해주세요.',
      desc    : '',
    },
    paymentCanceled: {
      icon    : 'warning',
      message : '결제가 취소되었습니다.',
      desc    : '',
    },
  },
  error: {
    insufficientBalance: {
      icon    : 'warning',
      message : 'Wecoin 잔액이 부족합니다.',
      desc    : '충전 페이지로 이동합니다. 충전과 동시에 해당 상품이 구매 됩니다',
    },
  }
}

const Course = {
  warn: {
    deleteWishlist: {
      icon    : 'warning',
      message : '찜한 강의를 삭제하시겠습니까?',
      desc    : '',
    },
    loadingDeployData: {
      icon    : 'warning',
      message : '결과를 불러오는 중입니다',
      desc    : '다시 시도해주세요.',
    },
  },
  error: {
    notAuthorized: {
      icon    : 'warning',
      message : '권한을 요청하세요',
      desc    : '결과물을 개발하신 분께 권한을 문의해주세요.',
    },
    updateUserData: {
      icon    : 'warning',
      message : '다시 시도해주세요',
      desc    : '결과를 저장하지 못했습니다.',
    },
  },
  positive: {
    submitLearnFinish: {
      icon    : 'done',
      message : '수업을 완료하시겠습니까?',
      desc    : '',
    },
  },
}

const Auth = {
  error: {
    resetPasswordFailed: {
      icon    : 'warning',
      message : '비밀번호 재설정에 실패했습니다',
      desc    : '다시 시도해주세요.',
    },
    retry: {
      icon    : 'warning',
      message : '에러가 발생하였습니다',
      desc    : '다시 시도해주세요.',
    },
    modifyAvatar: {
      icon    : 'warning',
      message : '사진이 저장되지 않았습니다. 다시 시도해주세요',
      desc    : '다시 시도해주세요.',
    },
    invalidAccess: {
      icon    : 'xlLock',
      message : '잘못된 접근입니다',
      desc    : '이메일 인증 페이지로 이동합니다.',
    },
    sessionExpired: {
      icon    : 'warning',
      message : '재로그인이 필요합니다',
      desc    : '로그인 페이지로 이동합니다.',
    },
    duplicate: {
      icon    : 'warning',
      message : '이미 등록된 메일 혹은 전화번호가 존재합니다',
      desc    : '다른 정보를 입력해주세요.',
    },
  },
  info: {
    sentResetPasswordEmail: {
      icon    : 'mail',
      message : '비밀번호 재설정 메일이 발송되었습니다',
      desc    : '',
    },
    saveUserInfo: {
      icon    : 'smile',
      message : '입력정보를 저장하시겠습니까?',
      desc    : '',
    },
  },
  positive: {
    edit: {
      icon    : 'done',
      message : '회원정보가 변경되었습니다',
      desc    : '',
    },
    logout: {
      icon    : 'smile',
      message : '로그아웃이 완료되었습니다',
      desc    : '',
    },
    verification: {
      icon    : 'done',
      message : '인증 코드가 발송되었습니다',
      desc    : '',
    },
  },
}

export const AlertMessage = {
  common : Common,
  auth   : Auth,
  cart   : Cart,
  course : Course,
  payment: Payment,
} as const
