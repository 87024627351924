import React           from 'react'
import { useNavigate } from 'react-router-dom'

import Button                 from 'components/core/Button'
import WishListHeartIcon      from 'components/core/WishListHeartIcon';
import ShareIconButton        from 'components/core/ShareIconButton';
import { Course             } from 'pages/types'
import { PATH, createMarkup } from 'constant';
import css                    from './Banner.module.scss'

interface Props {
  course        : Course;
  wishListed    : boolean;
  setWishListed : () => void;
}

const CourseBanner = ({ course, wishListed, setWishListed }: Props) => {
  const navigate = useNavigate();
  const {
    coverImage,
    title,
    price,
    introduction,
  } = course

  const goToLatestChapter = () => {
    // If user has access to the course, navigate to the latest chapter user has accessed.
    // Otherwise, navigate to the first chapter which is usually free
    if (course.userAccessInfo) {
      navigate(PATH.COURSE.toLatestChapter(course.id));
    } else {
      navigate(PATH.COURSE.toChapter(course.id, 1));
    }
  }

  const CourseImage = () => (
    <div 
      className = {css.thumbnail}
      onClick   = {goToLatestChapter}
    >
      <img src={coverImage} alt={title} />
    </div>
  );

  const Title = () => (<h1 className={css.title} dangerouslySetInnerHTML={createMarkup(title)} />);
  const Price = () => (
    <div className="flex items-center justify-between">
      <p className={css.price}>
        {price === 0 ? '무료' : `${price.toLocaleString()}원`}
      </p>
      <p className="text-lg text-primary mb-6">
        총 {course.chapterInfo?.totalChapterCount}강
      </p>
    </div>
  );
  const Introduction = () => (
    <div>
      <p className={css.courseTitle}>{title}</p>
      <p className={css.introduction}>{introduction}</p>
    </div>
  );

  const StartButton = () => {
    return (<Button
      className={`${css.startBtn} hidden md:block`}
      size="full"
      width="100%"
      onClick={goToLatestChapter}
    >
      {"바로 시작하기"}
    </Button>);
  };

  return (
    <>
      <section className="w-full py-8 bg-bannerBG">
        <div className={`${css.contentContainer} px-4 md:px-[25px] lg:px-[49px]`}>
          <CourseImage />
          <section className={css.information}>
            <div>
              <Title />
            </div>
            <div>
              <Price       />
              <StartButton />
            </div>
            <Introduction />
            <div className="flex justify-center gap-[50px] py-6 pb-8 md:py-0 md:pr-0 md:pl-2.5">
              <WishListHeartIcon
                isWishListed = {wishListed}
                description  = "찜하기"
                onClick      = {() => setWishListed()}
              />
              <ShareIconButton
                description = "공유하기"
                course      = {course}
              />
            </div>
          </section>
        </div>
      </section>
    </>
  )
}

export default CourseBanner
