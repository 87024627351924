import React from 'react';
import css   from './BenefitAndAudience.module.scss';

export interface BulletListProps {
  benefits    : string[];
  suggestions : string[];
}

const BenefitAndAudience = ({ benefits, suggestions }: BulletListProps) => {

  return (
    <div className={css.container}>
      <section className={css.section}>
        <p className={css.sectionTitle}>베네핏</p>
        <p className={css.sectionSubtitle}>수업 후 이런 걸 얻게 돼요</p>
        <ol className={css.listWrapper}>
          {benefits.map(benefit => (
            <li key={benefit} className={css.benefit}>
              {benefit.trim()}
            </li>
          ))}
        </ol>
      </section>
      <section className={css.section}>
        <p className={css.sectionTitle}>학습 대상</p>
        <p className={css.sectionSubtitle}>이런 분들을 위한 클래스예요</p>
        {suggestions.map(suggestion => (
          <li key={suggestion} className={css.suggestion}>
            {suggestion.trim()}
          </li>
        ))}
      </section>
    </div>
  );
};

export default BenefitAndAudience;
