import React from 'react';

interface EditProfileFormData {
  [userName    : string] : string;
  phoneNumber  : string;
  email        : string;
  profileImage : string;
};

const useInputChangeValue = () => {
  const [formData, setFormData] = React.useState<EditProfileFormData>({
    name         : '',
    phoneNumber  : '',
    email        : '',
    profileImage : '',
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return { handleChange, formData, setFormData };
};

export default useInputChangeValue;
