import React, {useEffect          } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { User                 } from 'pages/types';
import { useUser              } from 'contexts/UserContext';
import { useModal, ModalTypes } from 'contexts/ModalContext';
import { PATH, AlertMessage   } from 'constant';
import { REDIRECT_URL         } from 'pages';

interface WithUserProps {
  user: User; 
}

export const withAuth = (Component: React.ComponentType<WithUserProps>) => {
  const AuthenticatedComponent = (props: WithUserProps) => {
    const user     = useUser();
    const modal    = useModal();
    const location = useLocation();
    const navigate = useNavigate();

    /***********************************************************************/
    /********************         Use Effects           ********************/
    /***********************************************************************/
    useEffect(() => {
      const redirectToSignUpIfNotAuthed = () => {
        if (!user?.isAuthenticated) {
          modal?.openModal(ModalTypes.ALERT, {
            type   : 'info',
            dialog : AlertMessage.common.warn.needLogin
          }).then(() => {
            const queryParams = new URLSearchParams();
            queryParams.set(REDIRECT_URL, location.pathname);

            navigate(`${PATH.SIGNUP.base}?${queryParams}`);
          });
        }
      };

      redirectToSignUpIfNotAuthed();
    }, [user?.isAuthenticated]); 

    /***********************************************************************/
    /********************         Components            ********************/
    /***********************************************************************/
    if (!user?.isAuthenticated || !user.user) {
      return null;
    }

    // If authenticated, render the passed component
    return <Component {...props} user={user.user} />;
  };

  return React.createElement(AuthenticatedComponent);
};
