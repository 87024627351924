import { useMutation, gql } from '@apollo/client'

const GET_APPROVAL_PAYMENTS = gql`
  mutation getApprovalPayments(
    $paymentKey    : String!
    $orderId       : String!
    $amount        : Float!
    $paymentMethod : PaymentMethod!
    $orderName     : String!
    $product       : ProductInfo
  ) {
    confirmPayment(
      paymentKey    : $paymentKey
      orderId       : $orderId
      amount        : $amount
      orderName     : $orderName
      paymentMethod : $paymentMethod
      product       : $product
    ) {
      orderId
      orderName
      amount
      paymentMethod
      status
      message
    }
  }
`;

const useGetApprovalPayment = () => {
  const [getApprovalPayment] = useMutation(
    GET_APPROVAL_PAYMENTS,
    {
      context: { endpoint: 'payment' }
    }
  );

  return getApprovalPayment;
}

export default useGetApprovalPayment;
