import React, {
  memo,
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  MouseEvent,
  forwardRef,
  LegacyRef,
} from 'react';
import { cn, cond } from 'utils/styles';
import css from './&Base.module.scss';

export type InputType = 'text' | 'password' | 'number' | 'email' | 'tel';
export type InputSize = 'small' | 'medium' | 'large';
export interface InputProps {
  value: string;
  type?: InputType;
  size?: InputSize;
  invalided?: boolean;
  className?: string;
  disabled?: boolean;
  name?: string;
  placeholder?: string;
  required?: boolean;
  autoFocus?: boolean;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: MouseEvent<HTMLInputElement>) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
}

/**
 * input html tag를 사용하시는 것 처럼 사용하시면 됩니다.
 * @example <Input type="text" value={string} onChange={handleXXXInputChanged} />
 *
 * 추가 styling을 하고 싶을 때(ex. margin)에는 해당 페이지 tsx파일 내에서 className을 추가, prop으로 넘겨주세요
 *
 * 2022. 9.22 -박윤국-
 */
const Input = forwardRef(
  (props: InputProps, ref: LegacyRef<HTMLInputElement>) => {
    const {
      type = 'text',
      className = '',
      size = 'medium',
      invalided = false,
      ...others
    } = props;
    return (
      <input
        data-testid="input"
        ref={ref}
        type={type}
        className={cn(
          css.input,
          cond(invalided, css.negative),
          css[size],
          className,
        )}
        autoComplete="off"
        {...others}
      />
    );
  },
);

Input.displayName = 'Input';

export default memo(Input);
