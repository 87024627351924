import { useQuery, gql, QueryHookOptions } from '@apollo/client';

const COURSE_QUERY = gql`
  query getCourse($id: Float!) {
    course(id: $id) {
      id
      title
      introduction
      description
      difficulty
      thumbnail
      coverImage
      price,
      userAccessInfo {
        status
        progress
        chapterProgress {
          chapterId
          status
        }
      }
      chapterInfo {
        totalChapterCount
        chapters {
          id
          sequence
          groupTitle
          title
          price
        }
      }
    }
  }
`

export const useCourseQuery = (courseId: number) => {
  const options = {
    variables   : { id: courseId       },
    context     : { endpoint: 'course' },
    fetchPolicy : 'network-only'
  };

  const {data, loading, error} = useQuery(COURSE_QUERY, options as QueryHookOptions);

  return {course: data?.course, loading, error};
};

