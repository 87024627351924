import React           from 'react';
import { useNavigate } from 'react-router';

import Button        from 'components/core/Button';
import Icon          from 'components/core/Icon/Icon';
import { BaseModal } from 'components/modal/BaseModal';
import { PATH      } from 'constant';
import css           from './UnfinishedCourseModal.module.scss';

interface Props {
  onClose : () => void;
  courseId: number;
}

const UnfinishedCourseModal = ({ onClose, courseId }: Props) => {
  const navigate = useNavigate();

  return (
    <BaseModal switchModal={onClose}>
      <div className={css.container}>
        <div className={css.iconBox}>
          <Icon name="notDone" />
        </div>
        <p className={css.title}>아직 코딩을 끝내지 않으셨네요.</p>
        <div className={css.content}>
          <p className={css.contentText}>코스를 마무리하고, 코드를 완성하면</p>
          <p className={css.contentText}>결과물을 확인하실 수 있습니다.</p>
        </div>
        <div className={css.btnBox}>
          <Button
            size    = "large"
            onClick = {() => navigate(PATH.COURSE.toCourse(courseId))}
          >
            이어하기
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};

export default UnfinishedCourseModal;
