import React         from 'react';
import { useParams } from 'react-router';

import Agreement from './Agreement';
import Privacy   from './Privacy';
import Refund    from './Refund';
import Site      from './Site';
import css       from './Term.module.scss';

const TermMap = {
  privacy   : <Privacy />,
  refund    : <Refund />,
  site      : <Site />,
  agreement : <Agreement />,
};

export const TermPage = () => {
  const { subject } = useParams();

  return (
    <div className={css.container}>
      {TermMap[subject as keyof typeof TermMap]}
    </div>
  );
};
