import { useEffect } from 'react';

import { 
  EditorEvent,
  Subscriber
} from './types';

export const useEditorEvent = (subscriber: Subscriber) => {
  useEffect(() => {
    editorEventPublisher.subscribe(subscriber);
    return () => editorEventPublisher.unsubscribe(subscriber);
  }, [subscriber]);
}

class EditorEventPublisher {
  private subscribers : Subscriber[];

  constructor() {
    this.subscribers = [];
  };

  publish(events: EditorEvent[]): void {
    this.subscribers.forEach(subscriber => subscriber(events));
  };

  subscribe(subscriber: Subscriber) {
    this.subscribers.push(subscriber);
  }

  unsubscribe(subscriber: Subscriber) {
    this.subscribers = this.subscribers.filter(s => s !== subscriber);
  }
}

export const editorEventPublisher = new EditorEventPublisher();
