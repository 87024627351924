import { useQuery, gql, QueryHookOptions } from '@apollo/client';

const CHAPTER_INFO_QUERY = gql`
  query getChapterInfo($courseId: Float!, $sequence: Float!) {
    chapterInfo(courseId: $courseId, sequence: $sequence) {
      id
      title
      price
      sequence
      courseId
      courseTitle
      thumbnail
    }
  }
`

const useChapterInfoQuery = (courseId: number, sequence: number) => {
  const options = {
    variables   : {courseId: courseId, sequence: sequence},
    context     : {endpoint: 'course'},
    fetchPolicy : 'network-only'
  };

  const {data, loading, error} = useQuery(CHAPTER_INFO_QUERY, options as QueryHookOptions);

  return {chapter: data?.chapterInfo, loading, error};
};

export default useChapterInfoQuery;
