import React, { memo } from 'react';

import { Validations } from 'constant/validation';
import { cn, cond    } from 'utils/styles';
import { InputProps  } from './&Base';
import { Input       } from '..';
import errorIcon       from 'assets/common/error_icon.svg';
import css             from './&Label.module.scss';

export type RuleType =
  | 'required'
  | 'minLength8'
  | 'atLeast1Letter1Number'
  | 'email'
  | 'password'
  | 'onlyKorean'
  | 'onlyPositiveNumber'
  | 'length11'
  | 'onlyNumber'
  | 'phoneNumber'
  | 'name';

interface LabelInputProps extends InputProps {
  label?     : string;
  matchWith? : string;
  helpText?  : string;
  rules?     : RuleType[];
}

const LabeledInput = (props: LabelInputProps) => {
  const {
    value,
    required,
    helpText,
    matchWith,
    rules     = [],
    label     = '',
    className = '',
    ...others
  } = props;

  const showErrorMessage      = rules.length !== 0 && value.length > 0;
  const showHelpText          = helpText && !showErrorMessage;
  const showMatchErrorMessage = matchWith && value !== matchWith;
  const isAllValid            = !showMatchErrorMessage && rules.every(rule => Validations[rule].rule(value));

  return (
    <div className={cn(css.labeledInput, className)}>
      <label className={cn(css.label, cond(props.disabled ?? false, css.disabled))} >
        {label}
        <Input
          value     = {value}
          required  = {required}
          invalided = {showErrorMessage && !isAllValid}
          size      = "large"
          {...others}
        />
      </label>
      {showHelpText && <p className={css.helpText}>{helpText}</p>}
      {showErrorMessage &&
        Object.entries(Validations)
          .filter(([key]) => rules.includes(key as RuleType))
          .map(([key, validation]) => {
            const isValid = validation.rule(value);
            return (
              <div
                key={key}
                className={cn(css.errorWrapper, isValid ? css.hide : css.show)}
              >
                <img className={css.errorImage} alt="error" src={errorIcon} />
                <p className={css.errorMessage}>{validation.message}</p>
              </div>
            );
          })}
      {showMatchErrorMessage && (
        <div className={cn(css.errorWrapper, css.show)}>
          <img className={css.errorImage} alt="error" src={errorIcon} />
          <p className={css.errorMessage}>비밀번호가 일치하지 않습니다.</p>
        </div>
      )}
    </div>
  );
};

export default memo(LabeledInput);
