import { gql, useQuery, QueryHookOptions  } from '@apollo/client';

const CHAPTER_ACCESS_INFO_QUERY = gql`
  query chapterAccessInfo($courseId: Float!) {
    course(id : $courseId) {
      userAccessInfo {
        chapterProgress {
          chapterId
          status
        }
      }
    }
  }
`;

type Props = {
  courseId : number | string;
}

export const useChapterAccessInfoQuery = ({courseId}: Props) => {
  const query     = CHAPTER_ACCESS_INFO_QUERY;
  const variables = { courseId: Number(courseId) };
  const options   = {
    variables   : variables,
    context     : { endpoint: 'course' },
    fetchPolicy : 'network-only',
  }
  
  const { data, loading, error } = useQuery(query, options as QueryHookOptions);
  const chapterProgress          = data?.course?.userAccessInfo?.chapterProgress
    .reduce((acc: any, chapter: any) => {
      acc[chapter.chapterId] = chapter.status;
      return acc;
    }, {});

  const chapterStatus = chapterProgress || {};
  const authorized    = !!chapterProgress;

  return { 
    chapterStatus,
    authorized,
    loading, 
    error
  };
};
