import React         from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';

type Props = {
  onClose: () => void;
};

const CircledXButton = ({ onClose }: Props) => {
  return (
    <button 
      className={`
        absolute -top-2 -right-2 p-1
        rounded-full shadow-md
        text-lightprimary hover:text-blue-700 
        border border-blue-300 hover:border-lightprimary 
        bg-white transition-colors duration-200
      `}
      onClick={onClose}
    >
      <XMarkIcon className="h-4 w-4" aria-hidden="true" />
    </button>
  );
};

export default CircledXButton;
