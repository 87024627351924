import React           from "react";

import RunCodeButton  from './RunCodeButton';
import CopyCodeButton from './CopyCodeButton';
import CodeEditButton from "./CodeEditButton";

type Props = {
  isMobile       : boolean;
  showEditor     : boolean;
  setShowEditor  : (showEditor : boolean) => void;
  showPreview    : boolean;
  setShowPreview : (showPreview : boolean) => void;
  videoEnded     : boolean;
};

const EditorController = ({
  isMobile,
  showEditor,
  setShowEditor,
  showPreview, 
  setShowPreview,
  videoEnded,
}: Props) => {

  return (
    <div className ={`
      ${(isMobile && !videoEnded) ? `hidden` : `flex`} 
      flex items-center justify-between gap-2 m-2 px-3 md:px-2 lg:px-0
    `}>
      <div className = {`flex justify-start items-center gap-2`}>
        <CodeEditButton 
          isMobile      = {isMobile      }
          showPreview   = {showPreview   }
          showEditor    = {showEditor    } 
          setShowEditor = {setShowEditor } 
          videoEnded    = {videoEnded    } 
        />
        <CopyCodeButton showPreview = {showPreview} />
      </div>
      <RunCodeButton 
        isMobile       = {isMobile       }
        videoEnded     = {videoEnded     }
        showPreview    = {showPreview    } 
        setShowPreview = {setShowPreview } 
      />
    </div>
  );
};

export default EditorController;
