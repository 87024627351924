import React                    from 'react';
import ReactMarkdown            from 'react-markdown';
import rehypeRaw                from 'rehype-raw';
import customMarkdownComponents from './customMarkdownComponents';
import css                      from './MarkdownContent.module.scss';

interface Props {
  content    : string;
  className? : string;
}

const MarkdownContent = ({ content, className }: Props) => {
  return (
    <ReactMarkdown
      className     = {`h-full w-full ${css.markdownStyle} ${className ? className : ''}`}
      components    = {customMarkdownComponents}
      rehypePlugins = {[rehypeRaw as any]}
    >
      {content}
    </ReactMarkdown>
  )
};

export default MarkdownContent;
