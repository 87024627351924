import React                              from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import Button                    from 'components/core/Button';
import WecodeLogo                from 'components/core/Icon/Logo/Wecode';
import WecondSymbol              from 'assets/wecode_symbol_b@2x.png';
import hamburgerMenu             from 'assets/common/ic_hamburger.png';
import { navigateToKakaoAuth   } from 'utils/auth';
import { REDIRECT_URL          } from 'pages';
import { PATH                  } from 'constant';
import { defaultContainerWidth } from 'styles/customTailwindCSS';
import { useUser               } from 'contexts/UserContext';
import { useModal, ModalTypes  } from 'contexts/ModalContext';
import Profile                   from './Profile';

const GNB_ITEMS = [
  { id : 1, path : PATH.HOME.base, content       : '홈'                          } ,
  { id : 3, path : PATH.MYPAGE.myCourse, content : '내 강의', shouldLogin : true } ,
];

const GNB = () => {
  const modal    = useModal();
  const user     = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  const goToSignUp = () => {
    const queryParams = new URLSearchParams();
    queryParams.set(REDIRECT_URL, location.pathname);
    navigate(`${PATH.SIGNUP.base}?${queryParams}`);
  };

  const openHamburgerMenu = () => {
    modal?.openModal(ModalTypes.HAMBURGER_MENU, { items: GNB_ITEMS });
  };

  const Header = ({children}: {children: React.ReactNode}) => {
    return (
      <header className = "fixed z-50 top-0 right-0 left-0 border-b border-gray-200 bg-white shadow-md">
        {children}
      </header>
    );
  };

  const Nav = ({children}: {children: React.ReactNode}) => {
    return (
      <nav className = {`
        ${defaultContainerWidth} 
        flex justify-between items-center 
        mx-auto py-3
      `}>
        {children}
      </nav>
    );
  };

  const Logo = () => {
    return (
      <Link to={PATH.HOME.base}>
        <div className="hidden md:block mr-10">
          <WecodeLogo />
        </div>
        <div className="md:hidden relative w-8 h-8 cursor-pointer">
            <img src= {WecondSymbol} className="rounded-full object-cover" alt="Wecode" />
        </div>
      </Link>
    );
  }

  const LeftMenu = () => {
    return (
      <div className="hidden md:block flex space-x-8">
        {GNB_ITEMS.map(({ id, path, content, shouldLogin }) => {
          if (!user?.isAuthenticated && shouldLogin) return null;
          return (
            <Link key={id} to={path} className="text-black">
              {content}
            </Link>
          );
        })}
      </div>
    );
  };

  const HamburgerMenu = () => {
    return (
      <div className="block md:hidden cursor-pointer" onClick={openHamburgerMenu}>
        <img src={hamburgerMenu} alt="menu button" className="w-6 h-6"/>
      </div>
    );
  }

  const LoginButton = () => {
    return (
      <Button variant="lineBase" onClick={() => {navigateToKakaoAuth(location.pathname);}}>
        로그인
      </Button>
    );
  };

  const SignUpButton = () => {
    return (
      <Button onClick={goToSignUp}>회원가입</Button>
    );
  };

  return (
    <>
      <Header>
        <Nav>
          <div className ="flex">
            <Logo />
            <LeftMenu />
          </div>
          <div className = "hidden md:flex items-center">
            {user?.isAuthenticated ? (
              <Profile />
            ) : (
              <div className="flex gap-2">
                <LoginButton />
                <SignUpButton />
              </div>
            )}
          </div>
          <HamburgerMenu />
        </Nav>
      </Header> 
      <div className="h-[56px] md:h-62" />
    </>
  );
};

export default GNB;

