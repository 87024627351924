import { ReactNode } from 'react'
import ReactDOM      from 'react-dom'

interface ModalPortalProps {
  children: ReactNode
}

const ModalPortal = ({ children }: ModalPortalProps) =>
  ReactDOM.createPortal(
    children,
    document.getElementById('modal-root') as HTMLElement,
  )

export default ModalPortal;
