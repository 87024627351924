import { gql, useQuery } from '@apollo/client';

const GET_ORDER_QUERY = gql`
  query order($id: String!) {
    order(id: $id) {
      id
      name
      totalPrice
      status
      paymentMethod
      createdAt
      items {
        id
        name
        price
        createdAt
        productInfo {
          __typename
          ... on Chapter {
            id
            courseId
            sequence
            title
            price
            coverImage
            thumbnail
          }

          ... on Course {
            id
            title
            coursePrice
            coverImage
            thumbnail
          }

          ... on Wecoin {
            amount
            rewardPoints
          }
        }
      }
    }
  }
`;

type Props = {
  orderId     : string;
  onCompleted?: (order: any ) => void;
  onError?    : (error: any ) => void;
};

const useOrderDetailQuery = ({orderId, onCompleted, onError}: Props) => {
  const { data, loading, error } = useQuery(
    GET_ORDER_QUERY, 
    {
      variables   : { id: orderId },
      context     : { endpoint : 'payment' },
      fetchPolicy : 'network-only',
      onCompleted : ({ order }) => { onCompleted && onCompleted(order) },
      onError     : (error)     => { onError && onError(error) },
    }
  );

  return { order: data?.order, loading, error };
};

export default useOrderDetailQuery;
