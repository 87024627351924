import React from 'react';
import type { 
  ReactBaseProps,
  ReactMarkdownProps 
} from 'react-markdown/src/ast-to-react';

import WecodeEditor                from 'components/core/WecodeEditor';
import Layout, { LayoutType      } from 'components/core/Layout';
import VideoPlayer, { VideoProps } from 'components/core/VideoPlayer';
import CurriculumTable             from 'components/core/CurriculumTable';
import BulletList                  from 'components/core/BulletList';
import VisualCodeMentor            from 'components/core/VisualCodeMentor';
import { isValidURL  }             from 'utils/validation';
import CodeHighlighter             from './CodeHighlighter';
import BenefitAndAudience          from './BenefitAndAudience';
import ArrowBullet                 from './ArrowBullet';
import Callout                     from './Callout';
import CheckBulletBox              from './CheckBulletBox';
import Columns                     from './Columns';
import Introduction                from './Introduction';
import LinkhavingComment           from './LinkHavingComment';
import LinkToSubContentBox         from './LinkToSubContentBox';
import Reference                   from './Reference';
import Highlight                   from './Highlight';
import ImageCard                   from './ImageCard';
import Divider                     from './Divider';

type MarkdownNode     = ReactMarkdownProps & ReactBaseProps;
type MarkdownCodeNode = MarkdownNode       & { inline?: boolean };
type CodeEditorNode   = MarkdownNode       & { data: any };
type VideoNode        = MarkdownNode       & VideoProps & { mobileurl: string, data?: string };
type DataNode         = MarkdownNode       & { data: string };
type Size             = 'small' | 'medium' | 'large' | 'full';

interface LayoutNode       extends MarkdownNode     { layout: LayoutType;                      };
interface BenefitNode      extends MarkdownNode     { benefits: string; suggestions: string    };
interface IntroductionNode extends MarkdownNode     { text: string; subtext: string;           };
interface SubContentNode   extends MarkdownNode     { content: string;                         };
interface CommentingNode   extends MarkdownNode     { href: string; comment : string;          };
interface LinkedNode       extends MarkdownNode     { href?: string;                           };
interface ImageCardNode    extends MarkdownNode     { src: string, alt?: string, size?: Size   };
interface CustomCodeNode   extends MarkdownCodeNode { 
  color  : string;
  emoji  : string;
  number : number;
};

const getLayout = ({ layout, children } : LayoutNode) => {
  return (
      <Layout layout={layout}>{children}</Layout>
  );
};

const getCodeEdiotr = ({ data } : CodeEditorNode) => {
  return <WecodeEditor initialFiles={data ? JSON.parse(data) : {}} />;
};

const getImageCard = ({src, ...props}: ImageCardNode) => {
  return <ImageCard src={src} {...props} />
};

const getDivider = ({...props}: MarkdownNode) => {
  return <Divider {...props} />;
}

const getCodeHighlighter = ({ inline, className, children, ...props }: MarkdownCodeNode) => {
  return CodeHighlighter({ inline, className, children, props });
};

const getHighlighter = ({ children, ...props }: MarkdownNode) => {
  return <Highlight {...props}>{children}</Highlight>;
};

const getLinkToSubContentBox = ({ href, children, ...props }: LinkedNode)  => {
    const isLinkToBtn = href && !isValidURL(href) && href.indexOf('/session/') !== -1;

    return href && isLinkToBtn ? (
      <LinkToSubContentBox link={href} isReference {...props}>
        {children}
      </LinkToSubContentBox>
    ) : (
      <a
        className = "reference"
        href      = {href}
        target    = "_blank"
        rel       = "noreferrer"
        {...props}
      >
        {children}
      </a>
    );
};

const getSubContent = ({ content, children, ...props }: SubContentNode) => {
  return (
    <LinkToSubContentBox link={content} isReference={false} {...props}>
      {children}
    </LinkToSubContentBox>
  );
};

const getCommenting = ({ children, comment, ...props }: CommentingNode) => {
  return (
    <LinkhavingComment comment={comment} {...props}>
      {children}
    </LinkhavingComment>
  );
};

const getBackground = ({ children, ...props }: MarkdownNode) => {
  const style = { 
    backgroundColor : props.backgroundcolor ?? '#f1f5f9',
    textAlign       : props.textalign       ?? 'left',
    color           : props.color           ?? 'black',
    padding         : '10px 2px',
  } as React.CSSProperties;

  return (
    <p
      style={style}
      {...props}
    >
      {children}
    </p>
  );
};

const getColor = ({ children, color, ...props }: CustomCodeNode) => {
  return (
    <span style={{ color: color }} {...props}>
      {children}
    </span>
  );
};

const getCalloout = ({ children, emoji, ...props }: CustomCodeNode) => {
  return (
    <Callout content={children} emoji={emoji ? emoji : '💡'} {...props} />
  );
};

const getColums = ({ children, number, ...props }: CustomCodeNode) => {
  return (
    <Columns number={number} {...props}>
      {children}
    </Columns>
  );
};

const getIndentation = ({ children, ...props }: MarkdownNode) => {
  return (
    <span style={{ display: 'block', marginLeft: '20px' }} {...props}>
      {children}
    </span>
  );
};

const getReference = ({ children, ...props }: MarkdownNode) => {
  return <Reference {...props}>{children}</Reference>;
};

const getArrowBullet = ({ children, ...props }: MarkdownNode) => {
  return (
    <ArrowBullet {...props}>{children}</ArrowBullet>
  );
};

const getCheckBulletBox = ({ children, content, ...props }: SubContentNode) => {
  return (
    <CheckBulletBox title={content} {...props}>
      {children}
    </CheckBulletBox>
  );
};

const getIntroduction = ({ text, subtext }: IntroductionNode) => {
  return <Introduction text={text} subtext={subtext} />;
};

const getVideoPlayer = ({ url, orientation, width, height }: VideoNode) => { 
  return <VideoPlayer 
    url         = {url}
    orientation = {orientation}
    width       = {width}
    height      = {height}
  />; 
};

const getVideoCodeEditor = ({ url, mobileurl, orientation, height, data }: VideoNode) => {
  return <VisualCodeMentor 
    url         = {url}
    mobileUrl   = {mobileurl}
    orientation = {orientation}
    height      = {height}
    data        = {data ? JSON.parse(data) : {}}
  />; 
};

const getBenefitAndAudience = ({ benefits, suggestions }: BenefitNode) => {
  const parsedBenefits    = benefits.split(',');
  const parsedSuggestions = suggestions.split(',');

  return (
    <BenefitAndAudience
      benefits    = {parsedBenefits}
      suggestions = {parsedSuggestions}
    />
  );
};

const getBulletList = ({ children, ...props }: MarkdownNode) => {
  return <BulletList {...props}>{children}</BulletList>;
}

const getCurriculumTable = ({children, ...props}: DataNode) => {
  return null;
  //return <CurriculumTable sections={children ? JSON.parse(String(children)) :  {}} />
}

const customMarkdownComponents = {
  a                  : (node: LinkedNode)        => { return getLinkToSubContentBox(node); },
  layout             : (node: LayoutNode)        => { return getLayout(node);              },
  codeeditor         : (node: CodeEditorNode)    => { return getCodeEdiotr(node);          },
  code               : (node: MarkdownCodeNode)  => { return getCodeHighlighter(node);     },
  highlight          : (node: MarkdownNode)      => { return getHighlighter(node);         },
  subcontent         : (node: SubContentNode)    => { return getSubContent(node);          },
  commenting         : (node: CommentingNode)    => { return getCommenting(node);          },
  background         : (node: MarkdownNode)      => { return getBackground(node);          },
  color              : (node: CustomCodeNode)    => { return getColor(node);               },
  callout            : (node: CustomCodeNode)    => { return getCalloout(node);            },
  columns            : (node: CustomCodeNode)    => { return getColums(node);              },
  indentation        : (node: MarkdownNode)      => { return getIndentation(node);         },
  reference          : (node: MarkdownNode)      => { return getReference(node)            },
  arrowbullet        : (node: MarkdownNode)      => { return getArrowBullet(node);         },
  checkbulletbox     : (node: SubContentNode)    => { return getCheckBulletBox(node);      },
  introduction       : (node: IntroductionNode)  => { return getIntroduction(node);        },
  videoplayer        : (node: VideoNode)         => { return getVideoPlayer(node);         },
  visualcodementor   : (node: VideoNode)         => { return getVideoCodeEditor(node);     },
  imagecard          : (node: ImageCardNode)     => { return getImageCard(node)            },
  divider            : (node: MarkdownNode)      => { return getDivider(node)              },
  collapsiblelist    : (node: DataNode)          => { return getCurriculumTable(node)      },
  benefitandaudience : (node: BenefitNode)       => { return getBenefitAndAudience(node)   },
  bulletlist         : (node: MarkdownNode)      => { return getBulletList(node)           },
};

export default customMarkdownComponents;
