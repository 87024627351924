import React           from 'react';
import { useNavigate } from 'react-router-dom';

import { ErrorPage              } from 'pages';
import { Payment                } from 'pages/types';
import { AlertMessage, PATH     } from 'constant';
import { useModal, ModalTypes   } from 'contexts/ModalContext';
import { usePaymentHistoryQuery } from './hooks';
import NoContent                  from '../NoContent';

const PaymentHistory = () => {
  const modal    = useModal();
  const navigate = useNavigate();

  const { paymentHistory, loading, error } = usePaymentHistoryQuery({
    onError: () => {
      modal?.openModal(ModalTypes.ALERT, {
        type             : 'error',
        dialog           : AlertMessage.common.error.unknown,
        hasHelpEmailInfo : true,
      });
    },
  });

  if (error                     ) return <ErrorPage />;
  if (loading || !paymentHistory) return null;

  const Header = () => {
    const textSize = 'text-sm sm:text-base';

    return (
      <div className="flex justify-start py-4 border-b border-dotted font-semibold bg-gray">
        <div className={`${textSize} pl-4 w-[15%] text-left`}>결제일</div>
        <div className={`${textSize} w-[45%] text-left`}>결제 내역</div>
        <div className={`${textSize} w-[20%] text-right`}>결제 금액</div>
        <div className={`${textSize} w-[10%] text-right`}>결제 수단</div>
      </div>
    );
  };

  const PaymentList = () => {
    return (
      <div className="mb-4">
        {paymentHistory.map((payment, index) => (
          <div 
            key       = {index}
            className = "border-b border-dotted"
            onClick   = {() => navigate(PATH.MYPAGE.toPaymentDetail(payment.orderId))}
          >
            <PaymentInfo
              key     = {payment.orderId}
              payment = {payment}
            />
          </div>
        ))}
      </div>
    );
  };

  const PaymentInfo = ({payment}: {payment: Payment}) => {
    const totalAmount    = Intl.NumberFormat('ko-KR').format(payment.amount);
    const purchasedDate  = new Date(payment.approvedAt.replace('Z','')).toLocaleDateString('ko-KR');
    const textSize       = 'text-sm sm:text-base';
    const HOVER_BG_COLOR = 'hover:bg-gray-50 rounded-md';
    const paymentMethod  = {
      CARD     : '신용카드',
      EASY_PAY : '간편결제',
      FREE     : '무료',
      WECOIN   : 'Wecoin',
    }[payment.paymentMethod];

    return (
      <div className={`${HOVER_BG_COLOR} flex justify-between py-4 cursor-pointer`}>
        <div className={`${textSize} text-gray-400 pl-4 w-[15%] text-left`}>{purchasedDate}</div>
        <div className={`${textSize} w-[45%] text-left truncate`}>{payment.orderName}</div>
        <div className={`${textSize} w-[20%] text-primary text-right`}>{totalAmount}원</div>
        <div className={`${textSize} w-[10%] text-right`}>{paymentMethod}</div>
        <div className={`${textSize} w-[10%] text-gray-300 text-center`}>{'>'}</div>
      </div>
    );
  };


  if (paymentHistory.length === 0) return (
    <NoContent
      icon    = "emptyPurchased"
      title   = "아직 결제하신 내역이 없네요 😅"
      subText = "Wecoin 충전하고 리워드 포인트의 혜택을 받으세요! 💯"
      btnText = "Wecoin 충전하기"
      onClick = {() => navigate(PATH.PAYMENT.wecoin)}
      width   = "96px"
    />
  );

  return (
    <div className="w-full mx-auto pl-2">
      <Header />
      <PaymentList />
    </div>
  );
}

export default PaymentHistory;
