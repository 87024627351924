import React, { useState } from 'react';

import Icon, { IconName       } from 'components/core/Icon/Icon';
import { AlertDialog          } from './Alert';
import { AlertMessage         } from 'constant';
import { useModal, ModalTypes } from 'contexts/ModalContext';
import { copyToClipboard      } from 'utils/share';
import css                      from './Alert.module.scss';

const AlertBody = ({
  dialog,
  message,
  hasHelpEmailInfo,
}: {
  dialog            : AlertDialog;
  message?          : string;
  hasHelpEmailInfo? : boolean;
}) => {
  const modal                                  = useModal();
  const [isCopied, setIsCopied]                = useState(false);
  const { icon, message: dialogMessage, desc } = dialog;

  const handleCopy = () => {
    copyToClipboard({
      onCompleted: () => {  
        modal?.openModal(ModalTypes.ALERT, {
          dialog : AlertMessage.common.info.copiedUrl,
        })
      },
      onError: (_) => {
        modal?.openModal(ModalTypes.ALERT, {
          type   : 'error',
          dialog : AlertMessage.common.error.copiedError,
        });
      }
    }).then(() => setIsCopied(true));
  }


  return (
    <div className={css.content}>
      {icon && <Icon name={icon as IconName} />}
      <strong className={css.message}>
        {message ? message : dialogMessage}
      </strong>
      {desc && <p className={css.desc}>{desc}</p>}
      {hasHelpEmailInfo && (
        <div className={css.helpEmailInfo}>
          <p className={css.email}>
            <Icon name="helpEmail" />
            <span
              className = {css.emailText}
              onClick   = {() => handleCopy()}
            >
              {helpEmail}
            </span>
            {isCopied && (
              <>
                <Icon name="checkCircleLine" />
                복사 완료
              </>
            )}
          </p>
          <p className={css.emailGuide}>문제가 반복되면 이메일로 알려주세요.</p>
        </div>
      )}
    </div>
  );
};

export default AlertBody;

const helpEmail = 'help@gracefulrain.co';
