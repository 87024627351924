import React from "react";

type Props = {
  course: {
    thumbnail : string;
    title     : string;
  };
};

const Thumbnail = ({ course }: Props) => {
  return (
    <div className="w-[30%] h-[74px]" >
      <img 
        className = "rounded-md object-cover w-full h-full"
        src       = {course?.thumbnail}
        alt       = {course?.title}
      />
    </div>
  );
};

export default Thumbnail;
