import React from 'react';

type RadioButtonProps = {
  value      : string;
  name       : string;
  checked    : boolean;
  onChange   : (e: React.ChangeEvent<HTMLInputElement>) => void;
  className? : string | undefined;
};

const RadioButton = ({ value, name, checked, onChange, className }: RadioButtonProps) => {
  return (
    <input
      type      = "radio"
      name      = {name}
      value     = {value}
      checked   = {checked}
      onChange  = {onChange}
      className = {`form-radio text-primary focus:ring-lightprimary mr-2 ${className ? className : ''}`}

    /> 
  );
};

export default RadioButton;
