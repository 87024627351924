import React, { 
  useMemo,
  useEffect,
  useLayoutEffect
} from 'react';
import { autocompletion } from "@codemirror/autocomplete";
import { EditorView     } from "@codemirror/view";
import {
  FileTabs,
  SandpackCodeEditor,
  SandpackPreview,
  useSandpack,
  SandpackCodeViewer,
  CodeEditorRef,
} from '@codesandbox/sandpack-react';

import { editorEventPublisher } from '../EditorEventPublisher';
import { EditorEventType      } from '../types';
import css                      from '../WecodeEditor.module.scss';
import { 
  Transaction,
  TransactionType,
  Highlight,
  Annotation,
  Decorator,
  Decorators,
  Files,
  ActiveFile
} from '../types';

type Props = {
  isMobile      : boolean;
  files         : Files;
  transactions? : Transaction[];
  showPreview   : boolean;
  showEditor    : boolean;
};

const CodeEditor = ({
  isMobile,
  files,
  showPreview,
  showEditor,
  transactions = []
}: Props) => {
  const [activeDecorators, setActiveDecorators ] = React.useState<Decorator[] >([]);

  const { sandpack }       = useSandpack();
  const decorators         = React.useRef<Decorators>({});
  const codemirrorInstance = React.useRef<CodeEditorRef>(null);
  const codeViewerRef      = React.useRef<HTMLDivElement>(null);

  /***********************************************************************/
  /*****                      Functions                              *****/
  /***********************************************************************/
  /**
   * Set the active file in the editor
   *
   * @param transaction
   */
  const setActiveFile = (transaction: Transaction) => {
    const activeFile = ((transaction.payload as ActiveFile).activeFile).replace('/', '');
    sandpack.setActiveFile(`/${activeFile}`);
  }

  /**
   * Insert text at the specified line and column in the editor
   *
   * @param transaction
   */
  const insertText = (transction: Transaction) => {
    const { insertText, line, column, newLine } = transction.payload;
    const file                                  = transction.payload.file.replace('/', '');
    const currentText                           = sandpack.files[`/${file}`].code;
    const lines                                 = currentText.split("\n");

    if (line < 1 || line > lines.length + 1) {
      console.error(`Invalid line number to insert text ${insertText} at the line ${line} of\n`, currentText);
      return;
    }

    if (newLine) {
      lines.splice(line, 0, insertText); // Insert new line
    } else {
      const targetLine = lines[line - 1];
      lines[line - 1] = targetLine.slice(0, column) + insertText + targetLine.slice(column);
    }

    const newText = lines.join("\n");
    sandpack.updateFile(`/${file}`, newText);
  };

  /**
   * Get the highlights for the code editor
   *
   * @param transaction
   * @param acc
   */
  const getHighlights = (transaction: Transaction, acc: Decorators) => {
    const file           = transaction.payload.file.replace('/', '');
    const codeHighlights = (transaction.payload as Highlight);
    const highlights     = codeHighlights?.highlights.map(({line}) => {
      return { line: line, className: css.codeHighlight };
    });

    file in acc ? acc[file].push(...highlights) : acc[file] = highlights;
    return acc;
  }

  /**
   * Clear the highlights from the code editor
   *
   * @param transaction
   * @param acc
   */
  const clearHighlights = (transaction: Transaction, acc: Decorators) => {
    const file              = transaction.payload.file.replace('/', '');
    const lines             = transaction.payload.lines ?? [];
    const currentDecorators = decorators.current[file];

    if (currentDecorators) {
      decorators.current[file] = currentDecorators.filter(decorator => 
        decorator.className !== css.codeHighlight || 
        !lines.includes(decorator.line)
      );
    }

    return acc;
  }; 

  /**
   * Get the annotations for the code editor
   *
   * @param transaction
   * @param acc
   */
  const getAnnotations = (transaction: Transaction, acc: Decorators) => {
    const file            = transaction.payload.file.replace('/', '');
    const codeAnnotations = (transaction.payload as Annotation);
    const annotations     = codeAnnotations?.annotations.map(({
      dataId, 
      line, 
      startColumn, 
      endColumn
    }) => {
      return {
        className        : css.codeAnnotation,
        line             : line,
        startColumn      : (startColumn || 0),
        endColumn        : (endColumn   || 0) + 1, // Add 1 to endColumn for the data-id
        elementAttributes: {'data-id': dataId},
      };
    });

    file in acc ? acc[file].push(...annotations) : acc[file] = annotations;
    return acc;
  }

  /**
   * Clear the annotations from the code editor
   *
   * @param transaction
   * @param acc
   */
  const clearAnnotations = (transaction: Transaction, acc: Decorators) => {
    const file              = transaction.payload.file.replace('/', '');
    const dataIds: number[] = transaction.payload.dataIds ?? [];
    const currentDecorators = decorators.current[file];

    if (currentDecorators) {
      decorators.current[file] = currentDecorators.filter(decorator => 
        decorator.className !== css.codeAnnotation || 
        !dataIds.includes(Number(decorator.elementAttributes?.['data-id']))
      );
    }

    return acc;
  }; 

  /**
   * Handles the code update transaction.
   * This method is called before any other transaction is processed
   * if there are any code updates to be performed,
   * since updating the code will reset the decorators.
   */
  const updateCode_1 = () => {
    decorators.current = {};
    setActiveDecorators([]);
    sandpack.updateFile(files);
  }

  /**
   * Handle the editor actions such as
   * - Set active file
   * - Insert code
   * - Scroll code
   *
   * This method is called after the code update transaction
   * if there are any editor actions to be performed.
   *
   * @param transactions
   */
  const handleEditorActions_2 = (transactions: Transaction[]) => {
    transactions.forEach( transaction => {
      switch (transaction.type) {
        case TransactionType.SET_ACTIVE_FILE  : { setActiveFile(transaction); break; };
        case TransactionType.INSERT_CODE      : { insertText(transaction);    break; };
        case TransactionType.SCROLL_CODE      : { setTimeout(() => scrollCode(transaction), 500); break; };
        default                               : break;
      }
    });
  }

  /**
   * Update the decorators for the code editor such as
   * - Highlights
   * - Annotations
   * - Clear Highlights
   * - Clear Annotations
   *
   * This method is called after the editor actions are handled
   * if there are any decorators to be updated.
   *
   * @param transactions
   */
  const updateDecorators_3 = (transactions: Transaction[]) => {
    const decorations = transactions.reduce( (acc, transaction) => {
      switch (transaction.type) {
        case TransactionType.HIGHLIGHT        : return getHighlights(transaction, acc);
        case TransactionType.CLEAR_HIGHLIGHT  : return clearHighlights(transaction, acc);
        case TransactionType.ANNOTATION       : return getAnnotations(transaction, acc);
        case TransactionType.CLEAR_ANNOTATION : return clearAnnotations(transaction, acc);
        default                               : return acc;
      }
    }, {} as Decorators);

    mergeDecorators(decorations);
  }

  /**
   * Clear the decorators for the code editor such as
   * - Highlights
   * - Annotations
   * 
   * This method is called after the decorators are updated
   * if there are any decorators to be cleared.
   *
   * @param transactions
   */
  const clearDecorators_4 = (transactions: Transaction[]) => {
    const decorations = transactions.reduce( (acc, transaction) => {
      switch (transaction.type) {
        case TransactionType.CLEAR_HIGHLIGHT  : return clearHighlights(transaction, acc);
        case TransactionType.CLEAR_ANNOTATION : return clearAnnotations(transaction, acc);
        default                               : return acc;
      }
    }, {} as Decorators);

    mergeDecorators(decorations);
  };

  /**
   * Update the active decorators for the code editor
   * This method is called after the decorators are cleared
   * if there are any active decorators to be updated.
   */
  const updateActiveDecorators_4 = () => {
    const activeFile       = sandpack.activeFile.replace('/', '');
    const activeDecorators = decorators.current[activeFile] ?? [];
    setActiveDecorators(activeDecorators);
  };

  /**
   * Merge the decorators with the current decorators
   *
   * @param decorations
   */
  const mergeDecorators = (decorations: Decorators) => {
    for (const [key, value] of Object.entries(decorations)) {
      if ( key in decorators.current ) {
        decorators.current[key] = [...decorators.current[key], ...value];
      } else {
        decorators.current[key] = value;
      }
    }
  };

  /**
   * Handle the active file switch
   */
  const handleActiveFileSwitch = () => {
    const activeFile       = sandpack.activeFile.replace('/', '');
    const activeDecorators = decorators.current[activeFile] ?? [];
    setActiveDecorators(activeDecorators);
  };

  /**
   * Scroll the code to the specified line
   *
   * @param transaction
   */
  const scrollCode = (transaction: Transaction) => {
    const { line }   = transaction.payload;
    const editorView = codemirrorInstance.current?.getCodemirror();

    if (!editorView) return;

    // Get the state from the editor view
    const state = editorView.state;

    // Get the line object (1-based index)
    const lineObj = state.doc.line(line);

    // Calculate the target scroll position
    const scrollContainer = editorView.scrollDOM;

    // Assuming a fixed line height for calculation, or dynamically calculate if possible
    const lineHeight      = 20;
    const targetScrollTop = lineObj.number * lineHeight - scrollContainer.clientHeight / 2;

    smoothScrollTo(scrollContainer, targetScrollTop, 500); // 500ms duration

    //// Scroll to the desired line
    //editorView.dispatch({
    //  effects: EditorView.scrollIntoView(
    //    lineObj.from, 
    //    { y: "center" }
    //)});
  };

  /**
   * Smooth scroll to the target position
   *
   * @param element
   * @param target
   * @param duration
   */
  const smoothScrollTo = (element: HTMLElement, target: number, duration: number) => {
    const start    = element.scrollTop;
    const distance = target - start;

    let startTime: number | null = null;

    const easeInOutQuad = (t: number) => {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    };

    const animation = (currentTime: number) => {
      if (startTime === null) startTime = currentTime;

      const timeElapsed = currentTime - startTime;
      const progress    = Math.min(timeElapsed / duration, 1);
      const easing      = easeInOutQuad(progress);

      element.scrollTop = start + distance * easing;

      if (timeElapsed < duration) {
        requestAnimationFrame(animation);
      }
    };

    requestAnimationFrame(animation);
  };

  /***********************************************************************/
  /*****                      Use Effects                            *****/
  /**********> The order of the useEffects is important!  <***************/
  /***********************************************************************/
  useMemo        (() => {updateCode_1()                     } , [files]);
  useLayoutEffect(() => {handleEditorActions_2(transactions)} , [transactions]);
  useLayoutEffect(() => {updateDecorators_3(transactions)   } , [transactions]);
  useLayoutEffect(() => {clearDecorators_4(transactions)    } , [transactions]);
  useLayoutEffect(() => {updateActiveDecorators_4()         } , [transactions]);
  useMemo        (() => {handleActiveFileSwitch()           } , [sandpack.activeFile]);

  useEffect(() => {
    if (!showEditor) {
      const eventType = EditorEventType.EDITING_DONE;
      editorEventPublisher.publish([{ type: eventType }]);

      sandpack.resetAllFiles();
    }
  }, [showEditor]);

  /***********************************************************************/
  /*****                      Components                             *****/
  /***********************************************************************/
  const activeCode = sandpack.files[sandpack.activeFile].code;

  return !showPreview ? (
    <>
      <FileTabs className = {`${css.fileTabs} text-xs lg:text-sm`} />
      { showEditor ?
        <div className ={`
          flex-1 h-full
          ${isMobile  ? 'rounded-none' : 'rounded-[10px]'}
          rounded-tl-none
          overflow-auto scroll-smooth 
          text-xs md:text-sm
        `}>
          <SandpackCodeEditor
            wrapContent
            showLineNumbers  = {true}
            showInlineErrors = {true}
            showRunButton    = {false}
            showTabs         = {false}
            style            = {{ height: '100%' }}
            extensions       = {[autocompletion()]}
          />
        </div>: 
        <div 
          ref       = {codeViewerRef}
          className = {`
            ${css.codeViewerStyle} 
            flex-1 
            lg:rounded-b-lg lg:rounded-tr-lg
            h-5/6 relative
            overflow-auto scroll-smooth
            text-xs md:text-sm
          `}
        >
          <SandpackCodeViewer
            wrapContent
            showLineNumbers = {activeCode.length > 0}
            code            = {activeCode}
            ref             = {codemirrorInstance}
            showTabs        = {false}
            decorators      = {activeDecorators}
          />
        </div>
      }
    </>
  ) : 
    <SandpackPreview
      className             = {css.preview}
      showOpenInCodeSandbox = {false}
      showRefreshButton     = {false}
    />
};

export default CodeEditor;
