import React from 'react';

import Icon from 'components/core/Icon/Icon';

type Props = {
  isWishListed : boolean;
  onClick      : () => void;
  description? : string;
};

const WishListHeartIcon = ({ isWishListed, description, onClick }: Props) => {
  const hearFilled     = "scale-100 opacity-100 animate-bounceIn"
  const heartEmpty     = "opacity-100 scale-100"

  return (
    <div 
      className ="
        transition-transform transition-opacity duration-300 ease-in-out
        flex flex-col items-center cursor-pointer gap-1 relative
      "
      onClick   = {onClick}
    >
      <div className={`
        hover:text-blue-500 hover:scale-125
        ${isWishListed ? hearFilled : heartEmpty}
      `}>
        <Icon name={isWishListed ? 'heartFilled' : 'heart'} />
      </div>
      { description ? <p className="text-xs font-semibold">{description}</p> : null }
    </div>
  );
};

export default WishListHeartIcon;
