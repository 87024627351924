import React                        from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import UserProfile          from '../Content/UserProfile';
import Logout               from '../Content/Logout';
import MyCourseInfo         from '../Content/MyCourseInfo';
import PurchaseHistory      from '../Content/PurchaseHistory';
import PaymentHistory       from '../Content/PaymentHistory';
import PurchaseDetail       from '../Content/PurchaseDetail';
import PaymentDetail        from '../Content/PaymentDetail';
import WishList             from '../Content/WishList';
import { PATH }             from 'constant';
import { WecoinChargePage } from 'pages';

export const myPages = {
  [PATH.MYPAGE.userProfile] : {
    title   : '회원정보',
    content : <UserProfile  />,
  },
  [PATH.MYPAGE.wecoin]: {
    title   : '위코인 충전',
    content : <WecoinChargePage />,
  },
  [PATH.MYPAGE.myCourse] : {
    title   : '내 강의',
    content : <MyCourseInfo />,
  },
  [PATH.MYPAGE.favorite] :{
    title   : '찜한 강의',
    content : <WishList />,
  },
  [PATH.MYPAGE.purchaseHistory] : {
    title   : '구매 내역',
    content : <PurchaseHistory />,
  },
  [PATH.MYPAGE.purchaseDetail] : {
    title   : '구매 상세 정보',
    content : <PurchaseDetail />,
  },
  [PATH.MYPAGE.paymentHistory] : {
    title   : '충전 내역',
    content : <PaymentHistory />,
  },
  [PATH.MYPAGE.paymentDetail] : {
    title   : '충전 상세 정보',
    content : <PaymentDetail />,
  },
  [PATH.MYPAGE.logout] : {
    title   : '로그아웃',
    content : <Logout />,
  },
};

const Content = () => {
  const { pathname } = useLocation();
  const navigate     = useNavigate();

  const page = PATH.MYPAGE.isPurchaseDetail(pathname) 
    ? myPages[PATH.MYPAGE.purchaseDetail] 
    : (
      PATH.MYPAGE.isPaymentDetail(pathname)
        ? myPages[PATH.MYPAGE.paymentDetail]
        : myPages[pathname]
    )

  if (!page) { 
    navigate(PATH.NOTFOUND.notFound, { replace: true });
    return null;
  }

  return (
      <div className="flex flex-col w-full">
      <p className="md:hidden w-full text-left mt-4 text-base font-semibold">
        My Page
      </p>
      <h1 className="
        w-full 
        pt-2 pb-4 md:pt-4 md:pl-2
        border-b-2 border-black 
        text-xl font-black leading-1.2
      ">
        {page.title}
      </h1>
      <div className="w-full my-8">
        {page.content}
      </div>
    </div>
  );
}

export default Content;
