import { useMutation, gql } from '@apollo/client'

const CREATE_WECOIN_ORDER = gql`
  mutation createWecoinOrder(
    $amount: WecoinAmount!
  ) {
    createWecoinOrder(
      amount: $amount
    ) {
      id
      name
      totalPrice
      status
    }
  }
`;

const useCreateWecoinOrder = () => {
  const [createWecoinOrder] = useMutation(
    CREATE_WECOIN_ORDER,
    {
      context: { endpoint: 'payment' }
    }
  );

  return createWecoinOrder;
}

export default useCreateWecoinOrder;
