import React, { useEffect } from 'react'
import { useNavigate }      from 'react-router'

import { ErrorPage                                   } from 'pages';
import { AlertMessage, PATH                          } from 'constant';
import { useModal, ModalTypes                        } from 'contexts/ModalContext';
import { useWishListQuery, useDeleteWishListMutation } from './hooks';
import NoContent                                       from '../NoContent'
import MyPageCard                                      from '../MyPageCard'
import css                                             from './WishList.module.scss'

const WishList = () => {
  const modal    = useModal();
  const navigate = useNavigate()

  const { wishList, loading, fetchWishList, error: wishListFetchError } = useWishListQuery({
    onError: ()  => {
      modal?.openModal(ModalTypes.ALERT, {
        type             : 'error',
        dialog           : AlertMessage.common.error.unknown,
        hasHelpEmailInfo : true,
      })
    }
  })

  const { deleteWishList } = useDeleteWishListMutation({
    onCompleted : (_) => { fetchWishList(); },
    onError     : ()  => {
      modal?.openModal(ModalTypes.ALERT, {
        type             : 'error',
        dialog           : AlertMessage.common.error.unknown,
        hasHelpEmailInfo : true,
      })
    },
  });

  const deleteWish = (id: number) => {
    modal?.openModal(ModalTypes.ALERT, {
      hasCancelButton : true,
      type            : 'warn',
      dialog          : AlertMessage.course.warn.deleteWishlist,
      onSuccess       : () => deleteWishList({variables: {courseIds: [Number(id)]}}), 
    })
  }

  /***********************************************************************/
  /*****                      Components                             *****/
  /***********************************************************************/
  if (wishListFetchError  ) return <ErrorPage errorMessage={wishListFetchError.message} />;
  if (loading || !wishList) return null;

  const EmptyWishList = () => (
    <NoContent
      icon    = "emptyWish"
      title   = "Never Stop Learning ✨"
      subText = "나를 발전시켜줄 강의들을 미리 계획하세요."
      btnText = "내 미래 계획하러 가기"
      onClick = {() => navigate(PATH.HOME.base)}
      width   = "96px"
    />
  )

  return (
    <div className="flex flex-wrap h-full gap-4 items-start justify-start">
      { 
        wishList.length === 0 ? 
          <EmptyWishList /> : (
          wishList.map(item => (
            <div 
              key       = {item.courseId}
              className ="w-full lg:w-[31%]"
            >
              <MyPageCard
                hasWishBtn
                data          = {{id: item.courseId, ...item}}
                variant       = "wishList"
                handleWishBtn = {() => deleteWish(item.courseId)}
              />
            </div>
          ))
        )}
    </div>
  )
}

export default WishList
