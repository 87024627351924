import React, { useEffect, useState } from 'react'

import { ModalPortal, ModalHeader, ModalFooter } from 'components/modal/BaseModal'
import { AlertMessage                          } from 'constant/alertMessage'
import { useLockBodyScroll, usePrompt          } from 'utils/hooks'
import { useModal                              } from 'contexts/ModalContext'
import { AlertType                             } from 'pages/types';
import css                                       from './Alert.module.scss'
import AlertBody                                 from './AlertBody'

type actionType          = 'success' | 'close'
export type AlertDialog  = {
  icon?   : string
  message : string
  desc?   : string
}

export interface AlertState {
  title?            : string
  type?             : AlertType
  message?          : string
  dialog?           : AlertDialog
  hasCancelButton?  : boolean
  hasHelpEmailInfo? : boolean
  onSuccess?        : () => void
  onClose?          : () => void
  callBack?         : () => void
}

const Alert = (props: AlertState = {}) => {
  const modal               = useModal()
  const [action, setAction] = useState<actionType>()
  const {
    type    = 'info',
    title   = '',
    message = '',
    dialog  = AlertMessage.common.error.unknown,
    hasCancelButton,
    hasHelpEmailInfo,
    onSuccess,
    onClose,
  } = props
  const closeModal = () => { modal?.closeModal(); }

  useLockBodyScroll()
  usePrompt(!action)
  useEffect(() => {
    if (!action) return

    const actionHandler: { [key in actionType]: (() => void) | undefined } = {
      success : onSuccess,
      close   : onClose,
    }

    actionHandler[action]?.()
    closeModal()
  }, [action])

  return (
    <ModalPortal>
      <section className={css.backdrop}>
        <div
          className = {css.dimmedBackground}
          onClick   = {() => setAction('close')}
        />
        <article className={css.container} onClick={e => e.stopPropagation()}>
          <ModalHeader title={title} switchModal={() => setAction('close')} />
          <AlertBody
            dialog           = {dialog}
            message          = {message}
            hasHelpEmailInfo = {hasHelpEmailInfo}
          />
          <ModalFooter
            type            = {type}
            hasCancelButton = {!!hasCancelButton}
            onClick         = {{
              positive: () => setAction('success'),
              negative: () => setAction('close'),
            }}
          />
        </article>
      </section>
    </ModalPortal>
  )
}

export default Alert
