import React           from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ArrowRightCircleIcon,
} from '@heroicons/react/24/solid';

import { PATH } from 'constant';
import Button   from 'components/core/Button';
import { 
  useUpdateChapterStatusAsACompleted,
} from '../../hooks';

type Props = {
  chapter: { 
    sequence      : number,
    courseId      : number,
    nextSequence? : number,
    id            : number
  };
}

const NextButton = ({chapter}: Props) => {
  const navigate                        = useNavigate();
  const updateChapterStatusAsACompleted = useUpdateChapterStatusAsACompleted();

  const getNextChapter = () => {
    updateChapterStatusAsACompleted({courseId: chapter.courseId!, chapterId: chapter.id});

    if(chapter.nextSequence) { 
      navigate(PATH.COURSE.toChapter(Number(chapter.courseId), chapter.nextSequence));
    } else {
      navigate(PATH.COURSE.toCompleted(Number(chapter.courseId)));
    }
  }

  return (
    <div className="chapter-next-button h-full flex items-center">
      <ArrowRightCircleIcon 
        className ="lg:hidden h-[40px] text-primary" 
        onClick   = {() => getNextChapter()}
      />
      <Button
        size      = "medium"
        className = "hidden lg:block lg:h-[40px]"
        onClick   = {() => getNextChapter()}
      >
        다음으로
      </Button>
    </div>
  );
};

export default NextButton;
