import React, { useEffect, CSSProperties, useRef } from 'react';

import { useDebounce } from './hooks';
import MarkdownContent from 'components/core/MarkdownContent';
import VideoPlayer, { 
  Text,
  Line,
  VideoEvent,
  VideoEventType,
  VideoProps, 
  useVideoEvent,
  videoEventPublisher,
  TransactionType as VideoTransactionType,
  Transaction     as VideoTransaction,
} from 'components/core/VideoPlayer';

import WecodeEditor, { 
  EditorEvent,
  useEditorEvent,
  EditorEventType,
  Transaction     as EditorTransaction,
  TransactionType as EditorTransactionType,
} from 'components/core/WecodeEditor';

type Props = VideoProps & { 
  activeTab?   : string,
  setActiveTab?: (tab: string) => void,
  data         : Record<string, any>
};


const DESKTOP          = 1024;
const TIME_EPSILON     = 0.50;
const EMPTY_CODE       = { files: { 'index.html' : ''} , entry: 'index.html'};
const TEXT_EVENTS      = [VideoEventType.TEXT_UPDATE, VideoEventType.TEXT_APPEND, VideoEventType.SCROLL_TEXT];
const VisualCodeMentor = ({
  url,
  mobileUrl,
  activeTab    = '',
  setActiveTab = () => {},
  data         = {},
  orientation  = 'landscape',
  height       = '100%'
}: Props) => {
  const [videoTransactions, setVideoTransactions  ] = React.useState<VideoTransaction[]>([]);
  const [editorTransactions, setEditorTransactions] = React.useState<EditorTransaction[]>([]);
  const [isMobile, setIsMobile                    ] = React.useState(window.innerWidth < DESKTOP);
  const [textContents, setTextContents            ] = React.useState<string>("");
  const markdownContainerRef                        = useRef<HTMLDivElement>(null);
  const lastSentEvents                              = useRef<Set<string>>(new Set());
  const videoDuration                               = useRef<number>(0);
  const videoLastPlayedSeconds                      = useRef<number>(0);
  const allEvents                                   = React.useMemo(() => {
    return Object.entries(data).flatMap(([seconds, events]) =>
      events.map((event: any) => ({ ...event, seconds: Number(seconds) }))
    );
  }, [data]);


  /*******************************************************************
   * Handle the resize event to determine if the user is on mobile
   *******************************************************************/
  const handleResize = () => { 
    const resize = () => setIsMobile(window.innerWidth < DESKTOP); 

    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  };

  /*******************************************************************
   * Publish events at the current playback time.
   * If the current time is close to the last seek time, skip the event
   * to avoid duplicate events.
   * Also, skip the event if it has already been sent.
   ******************************************************************/
  const handleVideoProgress = ({ playedSeconds }: { playedSeconds: number }) => {
    videoLastPlayedSeconds.current = playedSeconds;

    const currentEvents = allEvents.filter(event => 
      (Math.abs(event.seconds - playedSeconds)) < 1 &&
      !lastSentEvents.current.has(JSON.stringify(event))
    );

    const codeUpdateEvent = currentEvents.find(event => event.type === VideoEventType.CODE_UPDATE);
    if (codeUpdateEvent) {
      // Update the last code update event to track the most recent code update
      // and reset the last sent events on code update
      lastSentEvents.current.clear();
    }

    if (playedSeconds < 1) {
      const startEvent = { type: VideoEventType.START, seconds: 0 };
      videoEventPublisher.publish([startEvent, ...currentEvents]);
    } else {
      currentEvents.length > 0 && videoEventPublisher.publish(currentEvents);
    }

    if (currentEvents.length > 0) {
      currentEvents.forEach(event => lastSentEvents.current.add(JSON.stringify(event)));
    }
  };


  /*******************************************************************
   * Find the most recent CODE_UPDATE event before seekedSeconds
   * and all events including and after the most recent CODE_UPDATE
   * and before seekedSeconds. 
   * Also, track the last sent events to avoid duplicate events.
   ******************************************************************/
  const handleVideoSeek = (seekedSeconds: number) => {
    // Weirdly, the video player triggers the seek event with 0 seconds 
    // when the video has ended. So, we need to ignore this event.
    if (seekedSeconds === 0 && videoDuration.current > 0) {
      if (Math.abs(videoDuration.current - videoLastPlayedSeconds.current) < 1) {
        return;
      }
    }

    // Find the most recent CODE_UPDATE event before seekedSeconds
    // as CODE_UPDATE events are the starting point of the code changes
    const mostRecentCodeUpdateEvent = allEvents
      .filter(event => event.type === VideoEventType.CODE_UPDATE && event.seconds <= seekedSeconds + TIME_EPSILON)
      .reduce((prev, curr) => { 
        return prev && prev.seconds > curr.seconds ? prev : curr;
      }, null);

    const textEvents = allEvents
      .filter(event => 
        TEXT_EVENTS.includes(event.type) 
        && event.seconds <= seekedSeconds + TIME_EPSILON
      );

    lastSentEvents.current.clear(); // Clear the last sent events on seek

    if (!mostRecentCodeUpdateEvent && seekedSeconds > 0) {
      // If no CODE_UPDATE event and TEXT_UPDATE event found, 
      // then reset the editor
      videoEventPublisher.publish([{type: VideoEventType.EDITOR_RESET}]);
    }

    // Get relevant events between the most recent code update and seeked seconds
    // but only send the events that haven't been sent yet
    const codeUpdateEvents = mostRecentCodeUpdateEvent ? allEvents.filter(event =>  
      event.seconds >= mostRecentCodeUpdateEvent.seconds 
      && (! TEXT_EVENTS.includes(event.type)) 
      && event.seconds <= seekedSeconds + TIME_EPSILON
    ) : [];

    if (codeUpdateEvents.length > 0) {
      codeUpdateEvents.forEach(event => lastSentEvents.current.add(JSON.stringify(event)));
      videoEventPublisher.publish(codeUpdateEvents);
    }

    if (textEvents.length > 0) {
      textEvents.forEach(event => lastSentEvents.current.add(JSON.stringify(event)));

      const mostRecentTextEvents = textEvents.at(-1);
      videoEventPublisher.publish([mostRecentTextEvents]);
    }
  };

  const debouncedHandleVideoSeek = useDebounce(handleVideoSeek, 300);

  /*******************************************************************
   * Handle the video event and update the code editor state
   *******************************************************************/
  const handleVideoEvent = (events: VideoEvent[]) => {
    const handleEvent = (event: VideoEvent): EditorTransaction[] => {
      switch (event.type) {
        case VideoEventType.START: {
          setActiveTab('Video');

          return [{type: EditorTransactionType.RESET}];
        }
        case VideoEventType.PLAY: {
          return [{type: EditorTransactionType.VIDEO_PLAY}];
        }
        case VideoEventType.ENDED: {
          return [{type: EditorTransactionType.VIDEO_ENDED}];
        }
        case VideoEventType.EDITOR_RESET: {
          setActiveTab('Video');

          return [{type: EditorTransactionType.EDITOR_RESET}];
        } 
        case VideoEventType.RESET: {
          setActiveTab('Video');

          return [{type: EditorTransactionType.RESET}];
        } 
        case VideoEventType.INSERT_CODE: {
          return [{type: EditorTransactionType.INSERT_CODE, payload: event.payload}];
        }
        case VideoEventType.SET_ACTIVE_FILE: {
          return [{type: EditorTransactionType.SET_ACTIVE_FILE, payload: event.payload}];
        }
        case VideoEventType.SHOW_TEXT: {
          setActiveTab('Text');
          return [];
        }
        case VideoEventType.SHOW_VIDEO: {
          setActiveTab('Video');
          return [];
        }
        case VideoEventType.SHOW_CODE: {
          setActiveTab('Code');
          return [];
        }
        case VideoEventType.TEXT_UPDATE: {
          const text = event.payload as Text;
          setTextContents(text.text ?? "");
          return [];
        }
        case VideoEventType.TEXT_APPEND: {
          const text = event.payload as Text;
          setTextContents(textContents  => textContents + (text.text ?? ""));
          return [];
        }
        case VideoEventType.CODE_UPDATE: {
          return [{type: EditorTransactionType.CODE_UPDATE, payload: event.payload}];
        }
        case VideoEventType.CODE_HIGHLIGHT: {
          return [{type: EditorTransactionType.HIGHLIGHT, payload: event.payload}];
        }
        case VideoEventType.CODE_ANNOTATION: {
          return [{type: EditorTransactionType.ANNOTATION, payload: event.payload}];
        }
        case VideoEventType.CLEAR_CODE_HIGHLIGHT: {
          return [{type: EditorTransactionType.CLEAR_HIGHLIGHT, payload: event.payload}];
        }
        case VideoEventType.CLEAR_CODE_ANNOTATION: {
          return [{type: EditorTransactionType.CLEAR_ANNOTATION, payload: event.payload}];
        }
        case VideoEventType.SCROLL_CODE: {
          return [{type: EditorTransactionType.SCROLL_CODE, payload: event.payload}];
        }
        case VideoEventType.SCROLL_TEXT: {
          const line = event.payload as Line;
          setTimeout(() => scrollTextContent(line.line), 500);
          return [];
        }
        default: return [];
      }
    };

    const transactions = events.flatMap(event => handleEvent(event));
    transactions.length && setEditorTransactions(transactions);
  }

  /*******************************************************************
   * Handle the editor event and update the video player state
   *******************************************************************/
  const handleEditorEvent = (events: EditorEvent[]) => {
    const handleEvents = (event: EditorEvent) => {
      switch (event.type) {
        case EditorEventType.EDITING_START: {
          setVideoTransactions([{type: VideoTransactionType.PAUSE}]);
          break;
        };
        case EditorEventType.EDITING_DONE: {
          setVideoTransactions([{type: VideoTransactionType.RESUME}]);
          break;
        };
        case EditorEventType.SHOW_PREVIEW: {
          setVideoTransactions([{type: VideoTransactionType.PAUSE}]);
          break; 
        };
        default: break;
      }
    };

    events.forEach(event => handleEvents(event));
  }

  const handleVideoPlay = () => {
    videoEventPublisher.publish([{type: VideoEventType.PLAY}]);
    setVideoTransactions([{type: VideoTransactionType.PLAY}]);
  }

  const handleVideEnded = () => {
    videoEventPublisher.publish([{type: VideoEventType.ENDED}]); 

    // Clear the last sent events on video end
    // so that when restarted, the very first event is not skipped
    lastSentEvents.current.clear();
  };

  const handleVideoDuration = (duration: number) => {
    videoDuration.current = duration;
  }

  const scrollTextContent = (lineNumber: number) => {
    if (markdownContainerRef.current) {
      // Calculate the line height using the first text element
      const firstTextElement = markdownContainerRef.current.querySelector('p, h1, h2, h3, h4, h5, h6, li, pre, blockquote');

      if (firstTextElement) {
        const lineHeight = firstTextElement.clientHeight;
        const scrollTop = (lineNumber - 1) * lineHeight; // Subtract 1 from lineNumber to convert to 0-based index

        markdownContainerRef.current.scrollTop = scrollTop;
      }
    }
  };

  /***********************************************************************/
  /*****               "       Use Effects                           *****/
  /***********************************************************************/
  useEffect(handleResize, []);
  useVideoEvent(handleVideoEvent);
  useEditorEvent(handleEditorEvent);

  /***********************************************************************/
  /*****                      Components                             *****/
  /***********************************************************************/
  const isSelected = (tab: string) => activeTab === tab;
  const hideVideo  = isMobile && !isSelected('Video');
  const hideCode   = isMobile && !isSelected('Code');
  const hideText   = isMobile && !isSelected('Text');
  const invisible  = {
    position   : "absolute",
    top        : "-10000px", 
    left       : "0",
    visibility : "hidden",
    opacity    : "0",
    transform  : "scale(0)",
    transition : "visibility 0s linear 0.5s, opacity 1s ease, transform 1s ease"
  } as CSSProperties;

  const visible = {
    visibility : "visible",
    opacity    : "1",
    transform  : "scale(1)",
    transition : "opacity 1s ease, transform 0.7s ease",
    height     : "100%"
  } as CSSProperties;

  return (
    <div 
      className = "flex flex-row w-full"
      style     = {{ height: height }}
    >
      <div 
        className ="
          lg:border-r lg:border-gray-400 
          h-full w-full lg:p-4 
          lg:bg-grey90 
          lg:flex-grow lg:basis-1/2
        "
        style = {hideText ? invisible : visible}
        >
        <div 
          ref       = {markdownContainerRef}
          className ="
            text-pane
            lg:border lg:rounded-lg
            !h-full p-4 bg-white  
            overflow-y-auto scroll-smooth
          ">
          <MarkdownContent content={textContents} />
        </div>
      </div>
      <div className={`video-pane ${hideVideo ? 'w-0 h-0' : ' h-full w-full'} lg:basis-1/2 lg:flex-grow`}>
        <VideoPlayer
          transactions= {videoTransactions}
          style       = {hideVideo ? invisible : visible}
          url         = {isMobile ? (mobileUrl ?? url): url}
          orientation = {orientation}
          width       = {"100%"}
          height      = {"100%"}
          onProgress  = {handleVideoProgress}
          onDuration  = {handleVideoDuration}
          onSeek      = {(seconds: number) => debouncedHandleVideoSeek(seconds)}
          onPlay      = {handleVideoPlay}
          onEnded     = {handleVideEnded}
        />
      </div>
      <div className={`
        code-pane
        lg:border-l lg:border-gray-400
        ${hideCode ? 'w-0 h-0' : 'h-full w-full pt-2 lg:p-4 lg:pb-2 bg-grey90'} 
        lg:basis-1/2 lg:flex-grow
        text-xs lg:text-sm
      `}>
        <WecodeEditor
          style        = {hideCode ? invisible : visible}
          initialFiles = {EMPTY_CODE}
          events       = {editorTransactions}
        />
      </div>
    </div>
  );
};

export default VisualCodeMentor;
