import React from 'react';

export enum LayoutType { 
  ROW    = "row",
  COLUMN = "column"
};

interface LayoutProps { 
  layout  : LayoutType;
  children: React.ReactNode;
};

const ROW_STYLE = `
  flex flex-row 
  w-full h-[87vh] 
`;
const COLUMN_STYLE = `flex flex-col`

const Layout = ({layout, children}: LayoutProps) => {
  const style = layout === LayoutType.ROW ? ROW_STYLE : COLUMN_STYLE;

  return (
    <div className={style}>
      {React.Children.map(children, (child) => (
        <div className="flex-grow basis-1/2 h-full">
          {child}
        </div>
      ))}
    </div>
  );
};

export default Layout;
