import { gql, useQuery } from '@apollo/client';
import { Payment       } from 'pages/types';

const GET_PAYMENT_INFO = gql`
  query ($orderId: String!) {
    paymentInfo(orderId: $orderId) {
      orderId
      orderName
      amount
      paymentMethod
      status
      approvedAt
      receiptUrl
    }
  }
`;

type Props = {
  orderId     : string;
  onCompleted?: (paymentHistory: Payment) => void;
  onError?    : (error: any             ) => void;
};

const usePaymentInfoQuery = ({orderId, onCompleted, onError}: Props) => {
  const { data, loading, error } = useQuery<{ paymentInfo: Payment; }>(
    GET_PAYMENT_INFO, 
    {
      variables   : { orderId: orderId },
      context     : { endpoint : 'payment' },
      fetchPolicy : 'network-only',
      onCompleted : ({ paymentInfo }) => { onCompleted && onCompleted(paymentInfo) },
      onError     : (error)           => { onError && onError(error)               },
    }
  );

  return { payment: data?.paymentInfo, loading, error };
};

export default usePaymentInfoQuery;
