import React  from 'react';
import bullet from 'assets/icon/svg/sm/arrow_right_grey_default.svg';
import css    from './ArrowBullet.module.scss';

export interface ArrowBulletProps {
  children: React.ReactNode;
}

const ArrowBullet = ({ children }: ArrowBulletProps) => {
  return (
    <li className={css.container}>
      <img className={css.bullet} alt="목록" src={bullet} />
      <span className={css.content}>{children}</span>
    </li>
  );
};

export default ArrowBullet;
